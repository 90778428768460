import { useLocation, useNavigate } from "react-router-dom";
import { CommonConfig } from "constant";
import "assets/css/style.css";

import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import Card from "@mui/material/Card";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import Grid from "@mui/material/Grid";
import {
  Add,
  ArrowDownward,
  CancelScheduleSendTwoTone,
  ControlCameraTwoTone,
} from "@mui/icons-material";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import axios from "axios";
import cogoToast from "cogo-toast";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import CardContent from "@mui/material/CardContent";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button as PButton } from "primereact/button";
import { TextField } from "@mui/material";
import Icon from "@mui/material/Icon";
import MDTypography from "components/MDTypography";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { NotesComponent } from "layouts/component/Notes";
import moment from "moment-timezone";
import Moment from "react-moment";
import "../lead/Lead.css";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  InputLabel,
} from "@mui/material";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Autocomplete from "@mui/material/Autocomplete";
import { indexof } from "stylis";
import pxToRem from "assets/theme/functions/pxToRem";
import { width } from "@mui/system";

function Leadlist(props) {
  let navigate = useNavigate();
  var PersonId = CommonConfig.loggedInUserData();
  console.log("Hello",PersonId);
  if(PersonId==undefined || PersonId==""){
    navigate('authentication/login');
  }
  // console.log(PersonId[0].PersonId);

  // if (localStorage.getItem("userData")) {
  //   // alert("Hello");
  // } else {
  //   // alert("Hello2")
  //   navigate("/");
  // }
  let i = 1;
  const { state } = useLocation();
  const [data, setData] = useState([]);
  const [rememberMe, setRememberMe] = useState(true);
  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const [name, setName] = useState("");
  const [cityOrState, setCityOrState] = useState("");
  const [userType, setUserType] = useState("");
  const [phoneOrEmail, setPhoneOrEmail] = useState("");
  const [personIdNumber, setPersonIdNumber] = useState("");

  const [values, setValues] = useState([]);
  const [stateLabel, setStateLabel] = useState(null);

  const [selectedMediumType, setSelectedMediumType] = useState(false);
  const [appointmentdelID, setappointmentdelID] = useState(null);
  const [loader, setLoader] = React.useState(false);
  const [leadID, setLeadID] = useState(null);
  const [getNotesLeadId, setGetNotesLeadId] = useState(null);
  const [stageData, setStageData] = useState(null);
  const [followUpValue, setFollowUpValue] = useState(null);
  const [serviceAutocompleteValues, setServiceAutocompleteValues] = useState(null);

  const [openDialog, setOpenDialog] = useState(false);
  const [notesData, setNotesData] = useState([]);
  const [notesListData, setNotesListData] = useState([]);

  const [addNotesOpenDialog, setAddNotesOpenDialog] = useState(false);
  const [chNotes, setChNotes] = useState(null);

  // let loginUser;

  // loginUser = JSON.parse(localStorage.getItem("userData"));

  const requestStatus = [
    { label: "All", value: "All", IsSelected: false, Index: 0 },
    { label: "Lead", value: "Lead", IsSelected: true, Index: 1 },
    { label: "Follow Up", value: "Follow Up", IsSelected: false, Index: 2 },
    { label: "Won", value: "Won", IsSelected: false, Index: 3 },
    
    { label: "Lost", value: "Lost", IsSelected: false, Index: 4 },
    { label: "Purged", value: "Purged", IsSelected: false, Index: 5 },
    
  ]

  var userTypeMessage = "Lead List";
  const StageData = [
    { label: "Lead" },
    { label: "Follow Up" },
    { label: "Won" },
    { label: "Lost" },
    { label: "Purged" },
  ];

  // console.log("Testing12 ", loginUser.PersonId);

  const columns = [
    {
      name: "S.No",
      // selector: "LeadNumber",
      // filter: true,
      // sortable: true,
      // maxWidth: 25,
      style: {
        cursor: "pointer",
        textDecorationLine: "underline",
        fontWeight: "bold",
      },
      body: (record) => {
        return (
          <href onClick={() => editUser(record)}>
            {record.LeadNumber}
          </href>
        );
      },
    },

    {
      name: "Lead  Date",
      body: (data) => {
        if (data.LeadDateFormat) {
          return data.LeadDateFormat;
        }
        return "";
      },
      selector: "LeadDate",
      filter: true,
      sortable: true,
    },

    {
      name:"Company Name",
      selector:"CompanyName",
      filter: true,
      sortable: true,
    },

    {
      name: "Name",
      selector: "companyContactPerson",
      filter: true,
      sortable: true,
    },

    
    {
      name: "Number",
      selector: "ContactNumber",
      filter: true,
      sortable: true,
    },

    {
      name: "Email",
      selector: "ContactEmail",
      filter: true,
      sortable: true,
    },

    {
      name: "Source",
      selector: "SourceTypeOther",
      filter: true,
      sortable: true,
    },

    // {
    //   name: "Lead Title",
    //   selector: "LeadTitle",
    //   filter: true,
    //   sortable: true,
    // },

    // {
    //   name: "Requirement Type",
    //   selector: "RequirementType",
    //   filter: true,
    //   sortable: true,
    // },

    {
      name: "Handler",
      selector: "HandlerPersonid",
      filter: true,
      sortable: true,
    },

    {
      name: "Stage",
      selector: "Stage",
      filter: true,
      sortable: true,
    },
    
    {
      name: "Follow Up",
      body: (data) => {
        if (data.FollowUpDateFormat && data.Stage=="Follow Up") {
          console.log("table console",data);
          return data.FollowUpDateFormat;
        }else
          return "";
      },
      selector: "FollowUpDate",
      filter: true,
      sortable: true,
    },

    // {
    //   name: "Follow Up Time",
    //   body: (data) => {
    //     if (data.StartDate) {
    //       // return moment(data.StartDate).format("hh:mm a");
    //       return moment(data.StartDate).utc().format("hh:mm A");
    //     }
    //     return "";
    //   },
    //   selector: "FollowUpTime",
    //   filter: true,
    //   sortable: true,
    // },

    // {
    //   name: "Last Updated On",
    //   body: (data) => {
    //     if (data.UpdatedOn) {
    //       return CommonConfig.formatDate(data.UpdatedOn);
    //     }
    //     return "";
    //   },
    //   selector: "LastUpdatedOn",
    //   filter: true,
    //   sortable: true,
    // },

    // {
    //   name: "Updated By",
    //   selector: "UpdatedBy",
    //   filter: true,
    //   sortable: true,
    // },
    // {
    //   name: "Note",
    //   body: (data) => noteTemplate(data),
    //   ignoreRowClick: true,
    //   allowOverflow: true,
    //   button: true,
    //   filter: true,
    //   sortable: true,
    // },

    {
      name: "Action",
      body: (data) => actionTemplate(data),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const noteTemplate = (data) => {
    console.log("datatatatatat", data);
    return (
      <div>
        <span tooltip="Show Notes" onClick={() => getNotes(data)} className="notes-label">
          3
        </span>
      </div>
    );
  };
  


  const actionTemplate = (data) => {
    return (
      <div>
        {/* <PButton
          type="button"
          icon="pi pi-user-edit"
          className="p-button-warning"
          onClick={() => editUser(data)}
          style={{ marginRight: ".5em" }}
          tooltip="Edit Lead"
          tooltipOptions={{ position: "bottom" }}
        /> */}

        <PButton
          type="button"
          icon="pi pi-users"
          // className="customeStylePButton"
          onClick={() => Notes(data.LeadID)}
          style={{ marginRight: ".5em" }}
          tooltip="Add Notes"
          tooltipOptions={{ position: "bottom" }}
        ></PButton>
        
        {/* <PButton
          type="button"
          icon="pi pi-trash"
          onClick={() => deleteUser(data.LeadID)}
          style={{ marginRight: ".5em",backgroundColor:"red" }}
          tooltip="Delete"
          tooltipOptions={{ position: "bottom" }}
        ></PButton> */}
        
      </div>
    );
  };

  const getColoumnsRow = () =>
    columns.map((item, id) => {
      return (
        <Column
          style={{ minWidth: "200px" }}
          key={id}
          sortable={item.sortable}
          body={item?.body}
          filter={item.filter}
          field={item.selector}
          header={item.name}
        />
      );
    });

  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    // errors,
    formState: { errors },
    getValues,
  } = useForm({ 
    UserType: "",
  });

  
  const getData = (props) => {

    var PersonId = CommonConfig.loggedInUserData();
    console.log("Hello14782",PersonId[0].Designation);
    console.log("Hello",PersonId.FirstName);
    var payload = ""
    if(PersonId[0].Designation == "Admin"){
      payload = ""
    }else {
      // 'le.Stage = ' + '"'+serviceAutocompleteValues[i].label+'"' + " OR "
      payload = ' AND le.HandlerPersonid = ' + '"'+PersonId[0].FirstName+'"'
    }

    var pdata = {
      newdata:payload
    }
    debugger
    setLoader(!loader);
    axios({
      url:CommonConfig.ApiUrl+"authentication/getLeadList",
      data: {pdata},
      method: "POST",
      headers: {},
    })
      .then((res) => {
        setLoader(!loader);
        if (res.data?.success === true) {
          setData("");
          console.log("Testing = ",res);
          setLoader(false);
          setData(res.data.data[0]);
          console.log("Data...", data);
        }
        //console.log("test...hhghghg", res.data.Data[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  };


  useEffect(() => {
    getData();
  }, []);

  const deleteUser = async (selectedRow) => {
    setLoader(!loader);
    // const { LeadID } = selectedRow;
    try {
      const data = await axios(CommonConfig.ApiUrl + "authentication/deleteLeadListBYID", {
        method: "POST",
        data: { LeadID:selectedRow},
        headers:{},
      }).then((res) => {
        setLoader(false);
        var userdata = {
          //...res.data.data[0][0],
          //dOC: res.data.data[1],
        };
        console.log(res);

        // var documentdata = [];
        console.log(",,,,,,,,,ggg,,", userdata);
        navigate("/layouts/lead/Index",
        //   state: res.data.data[0][0],
         );
      });

      // navigate("/layouts/referal/refEdit", {
      //   state: data,
      // });
    } catch (error) {
      console.log({ error });
    }
  };

  const onServiceChange = (event, value) => {
    console.log("VALUEService", value);
    let formattedData = [];

    for (let i = 0; i < value.length; i++) {
      formattedData.push(value[i].label);
    }

    console.log("formattedDatazasQ", formattedData.toString());

    setValues(formattedData);
    setServiceAutocompleteValues(value);
    console.log("onService", values);
  };

  const editUser = async (row = {}) => {
    setLoader(!loader);
    const { LeadID } = row;
    try {
      const data = await axios(CommonConfig.ApiUrl + "authentication/getLeadListBYID", {
        method: "POST",
        data: { LeadID },
      }).then((res) => {
        setLoader(!loader);
        var userdata = {
          ...res.data.data[0][0],
          dOC: res.data.data[1],
        };

        var documentdata = [];

       
        console.log(",,,,,,,,,ggg,,", userdata);
        navigate("/layouts/lead/Index", {
          state: res.data.data[0][0],
        });
      });

      // navigate("/layouts/referal/refEdit", {
      //   state: data,
      // });
    } catch (error) {
      console.log({ error });
    }
  };



  function getNotes(selectedRow) {
    console.log("selectedRowQ", selectedRow.LeadID);
    setGetNotesLeadId(selectedRow.LeadID);
    setOpenDialog(true);

    let noteReqData = {
      EntityType: "Lead",
      EntityId: selectedRow.LeadID,
      CurrentUser: "",
    };
    axios({
      url: CommonConfig.ApiUrl + "authentication/getNoteList",
      method: "POST",
      headers: {},
      data: noteReqData,
    })
      .then((res) => {
        if (res.data?.success) {
          setNotesData(res.data.data || []);
          document.getElementById("NotesTableDynamic").style.display = "block";
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  console.log("getNotesLeadId", getNotesLeadId);

  function Notes(selectedRow) {
    console.log("Bhanunanau", selectedRow);
    getNoteList(selectedRow);
    console.log("selectedRowQ", selectedRow);
    setLeadID(selectedRow);
    setAddNotesOpenDialog(true);
  }

  console.log("leadIDQQQA", leadID);

  const saveNotes = () => {
    if (chNotes === null || chNotes === "") {
      document.getElementById("NotesError").style.display = "block";
      return;
    } else {
      document.getElementById("NotesError").style.display = "none";
    }

    const formattedData = {
      EntityType: "Lead",
      EntityId: leadID,
      CurrentUser: "",
      IsFlag: 0,
      NoteType: "Lead",
      NoteTitle: "",
      NoteText: chNotes,
    };
    debugger;
    console.log("formatteddata..", formattedData);
    axios({
      url: CommonConfig.ApiUrl + "authentication/addNote",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data.success === true) {
          cogoToast.success("Notes Added Successfully.");
          getNoteList(leadID);
          setChNotes("");
        }
        console.log("test...", res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  console.log("leadIDza1", leadID);
  const getNoteList = (selectedRow) => {
    console.log("leadIDza", selectedRow);
    let noteReqData = {
      EntityType: "Lead",
      EntityId: selectedRow,
      CurrentUser: "",
    };
    console.log("noteReqDataQ", noteReqData);
    axios({
      url: CommonConfig.ApiUrl + "authentication/getNoteList",
      method: "POST",
      headers: {},
      data: noteReqData,
    })
      .then((res) => {
        if (res.data?.success) {
          setNotesListData(res.data.data || []);
          document.getElementById("NotesTableDynamic").style.display = "block";
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  console.log("leadIDleadID", leadID);

  const handleClick=async ()=>{
    console.log("stage values are",serviceAutocompleteValues);
    let newdata=[];
    var data = ""
    for (let i = 0; i < serviceAutocompleteValues?.length; i++) {
      data = data + 'le.Stage = ' + '"'+serviceAutocompleteValues[i].label+'"' + " OR "
    }

    data = data.substring(0, data.length - 3);
    console.log("the newdata is",data);
    var PersonId = CommonConfig.loggedInUserData();

    var payload = ""
    if(PersonId[0].Designation == "Admin"){
      payload = ""
    }else {
      // 'le.Stage = ' + '"'+serviceAutocompleteValues[i].label+'"' + " OR "
      payload = ' AND (le.HandlerPersonid = ' + '"'+PersonId[0].FirstName+'")'
    }

    data = data + payload


    
    // setLoader(!loader);
    var pdata = {
      newdata:data
    }
    try {
      const data = await axios(CommonConfig.ApiUrl + "authentication/getFilteredList", {
        method: "POST",
        data: {pdata},
        headers:{},
      }).then((res) => {

        setLoader(!loader);
        if (res.data?.success === true) {
          setData("");
          console.log("Testing = ",res);
          setLoader(false);
          setData(res.data.data[0]);
          console.log("Data...", data);
        }
        // setLoader(false);
        console.log(res);
      });
    } catch (error) {
      console.log({ error });
    }

  }

  const showdatasets = async () =>{
    debugger
    if(localStorage.getItem("HIDEDATA") == 1){
      document.getElementById("HIDEDATA").style.display = "none"
      localStorage.removeItem("HIDEDATA")
    }else{

      localStorage.setItem("HIDEDATA",1)
      document.getElementById("HIDEDATA").style.display = "block"

    }


  }
   const handleClickSet=async ()=>{

    var All  = document.getElementById("ALL").checked;
    console.log("ALL" , All)

    var Lead  = document.getElementById("Lead").checked;
    var FollowUP  = document.getElementById("Follow Up").checked;
    var won  = document.getElementById("Won").checked;
    var Lost  = document.getElementById("Lost").checked;
    var Purged  = document.getElementById("Purged").checked;

    var dataSet = ""

    if(All == true){
      dataSet = ""
    }else{
      if(Lead == true){
        dataSet = dataSet + "Lead,"
      } 
      if(FollowUP == true){
        dataSet = dataSet + "Follow Up,"
      } 
      if(won == true){
        dataSet = dataSet + "Won,"
      } 
      if(Lost == true){
        dataSet = dataSet + "Lost,"
      } 
      if(Purged == true){
        dataSet = dataSet + "Purged,"
      } 
    }

    if(dataSet!= ""){
      dataSet = dataSet.substring(0, dataSet.length - 1);
    }

    var data = "WHERE ";
    console.log(dataSet)
    if(dataSet!= ""){
      var myArray = dataSet.split(",");

      for (let i = 0; i < myArray?.length; i++) {
          data = data + 'le.Stage = ' + '"'+myArray[i]+'"' + " OR "
      }
      data = data.substring(0, data.length - 3);


    }




    var PersonId = CommonConfig.loggedInUserData();

    var payload = ""
    if(PersonId[0].Designation == "Admin"){
      payload = ""
    }else {
      if(dataSet!=""){
        payload = ' AND (le.HandlerPersonid = ' + '"'+PersonId[0].FirstName+'")'
      }
      else{
        payload = ' WHERE (le.HandlerPersonid = ' + '"'+PersonId[0].FirstName+'")'
      }
      // 'le.Stage = ' + '"'+serviceAutocompleteValues[i].label+'"' + " OR "
      
    }

    data = data + payload


    
    // setLoader(!loader);
    var pdata = {
      newdata:data
    }
    try {
      const data = await axios(CommonConfig.ApiUrl + "authentication/getFilteredList", {
        method: "POST",
        data: {pdata},
        headers:{},
      }).then((res) => {

        setLoader(!loader);
        if (res.data?.success === true) {
          document.getElementById("HIDEDATA").style.display = "none"
          setData("");
          console.log("Testing = ",res);
          setLoader(false);
          setData(res.data.data[0]);
          console.log("Data...", data);
        }
        // setLoader(false);
        console.log(res);
      });
    } catch (error) {
      console.log({ error });
    }

  }

  const resetData = () => {
    setServiceAutocompleteValues([]);
    
    getData()
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div>
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loader}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      {/* 
      "Name": "",
  "CityOrState": "",
  "UserType":"",
  "PhoneOrEmail":"",
  "PersonIdNum()ber":"" */}
      <Card sx={{ width: "100%", mt: 3 }}>
        <MDBox display="flex">
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="4rem"
            height="4rem"
            variant="gradient"
            bgColor="primary"
            color="white"
            shadow="md"
            borderRadius="xl"
            ml={3}
            mt={-2}
          >
            <Icon fontSize="medium" color="inherit">
              person_add
            </Icon>
          </MDBox>
          <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
            {userTypeMessage}
          </MDTypography>
          <div className="filter-right">
            <div className="filter-wrap">
              <button onClick={showdatasets}>Filter</button>
              <div className="filter-dropdown hidedata" id = "HIDEDATA">
                <div className="fd-scroll-area">
                  <ul>
                  {/* {requestStatus.map((step, key) => {
                              return (
                                <li>
                                  <label>
                                    <input
                                      type="checkbox"
                                      checked={step.IsSelected}
                                      onChange={(e) =>
                                        handleCheckboxChange(
                                          e,
                                          step,
                                          step.value
                                        )
                                      }
                                    />{" "}
                                    {step.value}
                                  </label>
                                </li>
                              );
                            })} */}
                    
                    <li>
                      <label>
                        <input type="checkbox" id= "ALL"></input>
                        <span>ALL</span>
                      </label>
                    </li>
                    <li>
                      <label>
                        <input type="checkbox" id = "Lead"></input>
                        <span>Lead</span>
                      </label>
                    </li>
                    <li>
                      <label>
                        <input type="checkbox" id = "Follow Up"></input>
                        <span>Follow Up</span>
                      </label>
                    </li>
                    <li>
                      <label>
                        <input type="checkbox" id = "Won"></input>
                        <span>Won</span>
                      </label>
                    </li>
                    <li>
                      <label>
                        <input type="checkbox" id = "Lost"></input>
                        <span>Lost</span>
                      </label>
                    </li>
                    <li>
                      <label>
                        <input type="checkbox" id = "Purged"></input>
                        <span>Purged</span>
                      </label>
                    </li>
                    {/* <li>
                      <label>
                        <input type="checkbox"></input>
                        <span>Option</span>
                      </label>
                    </li>
                    <li>
                      <label>
                        <input type="checkbox"></input>
                        <span>Option</span>
                      </label>
                    </li>
                    <li>
                      <label>
                        <input type="checkbox"></input>
                        <span>Option</span>
                      </label>
                    </li> */}
                  </ul>
                </div>
                <button className="search-btn" onClick={handleClickSet} >Search</button>
              </div>
            </div>
          </div>
        </MDBox>
        <MDBox sx={{ pt: 3, pl: 2, pr: 2 }}>
          
            {/* <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
              Status
            </MDTypography> */}
            {/* <Grid container spacing={3}>
              <Grid item md="8">
                <div className="reg_input sec_level">
                  <Autocomplete
                    filterSelectedOptions
                    multiple
                    id="Stage"
                    name="Stage"
                    options={StageData ? StageData : []}
                    value={serviceAutocompleteValues ? serviceAutocompleteValues : []}
                    defaultValue={serviceAutocompleteValues ? serviceAutocompleteValues : []}
                    onChange={onServiceChange}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Stage*"
                        placeholder="Stage"
                        // error={errorservices}
                        // helperText={errorservices}
                        // id="SkillSet"
                        // name="SkillSet"
                        // defaultValue={Params}
                        variant="outlined"
                        rows={1.5}
                        multiline
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    )}
                  />
                  <span class="errorTC" id="SkillSetError">
                    Please Select Stage.
                  </span>
                </div>
              </Grid>
              <Grid item md="4">
                <MDButton onClick={handleClick}  type="submit" variant="contained" color="primary" style={{ marginRight: '10px' }}>
                  Search  
                </MDButton>
                <MDButton onClick={resetData}  type="submit" variant="contained" color="primary">
                  Reset 
                </MDButton>
              </Grid>
             
            </Grid> */}
            
          
          
          
        </MDBox>
        <MDBox sx={{ pt: 4, pb: 2, pl: 2, pr: 2 }}>
          <div className="card leadList">
            <div className="table-custom">
              <DataTable
                scrollable
                value={data}
                showGridlines
                scrollHeight="600px"
                scrollWidth="100%"
                responsiveLayout="scroll"
                paginator
                rows={10}
                totalRecords={data ? data.length : 0}
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                rowsPerPageOptions={[10, 20, 50]}
                emptyMessage="No records found"
                // paginatorLeft={paginatorLeft}
                // paginatorRight={paginatorRight}
                filterDisplay="row"
              >
                {getColoumnsRow()}
              </DataTable>
            </div>
          </div>
        </MDBox>

        <Dialog open={openDialog} onClose={() => setOpenDialog(false)} fullWidth>
          <DialogTitle>Notes</DialogTitle>
          <DialogContent
            style={{
              paddingTop: "15px",
              margin: "25px",
            }}
          >
            <NotesComponent entityType="Lead" entityId={getNotesLeadId} NotesData={notesData} />
          </DialogContent>
          <DialogActions>
            <MDButton onClick={() => setOpenDialog(false)} color="secondary">
              OK
            </MDButton>
          </DialogActions>
        </Dialog>

        <Dialog open={addNotesOpenDialog} onClose={() => setAddNotesOpenDialog(false)} fullWidth>
          <DialogTitle>Notes</DialogTitle>
          <DialogContent
            style={{
              paddingTop: "15px",
              margin: "25px",
            }}
          >
            <Card sx={{ width: "100%", mt: 6 }}>
              <MDBox display="flex">
                <MDBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="4rem"
                  height="4rem"
                  variant="gradient"
                  bgColor="primary"
                  color="white"
                  shadow="md"
                  borderRadius="xl"
                  ml={3}
                  mt={-2}
                >
                  <Icon fontSize="medium" color="inherit">
                    warning_amber
                  </Icon>
                </MDBox>
                <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
                  Notes
                </MDTypography>
              </MDBox>
              <MDBox sx={{ pt: 4, pb: 2, pl: 2, pr: 2 }}>
                <div className="notes-table">
                  <table>
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Notes</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="nt-date">{moment(new Date()).tz("America/Chicago",true).format("MM-DD-YYYY")}</td>
                        <td className="nt-comment">
                          <div className="reg_input mb-0">
                            <TextField
                              onChange={(e) => setChNotes(e.target.value)}
                              value={chNotes ? chNotes : ""}
                              name="notes"
                              multiline
                              rows={2}
                              // maxRows={4}
                              label="Notes Text*"
                              id="regFirstName"
                              fullWidth
                            />
                            <span class="errorTC" id="NotesError">
                              Please Write Notes.
                            </span>
                          </div>
                        </td>
                        <td className="nt-action">
                          <PButton
                            type="button"
                            icon="pi pi-plus"
                            className="p-button-primary AddNotes"
                            onClick={() => saveNotes()}
                            tooltip="Add Notes"
                            tooltipOptions={{ position: "bottom" }}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <NotesComponent entityType="Lead" entityId={leadID} NotesData={notesListData} />
              </MDBox>
            </Card>
          </DialogContent>
          <DialogActions>
            <MDButton onClick={() => setAddNotesOpenDialog(false)} color="secondary">
              Ok
            </MDButton>
          </DialogActions>
        </Dialog>
      </Card>
      {/* <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="xs">
        <DialogTitle>Remove Lead</DialogTitle>
        <DialogContent>
          <Grid item md="12">
            <div className="reg_input check_value mb-0">
              <InputLabel className="reg_lable mt-5 mb-2">
                Are You Sure to Remove this Lead.?
              </InputLabel>
              <div>
                <span class="servicesError" id="ServicesError">
                  Please select any one option.
                </span>
              </div>
              <RadioGroup
                aria-label="remove"
                name="Remove"
                row="true"
                onChange={(e) => setSelectedMediumType(e.target.value)}
              >
                <FormControlLabel
                  value="Yes"
                  control={<Radio checked={selectedMediumType === "Yes" ? true : false} />}
                  label="Yes"
                  name="Remove"
                />
                <FormControlLabel
                  value="No"
                  control={<Radio checked={selectedMediumType === "No" ? true : false} />}
                  label="No"
                  name="Remove"
                />
              </RadioGroup>
            </div>
          </Grid>
        </DialogContent>
        <DialogActions>
          <MDButton variant="contained" color="secondary" onClick={() => CancelDelete()}>
            Back
          </MDButton>
          <MDButton
            variant="contained"
            color="primary"
            onClick={() => {
              deleteUser();
            }}
          >
            Ok
          </MDButton>
        </DialogActions>
      </Dialog> */}
    </DashboardLayout>
  );
}

export default Leadlist;
