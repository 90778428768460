    
import React, { useEffect, useMemo, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import cogoToast from "cogo-toast";
import axios from "axios";
import { DashCircle, PlusCircle } from "react-bootstrap-icons";
// import DatePicker from "react-date-picker";
import Multiselect from "multiselect-react-dropdown";
import PhoneInput from "react-phone-input-2";
import MDDatePicker from "components/MDDatePicker";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDInput from "components/MDInput";
import { CommonConfig } from "constant";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import Icon from "@mui/material/Icon";
import MDTypography from "components/MDTypography";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Country, State, City } from "country-state-city";

import {
  FormControlLabel,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
  Radio,
  Select,
  RadioGroup,
  Grid,
  Button,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import Autocomplete from "@mui/material/Autocomplete";
import Logo from "assets/img/cropped-webment.png";
import MDButton from "components/MDButton";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup1 from "yup";
import { yupResolver1 } from "@hookform/resolvers/yup";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { item } from "examples/Sidenav/styles/sidenavItem";

import { isValidDate } from "@fullcalendar/react";
import { Alert } from "@mui/material";
//New Lead Code Start
import { Button as PButton } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { NotesComponent } from "layouts/component/Notes";
//New Lead Code End

const schema = yup.object().shape(
  {
    CompanyName: yup.string().required("Company Name Required."),
    //Industry: yup.object().required("Industry Required."),
    // CompanySize: yup.object().required("No. of Employees Required."),
    // SalesTurnover: yup.object().required("Sales Turnover Required."),
    // Website: yup.string().required("Website Required."),
   // Source: yup.object().required("Source Required."),
    // Notes: yup.string().required("Notes Required."),

    // Country: yup.string().when(["AddressLine1", "AddressLine2", "City", "State", "PostalCode"], {
    //   is: (AddressLine1, AddressLine2, City, State, PostalCode) =>
    //     AddressLine1 !== "" ||
    //     AddressLine2 !== "" ||
    //     City !== "" ||
    //     State !== "" ||
    //     PostalCode !== "",
    //   then: yup.string().required("Address Type Required."),
    // }),
    // AddressLine1: yup.string().when(["Country", "AddressLine2", "City", "State", "PostalCode"], {
    //   is: (Country, AddressLine2, City, State, PostalCode) =>
    //     Country !== "" || AddressLine2 !== "" || City !== "" || State !== "" || PostalCode !== "",
    //   then: yup.string().required("Address Line1 Required."),
    // }),
   // AddressLine2: yup.string(),
    // .when(["Country", "AddressLine1", "City", "State", "PostalCode"], {
    //   is: (Country, AddressLine1, City, State, PostalCode) =>
    //     Country !== "" || AddressLine1 !== "" || City !== "" || State !== "" || PostalCode !== "",
    //   then: yup.string(),
    //   .required("Address Line2 Required."),
    // }),

  //  City: yup.string().when(["Country", "AddressLine1", "AddressLine2", "State", "PostalCode"], {
    //   is: (Country, AddressLine1, AddressLine2, State, PostalCode) =>
    //     Country !== "" ||
    //     AddressLine1 !== "" ||
    //     AddressLine2 !== "" ||
    //     State !== "" ||
    //     PostalCode !== "",
    //   then: yup.string().required("City Required."),
    // }),

    // State: yup.string().when(["Country", "AddressLine1", "AddressLine2", "City", "PostalCode"], {
    //   is: (Country, AddressLine1, AddressLine2, City, PostalCode) =>
    //     Country !== "" ||
    //     AddressLine1 !== "" ||
    //     AddressLine2 !== "" ||
    //     City !== "" ||
    //     PostalCode !== "",
    //   then: yup.string().required("State Required."),
    // }),

    // PostalCode: yup.string().when(["Country", "AddressLine1", "AddressLine2", "City", "State"], {
    //   is: (Country, AddressLine1, AddressLine2, City, State) =>
    //     Country !== "" || AddressLine1 !== "" || AddressLine2 !== "" || City !== "" || State !== "",
    //   then: yup.string().required("PostalCode Required."),
    // }),

    // AddressType: yup.object().required("Address Type Required."),
    // AddressLine1: yup.string().required("Address Line1 Required."),
    // AddressLine2: yup.string(),
    // .required("Address Line2 Required."),
    // City: yup.string().required("City Required."),
    // PostalCode: yup.string().required("Postal Code Required."),
    // State: yup.string().required("State Required."),
    Country: yup.string().required("Country Required."),
  },
  [
    ["Country", "AddressLine1"],
    ["Country", "AddressLine2"],
    ["AddressLine1", "AddressLine2"],
    ["AddressLine1", "City"],
    ["City", "AddressLine2"],
    ["Country", "City"],
    ["State", "AddressLine2"],
    ["State", "AddressLine1"],
    ["State", "Country"],
    ["State", "City"],
    ["State", "PostalCode"],
    ["City", "PostalCode"],
    ["Country", "PostalCode"],
    ["AddressLine2", "PostalCode"],
    ["AddressLine1", "PostalCode"],
  ]
);

const schema1 = yup.object().shape({
  CompanyID: yup.object().required("Company Required."),
  FirstName: yup.string().required("First Name Required."),
  LastName: yup.string().required("Last Name Required."),
  //Designation: yup.object().required("Designation Required."),
  //PhoneType: yup.object().required("Phone Type Required."),
  // CountryCode: yup.object().required("CountryCode Required."),
  CountryCode: yup.string().required("Country Code Required."),
  PhoneNumber: yup.string().required("Phone Number Required."),
 // EmailType: yup.object().required("Email Type Required."),

  Email: yup.string().required("Email Required.").email("Incorrect email format."),
  // Skype: yup.string().required("Skype Required."),
  // Facebook: yup.string().required("Facebook Required."),
  // LinkedIn: yup.string().required("LinkedIn Required."),

  // Country: yup.object().required("Country Required."),
});


const Add = () => {
  const [, updateState] = React.useState();
 const forceUpdate = React.useCallback(() => updateState({}), []);
  const { state } = useLocation();

  let navigate = useNavigate();
  const contactSection = useRef(null);
 
  var PersonId = CommonConfig.loggedInUserData();
  console.log("Hello",PersonId);
  if(PersonId==undefined || PersonId==""){
    navigate('authentication/login');
  }
  
  
  let clientRef = useRef(false);
  const MAX_COUNT = 5;
  // const termsCondition = "";
  const [values, setValues] = useState([]);
  const [stateId, setStateId] = useState([]);
  const [stateLabel, setStateLabel] = useState(state?.SecurityUserData?.State || null);
  const [CompanyDataDefault, setCompanyDataDefault] = useState([]);

  const [relativeStateId, setRelativeStateId] = useState([]);
  const [uploadfile, setuploadfile] = useState([]);
  const [relativeStateLabel, setRelativeStateLabel] = useState(
    state?.SecurityUserData?.RelativeState || null
  );
  const [serviceAutocompleteValues, setServiceAutocompleteValues] = useState(null);

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fileLimit, setFileLimit] = useState(false);
  const [companyData, setCompanyData] = useState(state || null);
  // const [DocumentuserData, setDocumentUserData] = useState(state[1] || null);
  console.log("KKKK", companyData);

  let LedData = JSON.parse(localStorage.getItem("leadData"));
  console.log("122222", LedData);
  let newData = JSON.parse(localStorage.getItem("newData"));
  console.log("newData011", newData);

  const [stateList, setStateList] = useState(null);
  const [contactPersonId, setContactPersonId] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const [requirementData, setRequirementData] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [documentdata, setdocumentdata] = useState([]);
  const [serviceList, setServiceList] = useState(null);
  const [loader, setLoader] = React.useState(false);
  const [sourceData, setSourceData] = useState(null);
  const [industryData, setIndustryData] = useState(null);
  const [salesTurnoverData, setSalesTurnoverData] = useState(null);
  const [sourceDataList, setSourceDataList] = useState(null);
  const [noOfEmployeData, setNoOfEmployeData] = useState(null);
  const [addressTypeData, setAddressTypeData] = useState(null);
  const [sourceValue, setSourceValue] = useState(null);
  const [countryData, setCountryData] = useState(null);
  const [countryIsoValue, setCountryIsoValue] = useState(null);
  const [stateIsoValue, setStateIsoValue] = useState(null);

  const [stateData, setStateData] = useState(null);
  const [citysData, setCitysData] = useState(null);

  console.log("countryIsoValue Country", `${countryIsoValue?.id}`);
  console.log("countryIsoValuecity", `${countryIsoValue?.id}`, `${stateIsoValue?.id}`);
//New Lead Code Start
//Lead Page Constant
const [leadData, setLeadData] = useState(state || null);
// Insert Multiple Contact Id....................................
const [contactId, setContactId] = useState(null);
console.log("setContactIdQAAQ", contactId);
const [selectedContactId, setSelectedContactId] = useState(null);
const [finalContactId, setFinalContactId] = useState(null);
let NewCompanyId = JSON.parse(localStorage.getItem("NewCompanyId"));
let NewCompanyName = JSON.parse(localStorage.getItem("NewCompanyName"));
const [NewCompanyNameInput,setNewCompanyNameInput] = useState(null);
const [selectSaveButtonvalue, setSelectSaveButtonvalue] = React.useState("");
const [handlerData, setHandlerData] = useState(null);
const [leadDate, setLeadDate] = useState(null);
const [rquirementTypeData, setRquirementTypeData] = useState(null);
const [stageData, setStageData] = useState(null);
const [followUpValue, setFollowUpValue] = useState(null);
const [FollowUpDate, setFollowupDate] = useState(null);
const [companyForLocal, setCompanyForLocal] = useState(null);
localStorage.setItem("PrevStageData", JSON.stringify(leadData ? leadData : ""));
let PrevStageData = JSON.parse(localStorage.getItem("PrevStageData"));
const [data, setData] = useState(null);
const [chNotes, setChNotes] = useState(null);
const [notesData, setNotesData] = useState([]);
const HandlerData = [{ label: "Kamal" }, { label: "Purveen" }];

//New Lead Code End

//New Contact Code Start
const [contactData, setContactData] = useState(state || null);
const [designationData, setDesignationData] = useState(null);
const [phoneEmailTypeData, setPhoneEmailTypeData] = useState(null);

//New Contact Code End
  // GetCountry State City--------------------------

  function getCountry() {
    let getAllCountry = Country.getAllCountries();
    let getCountryArry = [];
    for (var i = 0; i < getAllCountry.length; i++) {
      let getCountryObject = {};
      getCountryObject.label = getAllCountry[i]?.name;
      getCountryObject.id = getAllCountry[i]?.isoCode;
      getCountryArry.push(getCountryObject);
    }
  
    // console.log("countryDataavalueee", getCountryArry);
    // console.log("countryDataavalueee1", getAllCountry);
    setCountryData(getCountryArry);
  }

  function getStates() {
    let getAllState = State.getStatesOfCountry(`${countryIsoValue?.id}`);

    let getStateArry = [];
    for (var i = 0; i < getAllState.length; i++) {
      let getStateObject = {};
      getStateObject.label = getAllState[i]?.name;
      getStateObject.id = getAllState[i]?.isoCode;
      getStateArry.push(getStateObject);
    }
    console.log("qwert",getStateArry);
    setStateData(getStateArry);
  }

  function getCitys() {
    let getAllCity = City.getCitiesOfState(`${countryIsoValue?.id}`, `${stateIsoValue?.id}`);
    console.log("hello country",countryIsoValue,stateIsoValue);
    let getCityArry = [];
    for (var i = 0; i < getAllCity.length; i++) {
      let getCityObject = {};
      getCityObject.label = getAllCity[i]?.name;
      getCityObject.id = getAllCity[i]?.stateCode;
      getCityArry.push(getCityObject);
    }
    console.log("countryDataavalueee1222", getAllCity);
    setCitysData(getCityArry);
  }
  
  const getCountryISO = (countryName) => {
    let getAllCountry = Country.getAllCountries();
    console.log("the country",countryName);
    const country = getAllCountry?.find(country => country?.name === countryName);
    const tempData= {
      label:country?.name,
      id:country?.isoCode,
    };
    return tempData;
  }
  const scrollTo = () => { window.scrollTo({ top: contactSection.current.offsetTop, behavior: 'smooth', });}
  const getStateISO = (stateName) => {
    let getAllState = State.getStatesOfCountry(`${countryIsoValue?.id}`);
    console.log("the country",stateName);
    const state = getAllState?.find(state => state?.name === stateName);
    console.log("wow2",state);
    const tempData= {
      label:state?.name,
      id:state?.isoCode,
    };
    console.log("wow",tempData);
    return tempData;
  }
//New Lead Code Start
const getValueAddNewContactButton = () => {
  const newContactData = {
    LeadTitle: document.getElementById("LeadTitle")?.value,
    Handler: document.getElementById("Handler")?.value,
    LeadDate: leadData
      ? leadData.LeadDate
      : leadDate
      ? leadDate
      : LedData?.LeadDate
      ? LedData?.LeadDate
      : "",
      FollowUpDate: leadData
      ? leadData.FollowUpDate
      : FollowUpDate
      ? FollowUpDate
      : LedData?.FollowUpDate
      ? LedData?.FollowUpDate
      : "",
    Source: document.getElementById("Source")?.value,
    SourceTypeOther: document.getElementById("SourceTypeOther")?.value,
    SourceName: document.getElementById("SourceName")?.value,
    CompanyType: document.getElementById("CompanyType")?.value,
    RequirementType: document.getElementById("RequirementType")?.value,
    OtherRequirementType: document.getElementById("OtherRequirementType")?.value,
    SkillSet: values ? values : "",
    Requirement: document.getElementById("Requirement")?.value,
    Stage: document.getElementById("Stage")?.value,
    CompanyID: leadData
      ? leadData?.CompanyID
      : companyForLocal
      ? companyForLocal?.id
      : LedData?.CompanyID
      ? LedData?.CompanyID
      : "",
    CompanyName: leadData
      ? leadData?.CompanyName
      : companyForLocal
      ? companyForLocal?.label
      : LedData?.CompanyName
      ? LedData?.CompanyName
      : "",
    CompanyContactPersonID: finalContactId?.toString(),
    LeadID: state?.LeadID ? state?.LeadID : LedData ? LedData.LeadID : "",
    PreviousStageStore: PrevStageData?.Stage
      ? PrevStageData?.Stage
      : LedData?.PreviousStageStore
      ? LedData?.PreviousStageStore
      : "",
  };

  const newLead = {
    test: "test",
  };
  localStorage.setItem("newData", JSON.stringify(newLead));
  localStorage.setItem("leadData", JSON.stringify(newContactData));
  // navigate("/layouts/lead/Add");
  scrollTo();
  console.log("newContactData", newContactData);
};
const getValueSaveButton = () => {
  setSelectSaveButtonvalue("save");
};
const columns = [
  {
    name: "Company Contact Person ID",
    selector: "CompanyContactPersonID",
    filter: false,
    sortable: false,
  },

  {
    name: "Name",
    selector: "FirstName",
    filter: false,
    sortable: false,
  },
  {
    name: "Email",
    selector: "Email",
    filter: false,
    sortable: false,
  },
  {
    name: "Phone",
    selector: "PhoneNumber",
    filter: false,
    sortable: false,
  },

  {
    name: "Action",
    body: (data) => actionTemplate(data),
    ignoreRowClick: true,
    allowOverflow: true,
    button: true,
  },
];
//New Lead Code End

const setFinalContactID = () => {
  let dummyArry = [];
  for (let i = 0; i < selectedContactId?.length; i++) {
    console.log("text00", i);
    console.log("selectedContactid11", selectedContactId[i]?.id);
    dummyArry.push(selectedContactId[i]?.id);
  }
  setFinalContactId(dummyArry);
  console.log("dummyArry", dummyArry);
};
  
  useEffect(() => {
    getCountry();
    getStateData1();
    
  }, []);

  useEffect(() => {
    if (contactId) {
      setSelectedContactId(
        contactId?.map((item) => ({
          id: item?.CompanyContactPersonID,
        }))
      );
    }
  }, [contactId]);
  useEffect(() => {
    getStates();
    if(companyData?.Country){
      const temp2=getStateISO(companyData?.State);
      setStateIsoValue(temp2);
    }
  }, [countryIsoValue]);
  useEffect(() => {
    getCitys();
  }, [stateIsoValue]);
  useEffect(()=>{
    if(companyData){
      console.log("ccdd",companyData);
      const temp=getCountryISO(companyData?.Country);
      setCountryIsoValue(temp);
    }
  },[companyData]);

  useEffect(()=>{
    if(state==null) setLeadData(null);
 },[state]);
 useEffect(
   (v) => {
     if (companyForLocal) {
       setCompanyId(companyForLocal?.id);
     }
   },
   [companyForLocal]
 );

 useEffect(() => {
  if (selectedContactId) {
    setFinalContactID();
  }
}, [selectedContactId]);
  // GetCountry State City--------------------------

  const getStateData1 = () => {
    // const formattedData = {
    //   StringMapType: "STATE",
    //   SortType: "Alpha",
    // };

    axios({
      url: CommonConfig.ApiUrl + "authentication/getCompanyList",
      method: "GET",
      headers: {},
      data: "",
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("StateList===>", res.data.data[0]);

          setStateList(
            res.data.data[0].map((item) => ({
              id: item.CompanyID,
              label: item.CompanyName,
            }))
          );
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };

  const onStateChange = (event, value) => {
    console.log("State::", value);
    setStateId(value.id);
    setStateLabel(value.label);
  };
  let dir = "https://services.valuecare.org.au/Backend/documents/Referals/";
  // const documentdata = userData.dOC;
  console.log("uploadedFiles", uploadedFiles);

  const IndustryData = [{ label: "Industry1" }, { label: "Industry2" }, { label: "Industry3" }];
  const CompanySizeData = [
    { label: "< 50" },
    { label: "50-100" },
    { label: "100-500" },
    { label: "500-1000" },
    { label: "> 1000" },
  ];
  const SalesTurnoverData = [{ label: "< 50 Cr" }, { label: "50-100 Cr" }, { label: "> 100 Cr" }];

  //   const SourceData = [{ label: "MCA" }, { label: "B.tech" }, { label: "MBA" }, { label: "M.tech" }];
  const RequirementTypeData = [
    { label: "Web App" },
    { label: "Mobile App" },
    { label: "Digital Marketing" },
    { label: "Other" },
  ];

  const SkillList = [{ label: "< NET" }, { label: "React" }, { label: "Node" }];

  const PhoneTypeData = [{ label: "Work" }, { label: "Personal" }];
  const CountryCodeData = [{ label: "+61" }, { label: "+91" }];
  const stateListStatic = [
    { label: "State1" },
    { label: "State2" },
    { label: "State3" },
    { label: "State4" },
  ];
  const CountryData = [{ label: "Australia" }, { label: "India" }];
  const StageData = [
    { label: "Lead" },
    { label: "Follow Up" },
    { label: "Won" },
    { label: "Lost" },
    { label: "Purged" },
  ];

  const SourceData = [
    { label: "LinkedIn" },
    { label: "Upwork" },
    { label: "Referral Network" },
    { label: "Other" },
  ];

  console.log("stateListQQ", stateList);
  const getStateData = () => {
    const formattedData = {
      StringMapType: "STATE",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "authentication/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("StateList===>", res.data.data);

          setStateList(
            res.data.data.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };

  const handleFileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
  };
  const handleUploadFiles = (files) => {
    const uploaded = [...uploadedFiles];
    let limitExceeded = false;
    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        if (uploaded.length === MAX_COUNT) setFileLimit(true);
        if (uploaded.length > MAX_COUNT) {
          alert(`You can only add a maximum of ${MAX_COUNT} files`);
          setFileLimit(false);
          limitExceeded = true;
          return true;
        }
      }
    });
    if (!limitExceeded) setUploadedFiles(uploaded);
  };

 
 const setAutocompleteValues = () => {
    let dummyArray = [];
    let formattedData = [];
    let array = leadData?.SkillSet || LedData?.SkillSet?.toString();
    array = array?.split(",");
    for (let i = 0; i < array?.length; i++) {
      if (array[i]) {
        dummyArray.push({
          id: array[i],
          label: array[i],
        });
      }
    }
    for (let i = 0; i < array?.length; i++) {
      formattedData.push(array[i]);
    }
    debugger;
    setValues(formattedData);
    setServiceAutocompleteValues(dummyArray);
  };
  const onServiceChange = (event, value) => {
    console.log("VALUEService", value);
    let formattedData = [];

    for (let i = 0; i < value.length; i++) {
      formattedData.push(value[i].label);
    }

    setValues(formattedData);
    setServiceAutocompleteValues(value);
    console.log("onService", values);
  };

  const getServiceData = () => {
    const formattedData = {
      StringMapType: "SKILLSET",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "authentication/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("ServiceList===>", res.data.data);
          setServiceList(
            res.data.data.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getIndustryData = () => {
    const formattedData = {
      StringMapType: "INDUSTRY",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "authentication/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("ServiceList===>", res.data.data);
          setIndustryData(
            res.data.data.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  console.log("handlerDataQQ", industryData);

  const getSalesTurnoverData = () => {
    const formattedData = {
      StringMapType: "SALESTURNOVER",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "authentication/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("ServiceList===>", res.data.data);
          setSalesTurnoverData(
            res.data.data.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSourceData = () => {
    const formattedData = {
      StringMapType: "SOURCES",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "authentication/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("ServiceList===>", res.data.data);
          setSourceDataList(
            res.data.data.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getNoOfEmployeData = () => {
    const formattedData = {
      StringMapType: "NOOFEMPLOYEES",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "authentication/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("ServiceList===>", res.data.data);
          setNoOfEmployeData(
            res.data.data.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAddressTypeData = () => {
    const formattedData = {
      StringMapType: "PHONETYPE",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "authentication/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("ServiceList===>", res.data.data);
          setAddressTypeData(
            res.data.data.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getStateData1();
    getContactBYCompany();
  }, [companyId]);
  useEffect(() => {
    getAddressTypeData();
    getNoOfEmployeData();
    getSourceData();
    getSalesTurnoverData();
    getIndustryData();
    getStateData();
    getStateData1();
    getServiceData();
    //New Lead Code Start
    getServiceDataSkill();
    getNoteList(state?.LeadID ? state?.LeadID : LedData ? LedData.LeadID : "");
    getHandlerData()
    //New Lead Code End
    //New Contact Code Start
    getDesignationData();
    getPhoneEmailTypeData();
      //New Contact Code End
  }, []);
  const getStageData = () => {
    const formattedData = {
      StringMapType: "STAGE",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "authentication/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("ServiceList===>", res.data.data);
          setStageData(
            res.data.data.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getStateData1();
    getStageData();
    getRquirementTypeData();
    getHandlerData();
    getServiceData();
    setAutocompleteValues();
    getNoteList(state?.LeadID ? state?.LeadID : LedData ? LedData.LeadID : "");
  }, []);

  useEffect(() => {
    if (companyData) {
      setSourceValue(companyData?.SourceType);
    }
  }, [companyData]);

  console.log("UserData = ", companyData);
  const Industrydata = {
    id: companyData?.Industry,
    label: companyData?.Industry,
  };
  const CompanySizedata = {
    id: companyData?.CompanySize,
    label: companyData?.CompanySize,
  };
  const SalesTurnoverdata = {
    id: companyData?.SalesTurnover,
    label: companyData?.SalesTurnover,
  };
  const Sourcedata = {
    id: companyData?.Source,
    label: companyData?.Source,
  };

  const AddressTypedata = {
    id: companyData?.AddressType,
    label: companyData?.AddressType,
  };

  const Statedata = {
    label: companyData?.State,
  };

  const Countrydata = {
    label: companyData?.Country,
  };
//New Contact Code Start
var CompanyData = {};
  // if (contactData && setoffice) {
  CompanyData = {
    id: contactData?.CompanyID,
    label: contactData?.CompanyName,
  };

  const DesignationData = {
    id: contactData?.Designation,
    label: contactData?.Designation,
  };
  const PhoneTypedata = {
    id: contactData?.PhoneType,
    label: contactData?.PhoneType,
  };
  const EmailTypedata = {
    id: contactData?.EmailType,
    label: contactData?.EmailType,
  };
//New Contact Code End





//New Lead Code Start
  const Companydata = {
    id: leadData?.CompanyName ? leadData?.CompanyID : LedData?.CompanyID,
    label: leadData?.CompanyName ? leadData?.CompanyName : LedData?.CompanyName,
  };
  const Handlerdata = {
    id: leadData?.HandlerPersonid ? leadData?.HandlerPersonid : LedData?.Handler,
    label: leadData?.HandlerPersonid ? leadData?.HandlerPersonid : LedData?.Handler,
  };
  const RequirementTypeDataList = {
    id: leadData?.RequirementType ? leadData?.RequirementType : LedData?.RequirementType,
    label: leadData?.RequirementType ? leadData?.RequirementType : LedData?.RequirementType,
  };
  const StageDataList = {
    id: leadData?.Stage ? leadData?.Stage : LedData?.Stage,
    label: leadData?.Stage ? leadData?.Stage : LedData?.Stage,
  };
  //New Lead Code End
  const {
    register,
    handleSubmit,
    watch,
    control,
    // errors,
    formState: { errors },
    getValues,
    reset,
  } = useForm({
    //Company Form
    resolver: yupResolver(schema),
    defaultValues: {
      CompanyName: companyData?.CompanyName ? companyData?.CompanyName : "",
      Industry: companyData?.Industry ? Industrydata : "",
      CompanySize: companyData?.CompanySize ? CompanySizedata : "",
      SalesTurnover: companyData?.SalesTurnover ? SalesTurnoverdata : "",
      Website: companyData?.Website ? companyData?.Website : "",
      Source: companyData?.Source ? Sourcedata : "",
      Other: companyData?.Other ? companyData?.Other : "",
      Notes: companyData?.Notes ? companyData?.Notes : "",
      AddressType: companyData?.AddressType ? AddressTypedata : "",
      AddressLine1: companyData?.AddrLine1 ? companyData?.AddrLine1 : "",
      AddressLine2: companyData?.AddrLine2 ? companyData?.AddrLine2 : "",
      
      City: companyData?.City ? companyData?.City : "",
      PostalCode: companyData?.PostalCode ? companyData?.PostalCode : "",
      // State: companyData?.State ? Statedata : "",
      // Country: companyData?.Country ? Countrydata : "",
      State: companyData?.State ? companyData?.State : "",
      Country: companyData?.Country ? companyData?.Country : "",
    }, 
  });
   //New Lead Code Start
   const {
    register:register2,
    handleSubmit:handleSubmit2,
    watch:watch2,
    control:control2,
    // errors,
    formState: { errors:errors2 },
    getValues:getValues2,
    reset:reset2,
  } = useForm({
    //Company Form
   // resolver: yupResolver(schema),
    defaultValues: {
    
      LeadTitle: leadData?.LeadTitle ? leadData?.LeadTitle : LedData?.LeadTitle,
      Handler: LedData?.Handler || leadData?.HandlerPersonid ? Handlerdata : "",
      LeadDate: leadData?.LeadDate ? leadData?.LeadDate : LedData?.LeadDate,
      Source: LedData?.Source || leadData?.SourceType ? Sourcedata : "",
      SourceTypeOther: leadData?.SourceTypeOther
        ? leadData?.SourceTypeOther
        : LedData?.SourceTypeOther,
      SourceName: leadData?.SourceName ? leadData?.SourceName : LedData?.SourceName,
      CompanyID: LedData?.CompanyID || leadData?.CompanyName ? Companydata : "",
      NewCompany: LedData?.NewCompany ? LedData?.NewCompany : "",
      CompanyType: LedData?.CompanyType ? LedData?.CompanyType : "Existing Company",
      RequirementType:
        LedData?.RequirementType || leadData?.RequirementType ? RequirementTypeDataList : "",
      OtherRequirementType: leadData?.OtherRequirementType
        ? leadData?.OtherRequirementType
        : LedData?.OtherRequirementType,
      Requirement: leadData?.Requirement ? leadData?.Requirement : LedData?.Requirement,
      Stage: leadData?.Stage || leadData?.Stage ? StageDataList : "",
      
      followUpValue: leadData?.Stage || leadData?.Stage ? StageDataList : "",
      FollowUpDate: leadData?.FollowUpDate ? leadData?.FollowUpDate : LedData?.FollowUpDate,
    },
   
  });
  const CompanyType = watch("CompanyType");
 //New Lead Code End

//New Contact Code Start


const {
  register:register1,
  handleSubmit: handleSubmit1,
  watch:watch1,
  control:control1,
  // errors,
  formState: { errors:errors1 },
  getValues:getValues1,
  reset:reset1,
} = useForm({
  resolver: yupResolver(schema1),
  defaultValues: {
    CompanyID: contactData?.CompanyID ? CompanyData : "",
    FirstName: contactData?.FirstName ? contactData?.FirstName : "",
    LastName: contactData?.LastName ? contactData?.LastName : "",
    Gender: contactData?.Gender ? contactData?.Gender : "Male",
    //Designation: contactData?.Designation ? DesignationData : "",
    //PhoneType: contactData?.PhoneType ? PhoneTypedata : "",
    // CountryCode: contactData?.CountryCode ? CountryCodedata : "",
    CountryCode: contactData?.CountryCode ? contactData?.CountryCode : "",
    PhoneNumber: contactData?.PhoneNumber ? contactData?.PhoneNumber : "",
    //EmailType: contactData?.EmailType ? EmailTypedata : "",
    Email: contactData?.Email ? contactData?.Email : "",
   // Skype: contactData?.Skype ? contactData?.Skype : "",
    //LinkedIn: contactData?.LinkedIn ? contactData?.LinkedIn : "",
   // Facebook: contactData?.Facebook ? contactData?.Facebook : "",
  },
});


//New Contact Code End





  const GetSource = (e, value) => {
    var setoffice = e.target.value;

    var dataSET = {
      source: setoffice,
    };
    setSourceData(dataSET.source);
    console.log("sourceData", sourceData);
  };

  const cancel = () => {
    navigate("/dashboards/analytics");
    localStorage.removeItem("newData");
  };

  const onSubmitCompany = (data) => {debugger
    console.log("CompanydataQQQ", data);

    let formattedData = "";
    let sevicelist = [];
    sevicelist = values;
    console.log("skill", sevicelist);
    formattedData = {
      ...data,
      CurrentUser: "",
      CompanySize: data.CompanySize?.label,
      Country: data.Country,
      Industry: data.Industry?.label,
      SalesTurnover: data.SalesTurnover?.label,
      State: data.State,
      Source: data.Source?.label,
      AddressType: data.AddressType.label,
    };
    console.log("formattedData=", formattedData);

    let formattedDataT;

    formattedDataT = {
      formattedData,
    };

    if (companyData) {
      formattedDataT = {
        formattedData,
        AddressID: companyData?.AddressID,
        CompanyId: companyData?.CompanyID,
        CurrentUser: "",
      };
    }

    // console.log("formattedDataTTT.....", formattedDataT);

    axios({
      url: CommonConfig.ApiUrl + "authentication/AddUpdateCompany",

      method: "POST",
      headers: {},
      data: formattedDataT,
    })
      .then((res) => {debugger
        console.log("coidres", res.data.data[0][0]);
        localStorage.setItem("NewCompanyId", JSON.stringify(res.data.data[0][0]));
        localStorage.setItem("NewCompanyName", JSON.stringify(res.data.data[0][0]));

        var nc = res.data.data[0][0]['CompanyName'];
        
        console.log("loklokloklokloklokloklok ",nc);

        setLoader(false);
        debugger;
        /// setLoader(!loader);
        if (res.data.success === true) {
          if (companyData) {
            // setLoader(false);
            cogoToast.success("Company Updated Successfully.");
             navigate("/layouts/lead/CompanyList");
          } else if (newData?.test == "" || newData?.test == null) {
            cogoToast.success("Company Added Successfully.");
            getStateData1();
            reset();
            setNewCompanyNameInput(nc);
            // navigate("/layouts/lead/Add");
          } else {
            cogoToast.success("Company Added Successfully.");
            getStateData1();
            reset();
            // navigate("/layouts/lead/Add");
            localStorage.removeItem("newData");
          }
        } else {
          cogoToast.error("Something Went Wrong.");
        }

        console.log("testttttt", res.data);
      })
      .catch((error) => {
        console.log("AddUpdateErro", error);
      });
  };


//New Lead Code Start
const saveNotes = () => {
  if (chNotes === null || chNotes === "") {
    document.getElementById("NotesError").style.display = "block";
    return;
  } else {
    document.getElementById("NotesError").style.display = "none";
  }

  const formattedData = {
    EntityType: "Lead",
    EntityId: state?.LeadID ? state?.LeadID : LedData ? LedData.LeadID : "",
    CurrentUser: "",
    IsFlag: 0,
    NoteType: "Lead",
    NoteTitle: "",
    NoteText: chNotes,
  };
  debugger;
  console.log("formatteddata..", formattedData);
  axios({
    url: CommonConfig.ApiUrl + "authentication/addNote",
    method: "POST",
    headers: {},
    data: formattedData,
  })
    .then((res) => {
      if (res.data.success === true) {
        cogoToast.success("Notes Added Successfully.");
        getNoteList(state?.LeadID ? state?.LeadID : LedData ? LedData.LeadID : "");
        setChNotes("");
      }
      console.log("test...", res.data.data);
    })
    .catch((error) => {
      console.log(error);
    });
};
 const onSubmitLead = (data) => {
    debugger;
    console.log("dataaa", data);

    let LeadDateFormat = CommonConfig.formatDate(data.LeadDate);

    let FollowUpDateFormat = ""

    if(data.FollowUpDate ){
      console.log("the checking date is:",data.FollowUpDate);
      FollowUpDateFormat = CommonConfig.formatDate(data.FollowUpDate);
    }

    if(FollowUpDateFormat == "Invalid date"){
      FollowUpDateFormat = ""
    }

    let formattedData = "";
    let sevicelist = [];
    sevicelist = values;

    console.log("sevicelistAQ", sevicelist.length);

    // if (sevicelist.length == 0) {
    //   document.getElementById("SkillSetError").style.display = "block";
    //   return;
    // } else {
    //   document.getElementById("SkillSetError").style.display = "none";
    // }

    debugger;
    formattedData = {
      ...data,
      CurrentUser: "",
      // CompanyContactPersonID: contactPersonId ? contactPersonId : leadData?.CompanyContactPersonID,
      CompanyContactPersonID: finalContactId?.toString(),
      CompanyID: data.CompanyID ? data.CompanyID?.id : NewCompanyId?.NewCompanyId,
      Handler: data.Handler?.label,
      Source: data.Source?.label,
      LeadDate: LeadDateFormat,
      FollowUpDateFormat: FollowUpDateFormat,
      
      SkillSet: sevicelist?.toString(),
      RequirementType: data.RequirementType?.label,
      Stage: data.Stage?.label,
      PreviousStage: leadData?.Stage
        ? leadData?.Stage
        : LedData?.PreviousStageStore
        ? LedData?.PreviousStageStore
        : "",
      UserId:PersonId[0].PersonId,
      // SourceTypeOther:data.SourceTypeOther,
      SourceTypeOther:data.Source?.label=="Others" ? data.SourceTypeOther : "",

    };
    

    console.log("formattedDataA", formattedData);

    if (formattedData.CompanyID == undefined) {
      cogoToast.error("Please Select Company.");
      document.getElementById("ServicesError").style.display = "block";
      return;
    } else {
      document.getElementById("ServicesError").style.display = "none";
    }

    if (
      formattedData.CompanyContactPersonID == "" ||
      formattedData.CompanyContactPersonID == undefined ||
      leadData?.CompanyContactPersonID == ""
    ) {
      cogoToast.error("Please Select Contact.");
    } else {
      if (selectSaveButtonvalue == "save") {
        formattedData = {
          ...formattedData,
        };
        console.log("formattedDataQQ", formattedData);

        if (leadData) {
          formattedData = {
            ...formattedData,
            LeadID: state?.LeadID ? state?.LeadID : LedData ? LedData.LeadID : "",
          };
          console.log("ISLIVINGS", formattedData);
        }

        if (LedData) {
          formattedData = {
            ...formattedData,
            LeadID: state?.LeadID ? state?.LeadID : LedData ? LedData.LeadID : "",
          };
          console.log("ISLIVINGS", formattedData);
        }

        axios({
          url: CommonConfig.ApiUrl + "authentication/addUpdateLead",
          method: "POST",
          headers: {},
          data: formattedData,
        })
          .then((res) => {
            setLoader(false);
            debugger;
            setLoader(!loader);
            if (res.data.success === true) {
              localStorage.removeItem("leadData");
              localStorage.removeItem("NewCompanyId");
              localStorage.removeItem("NewCompanyName");
              localStorage.removeItem("PrevStageData");

              if (leadData) {
                setLoader(false);
                cogoToast.success("Lead Updated Successfully.");
                reset2();
                setData(data);
                 navigate("/layouts/lead/LeadList");
              } else {setLoader(false);
                cogoToast.success("Lead Added Successfully.");
                reset2();
                
               setData(""); 
                 navigate("/layouts/lead/LeadList");
              }
            } else {
              cogoToast.error("Something Went Wrong.");
            }
            console.log("testttttt", res.data);
          })
          .catch((error) => {
            console.log("AddUpdateErro", error);
          });
      }
    }
  };
  const display = (e) => {
    setLeadDate(CommonConfig.formatDate(e));
  };

  const GetRequirement = (e, value) => {
    var setoffice = e.target.value;

    var dataSET = {
      source: setoffice,
    };
    setRequirementData(dataSET.source);
    console.log("sourceData", requirementData);
  };

  const displayFollow = (e) => {
    console.log("display follow e:",e);
    setFollowupDate(CommonConfig.formatDate(e));

  };

  function editContact(selectedRow) {
    const newContactData = {
      LeadTitle: document.getElementById("LeadTitle")?.value,
      Handler: document.getElementById("Handler")?.value,
      LeadDate: leadData
        ? leadData.LeadDate
        : leadDate
        ? leadDate
        : LedData?.LeadDate
        ? LedData?.LeadDate
        : "",
        FollowUpDate: leadData
        ? leadData.FollowUpDate
        : FollowUpDate
        ? FollowUpDate
        : LedData?.FollowUpDate
        ? LedData?.FollowUpDate
        : "",
      Source: document.getElementById("Source")?.value,
      SourceTypeOther: document.getElementById("SourceTypeOther")?.value,
      SourceName: document.getElementById("SourceName")?.value,
      CompanyType: document.getElementById("CompanyType")?.value,
      RequirementType: document.getElementById("RequirementType")?.value,
      SourceName:document.getElementById("SourceName")?.value,
      OtherRequirementType: document.getElementById("OtherRequirementType")?.value,
      SkillSet: values ? values : "",
      Requirement: document.getElementById("Requirement")?.value,
      Stage: document.getElementById("Stage")?.value,
      CompanyID: leadData
        ? leadData?.CompanyID
        : companyForLocal
        ? companyForLocal?.id
        : LedData?.CompanyID
        ? LedData?.CompanyID
        : "",
      CompanyName: leadData
        ? leadData?.CompanyName
        : companyForLocal
        ? companyForLocal?.label
        : LedData?.CompanyName
        ? LedData?.CompanyName
        : "",
      CompanyContactPersonID: finalContactId?.toString(),
      LeadID: state?.LeadID ? state?.LeadID : LedData ? LedData.LeadID : "",
      PreviousStageStore: PrevStageData?.Stage
        ? PrevStageData?.Stage
        : LedData?.PreviousStageStore
        ? LedData?.PreviousStageStore
        : "",
    };

    const newLead = {
      test: "test",
    };
    localStorage.setItem("newData", JSON.stringify(newLead));
    localStorage.setItem("leadData", JSON.stringify(newContactData));

    navigate("/layouts/lead/NewContact", {
      state: selectedRow,
    });
  }
  function getUniqueCompanyNames(stateList) {
    const uniqueCompanyNames = [];
    const map = new Map();
    stateList.forEach(company => {
      if (!map.has(company.label)) {
        map.set(company.label, true);
        uniqueCompanyNames.push(company);
      }
    });
   console.log("unique company names",uniqueCompanyNames)
    return uniqueCompanyNames;
  }
  const newCompany = () => {debugger
    const newLeadData = {
      LeadTitle: document.getElementById("LeadTitle")?.value,
      Handler: document.getElementById("Handler")?.value,
      LeadDate: leadData
        ? leadData.LeadDate
        : leadDate
        ? leadDate
        : LedData?.LeadDate
        ? LedData?.LeadDate
        : "",
        FollowUpDate: leadData
        ? leadData.FollowUpDate
        : FollowUpDate
        ? FollowUpDate
        : LedData?.FollowUpDate
        ? LedData?.FollowUpDate
        : "",
      Source: document.getElementById("Source")?.value,
      SourceTypeOther: document.getElementById("SourceTypeOther")?.value,
      SourceName: document.getElementById("SourceName")?.value,
      CompanyType: document.getElementById("CompanyType")?.value,
      RequirementType: document.getElementById("RequirementType")?.value,
      OtherRequirementType: document.getElementById("OtherRequirementType")?.value,
      SkillSet: values ? values : "",
      Requirement: document.getElementById("Requirement")?.value,
      Stage: document.getElementById("Stage")?.value,
      CompanyID: leadData
        ? leadData?.CompanyID
        : companyForLocal
        ? companyForLocal?.id
        : LedData?.CompanyID
        ? LedData?.CompanyID
        : "",
      CompanyName: leadData
        ? leadData?.CompanyName
        : companyForLocal
        ? companyForLocal?.label
        : LedData?.CompanyName
        ? LedData?.CompanyName
        : "",
      CompanyContactPersonID: finalContactId?.toString(),
      LeadID: state?.LeadID ? state?.LeadID : LedData ? LedData.LeadID : "",
      PreviousStageStore: PrevStageData?.Stage
        ? PrevStageData?.Stage
        : LedData?.PreviousStageStore
        ? LedData?.PreviousStageStore
        : "",
    };
    const newLead = {
      test: "test",
    };
    localStorage.setItem("newData", JSON.stringify(newLead));
    localStorage.setItem("leadData", JSON.stringify(newLeadData));
    navigate("/layouts/lead/Add");
    console.log("newLeadData", newLeadData);
  };

  const getColoumnsRow = () =>
    columns.map((item, id) => {
      return (
        <Column
          style={{ minWidth: "200px" }}
          key={id}
          sortable={item.sortable}
          body={item?.body}
          filter={item.filter}
          field={item.selector}
          header={item.name}
        />
      );
    });
    const getContactBYCompany = async () => {debugger
      console.log("NewCompanyId?.CompanyID01", NewCompanyId?.CompanyID);
      console.log("companyIdQQQQ", companyId);
      //debugger;
  
      // ? leadData?.CompanyID
      // : companyForLocal
      // ? companyForLocal?.id
      // : LedData?.CompanyID
      // ? LedData?.CompanyID
  
      const formattedData = {
        CompanyID: companyId
          ? companyId
          : NewCompanyId?.NewCompanyId
          ? NewCompanyId?.NewCompanyId
          : leadData?.CompanyID
          ? leadData?.CompanyID
          : LedData?.CompanyID
          ? LedData?.CompanyID
          : "",
      };
      console.log("formattedDataforcompany", formattedData);
  
      try {
        const data = await axios(CommonConfig.ApiUrl + "authentication/getContactBYCompanyID", {
          method: "POST",
          data: formattedData,
        }).then((res) => {
          console.log("res0000", res.data.data[0]);
          setData(res.data.data[0]);
        });
      } catch (error) {
        console.log({ error });
      }
    };

    const newContact = () => {
      const newContactData = {
        LeadTitle: document.getElementById("LeadTitle")?.value,
        Handler: document.getElementById("Handler")?.value,
        LeadDate: leadData
          ? leadData.LeadDate
          : leadDate
          ? leadDate
          : LedData?.LeadDate
          ? LedData?.LeadDate
          : "",
          FollowUpDate: leadData
          ? leadData.FollowUpDate
          : FollowUpDate
          ? FollowUpDate
          : LedData?.FollowUpDate
          ? LedData?.FollowUpDate
          : "",
        Source: document.getElementById("Source")?.value,
        SourceTypeOther: document.getElementById("SourceTypeOther")?.value,
        SourceName: document.getElementById("SourceName")?.value,
        CompanyType: document.getElementById("CompanyType")?.value,
        RequirementType: document.getElementById("RequirementType")?.value,
        OtherRequirementType: document.getElementById("OtherRequirementType")?.value,
        SkillSet: values ? values : "",
        Requirement: document.getElementById("Requirement")?.value,
        Stage: document.getElementById("Stage")?.value,
        CompanyID: leadData
          ? leadData?.CompanyID
          : companyForLocal
          ? companyForLocal?.id
          : LedData?.CompanyID
          ? LedData?.CompanyID
          : "",
        CompanyName: leadData
          ? leadData?.CompanyName
          : companyForLocal
          ? companyForLocal?.label
          : LedData?.CompanyName
          ? LedData?.CompanyName
          : "",
  
        CompanyContactPersonID: finalContactId?.toString(),
        LeadID: state?.LeadID ? state?.LeadID : LedData ? LedData.LeadID : "",
        PreviousStageStore: PrevStageData?.Stage
          ? PrevStageData?.Stage
          : LedData?.PreviousStageStore
          ? LedData?.PreviousStageStore
          : "",
      };
  
      const newLead = {
        test: "test",
      };
      localStorage.setItem("newData", JSON.stringify(newLead));
      localStorage.setItem("leadData", JSON.stringify(newContactData));
      //navigate("/layouts/lead/NewContact");
      scrollTo();
      console.log("newContactDataQA", newContactData);
    };
  
  const actionTemplate = (data) => {
    console.log("tempdata", data);
    return (
      <div>
        <PButton
          type="button"
          icon="pi pi-user-edit"
          className="p-button-success"
          onClick={() => editContact(data)}
          style={{ marginRight: ".5em" }}
          tooltip="Edit Contact"
          tooltipOptions={{ position: "bottom" }}
        />

        <PButton
          type="button"
          icon="pi pi-plus"
          className="p-button-primary AddNotes"
          onClick={() => newContact()}
          // style={{ marginRight: ".5em" }}
          tooltip="Add New Contact"
          tooltipOptions={{ position: "bottom" }}
        />
      </div>
    );
  };

  const getRquirementTypeData = () => {
    const formattedData = {
      StringMapType: "REQUIEMENTTYPE",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "authentication/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("ServiceList===>", res.data.data);
          setRquirementTypeData(
            res.data.data.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getNoteList = () => {
    let noteReqData = {
      EntityType: "Lead",
      EntityId: state?.LeadID ? state?.LeadID : LedData ? LedData.LeadID : "",
      CurrentUser: "",
    };
    axios({
      url: CommonConfig.ApiUrl + "authentication/getNoteList",
      method: "POST",
      headers: {},
      data: noteReqData,
    })
      .then((res) => {
        if (res.data?.success) {
          setNotesData(res.data.data || []);
          document.getElementById("NotesTableDynamic").style.display = "block";
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getHandlerData = () => {
    const formattedData = {
      StringMapType: "HANDLER",
      SortType: "Alpha",
    };
  
    axios({
      url: CommonConfig.ApiUrl + "authentication/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("ServiceList===>", res.data.data);
          setHandlerData(
            res.data.data.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getServiceDataSkill = () => {
    const formattedData = {
      StringMapType: "SKILLSET",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "authentication/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("ServiceList===>", res.data.data);
          setServiceList(
            res.data.data.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
//New Lead Code End

//New Contact Code Start
const getDesignationData = () => {
  const formattedData = {
    StringMapType: "DESIGNITATION",
    SortType: "Alpha",
  };

  axios({
    url: CommonConfig.ApiUrl + "authentication/getDropDownValues",
    method: "POST",
    headers: {},
    data: formattedData,
  })
    .then((res) => {
      if (res.data?.success) {
        console.log("ServiceList===>", res.data.data);
        setDesignationData(
          res.data.data.map((item) => ({
            id: item.StringMapKey,
            label: item.StringMapName,
          }))
        );
      }
    })
    .catch((error) => {
      console.log(error);
    });
};
const onSubmitContact = (data) => {debugger
  
  console.log("ContactdataQQQ", data);

  let formattedData = "";

  let sevicelist = [];
  sevicelist = values;

  console.log("skill", sevicelist);
  formattedData = {
    ...data,
    // CompanyID: LedData?.CompanyID,
    CompanyID: data.CompanyID?.id,
    CurrentUser: "",
    // CompanySize: data.CompanySize?.label,
    Country: data.Country,
    CountryCode: data.CountryCode,
    Designation: data.Designation?.label,
    EmailType: data.EmailType?.label,
    // Industry: data.Industry?.label,
    PhoneType: data.PhoneType?.label,
    // SalesTurnover: data.SalesTurnover?.label,
    State: data.State,
  };
  console.log("formattedData=", formattedData);

  let formattedDataT;

  formattedDataT = {
    ...formattedData,
  };

  if (contactData) {
    formattedDataT = {
      ...formattedData,
      // CompanyID: contactData.CompanyID,
      CompanyContactPersonID: contactData.CompanyContactPersonID,
      EmailID: contactData.EmailID,
      PhoneID: contactData.PhoneID,
      CurrentUser: CommonConfig.getCurrentUserId(),
    };
  }

  console.log("formattedDataTTT.....", formattedDataT);

  axios({
    url: CommonConfig.ApiUrl + "authentication/addUpdateContact",

    method: "POST",
    headers: {},
    data: formattedDataT,
  })
    .then((res) => {
      setLoader(false);
      debugger;
      /// setLoader(!loader);
      if (res.data.success === true) {
        if (contactData) {
          // setLoader(false);
          cogoToast.success("Contact Updated Successfully.");
          navigate("/layouts/lead/Index");
        } else if (newData?.test == "" || newData?.test == null) {
          cogoToast.success("Contact Added Successfully.");
          reset1();
        } else {
          cogoToast.success("Contact Added Successfully.");
          reset1();
          localStorage.removeItem("newData");
        }
      } else {
        cogoToast.error("Something Went Wrong.");
      }

      console.log("testttttt", res.data);
    })
    .catch((error) => {
      console.log("AddUpdateErro", error);
    });
};
const getPhoneEmailTypeData = () => {
  const formattedData = {
    StringMapType: "PHONETYPE",
    SortType: "Alpha",
  };

  axios({
    url: CommonConfig.ApiUrl + "authentication/getDropDownValues",
    method: "POST",
    headers: {},
    data: formattedData,
  })
    .then((res) => {
      if (res.data?.success) {
        console.log("ServiceList===>", res.data.data);
        setPhoneEmailTypeData(
          res.data.data.map((item) => ({
            id: item.StringMapKey,
            label: item.StringMapName,
          }))
        );
      }
    })
    .catch((error) => {
      console.log(error);
    });
};


//New Contact Code End








  return (
    <>
      <div>
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loader}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <DashboardLayout>
        <DashboardNavbar />
        {/* New Company */}
        <Card sx={{ width: "100%", mt: 3 }}>
          <MDBox display="flex">
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="4rem"
              height="4rem"
              variant="gradient"
              bgColor="primary"
              color="white"
              shadow="md"
              borderRadius="xl"
              ml={3}
              mt={-2}
            >
              {companyData ? 
              <Icon fontSize="medium" color="inherit">
                edit
              </Icon> : <Icon fontSize="medium" color="inherit">
                add
              </Icon>}
              
              
            </MDBox>
            <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
              {companyData ? "Edit New Company" : "Add New Company"}
              {/* Referral Form. */}
            </MDTypography>
          </MDBox>
          <MDBox sx={{ pt: 4, pb: 2, pl: 2, pr: 2 }}>
            <form onSubmit={handleSubmit(onSubmitCompany)}>
              <div className="reg_step" id="regStep1">
                <div className="reg_form">
                  <div className="reg_box_bordered">
                    <Grid container spacing={3}>
                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="CompanyName"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                             //   autoComplete="nope"
                                label="Company Name*"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>

                      <Grid item md="4">
                        <div className="reg_input auto-complete">
                          <Controller
                            control={control}
                            name="Industry"
                            // rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                              <Autocomplete
                                disablePortal
                                id="Industry"
                                name="Industry"
                                value={value}
                                options={industryData ? industryData : ""}
                                onChange={(e, v) => onChange(v)}
                                renderInput={(params) => (
                                  <TextField
                                    autoComplete="off"
                                    {...params}
                                    label="Industry"
                                   // error={errors.Industry}
                                   // helperText={errors.Industry ? "Industry Required." : ""}
                                  />
                                )}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                      <Grid item md="4">
                        <div className="reg_input auto-complete">
                          <Controller
                            control={control}
                            name="CompanySize"
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                              <Autocomplete
                                disablePortal
                                id="CompanySize"
                                name="CompanySize"
                                value={value}
                                options={noOfEmployeData ? noOfEmployeData : ""}
                                onChange={(e, v) => onChange(v)}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="No of Employees"
                                    error={errors.CompanySize}
                                    helperText={
                                      errors.CompanySize ? "No of Employees Required." : ""
                                    }
                                  />
                                )}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                      <Grid item md="6">
                        <div className="reg_input auto-complete">
                          <Controller
                            control={control}
                            name="SalesTurnover"
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                              <Autocomplete
                                disablePortal
                                id="SalesTurnover"
                                name="SalesTurnover"
                                value={value}
                                options={salesTurnoverData ? salesTurnoverData : ""}
                                onChange={(e, v) => onChange(v)}
                                renderInput={(params) => (
                                  <TextField
                                    autoComplete="off"
                                    {...params}
                                    label="Sales Turnover"
                                    error={errors.SalesTurnover}
                                    helperText={
                                      errors.SalesTurnover ? "Sales Turnover Required." : ""
                                    }
                                  />
                                )}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                      <Grid item md="6">
                        <div className="reg_input">
                          <Controller
                            name="Website"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Website"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                      <Grid item md="6">
                        <div className="reg_input auto-complete">
                          <Controller
                            control={control}
                            name="Source"
                            // rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                              <Autocomplete
                                disablePortal
                                id="Source"
                                name="Source"
                                value={value}
                                options={sourceDataList ? sourceDataList : ""}
                                onChange={(e, v) => onChange(v, setSourceValue(v))}
                                // onChange={(e, v) => onChange(v)}
                                // onBlur={GetSource}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Source"
                                    // error={errors.Source}
                                    // helperText={errors.Source ? "Source Required." : ""}
                                  />
                                )}
                              />
                            )}
                          />
                        </div>
                      </Grid>

                      <Grid item md="6">
                        {sourceValue?.label == "Others" || sourceValue == "Others" ? (
                          <div className="reg_input">
                            <Controller
                              name="Other"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  label="Other Source"
                                  id="Other"
                                  variant="outlined"
                                  value={value}
                                  onChange={onChange}
                                  error={error}
                                  helperText={error?.message}
                                />
                              )}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                      <Grid item md="12">
                        <div className="reg_input">
                          <Controller
                            name="Notes"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Notes"
                                color="primary"
                                variant="outlined"
                                rows={3}
                                multiline
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <div className="reg_box_bordered">
                    <Grid container spacing={3}>
                      <Grid item md="4">
                        <div className="reg_input auto-complete">
                          <Controller
                            control={control}
                            name="AddressType"
                            // rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                              <Autocomplete
                                disablePortal
                                id="AddressType"
                                name="AddressType"
                                value={value}
                                options={addressTypeData ? addressTypeData : ""}
                                onChange={(e, v) => onChange(v)}
                                //   onBlur={GetSource}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Address Type"
                                    // error={errors.AddressType}
                                    // helperText={errors.AddressType ? "Address Type Required." : ""}
                                  />
                                )}
                              />
                            )}
                          />
                        </div>

                        {/* <div className="reg_input">
                          <Controller
                            name="AddressType"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Address Type*"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div> */}
                      </Grid>
                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="AddressLine1"
                            control={control}
                            defaultValue=""
                           // rules={{ required: true }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                autoComplete="nope"
                                type="text"
                                label="Address Line1"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                               // error={error}
                              //  helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="AddressLine2"
                            control={control}
                            defaultValue=""
                            // rules={{ required: false }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                autoComplete="nope"
                                label="Address Line2"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                      <Grid item md="3">
                        <div className="reg_input auto-complete">

                          {/* <Controller
                            name="Country"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                //autoComplete="nope"
                                label="Country"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          /> */}

  
                          {/* <Controller
                            control={control}
                            name="Country"
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                              <Autocomplete
                                disablePortal
                                id="Country"
                                name="Country"
                                value={value}
                                options={countryData ? countryData : ""}
                                onChange={(e, v) => onChange(v.label, setCountryIsoValue(v.label))}
                                // onChange={(e, v) => console.log("getisocodevalue", v)}
                                //   onBlur={GetSource}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Country"
                                    error={errors.Country}
                                    helperText={errors.Country ? "Country Required." : ""}
                                  />
                                )}
                              />
                            )}
                          /> */}
                           <Controller
                            control={control}
                            name="Country"
                            defaultValue=""
                           rules={{ required: true }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <Autocomplete
                                disablePortal
                                id="Country"
                                name="Country"
                                value={value || null}
                                options={countryData ? countryData : []}
                               // getOptionLabel={(option) => option.label}
                                onChange={(e, v) => {
                                onChange(v.label, setSourceValue(v.label));
                                // onChange={(e, v) => onChange(v)}
                                // onBlur={GetSource}
                                 setCountryIsoValue(v);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Country*"
                                    error={errors.Country}
                                    helperText={errors.Country ? "Country Required." : ""}
                                  />
                                )}
                              />
                            )}
                          />

                        </div>
                      </Grid>

                      <Grid item md="3">
                        <div className="reg_input">

                          {/* <Controller
                            name="State"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                autoComplete="nope"
                                label="State"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          /> */}
                          {/* <Controller
                            name="State"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <Autocomplete
                               autoComplete="nope"
                               options={stateData ? stateData : ""}
                               getOptionLabel={(state) => state.name}
                               value={value}
                               onChange={(e, selectedState) => onChange(selectedState)}
                               renderInput={(params) => (
                              <TextField
                                {...params}
                                label="State"
                                variant="outlined"
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                            />
                          )}
                         /> */}
                         <Controller
                            control={control}
                            name="State"
                           // rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                              <Autocomplete
                                disablePortal
                                id="State"
                                name="State"
                                value={value || null}
                                options={stateData ? stateData : []}
                                onChange={(e, v) => {
                                onChange(v.label, setStateIsoValue(v.label))
                                // onChange={(e, v) => console.log("getisocodevalue", v)}
                                //   onBlur={GetSource}
                                setStateIsoValue(v);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="State"
                                  //  error={errors.State}
                                  //  helperText={errors.State ? "State Required." : ""}
                                  />
                                )}
                              />
                            )}
                          />



                          {/* <Controller
                            name="State"
                            control={control}
                            defaultValue=""
                            rules={{ required: true }}
                            render={({ field: { onChange, value },fieldState: { error } }) => (
                            <Autocomplete
                              disablePortal
                              id="State"
                              name="State"
                              value={value || null}
                              options={stateData ? stateData : ""}
                              getOptionLabel={(state) => state.label}
                              onChange={(e, v) => 
                                 {onChange(v.label,setSourceValue(v.label));
                                       setStateIsoValue(v);
                              }}
                              renderInput={(params) => (
                              <TextField
                                 {...params}
                                 label="State"
                                 // variant="outlined"
                                 error={errors.State}
                                 helperText={errors.State ? "State Required." : ""}
                               />
                              )}
                              />
                             )}
                            /> */}
                        </div>
                      </Grid>

                      <Grid item md="3">
                        <div className="reg_input">
                          {/* <Controller
                            name="City"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="City"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          /> */}

                          {/* <Controller
                             name="City"
                             control={control}
                             defaultValue=""
                             rules={{ required: true }}
                             render={({ field: { onChange, value }}) => (
                             <Autocomplete
                              disablePortal
                              id="City"
                              name="City"
                              value={value || null}
                              options={citysData ? citysData : ""}
                              getOptionLabel={(city) => city.label}
                              onChange={(e, v) => 
                              onChange(v.label,setSourceValue(v.label))}
                              renderInput={(params) => (
                              <TextField
                               {...params}
                               label="City"
                               // variant="outlined"
                               error={errors.City}
                               helperText={errors.City ? "City Required." : ""} 
                              />
                              )}
                             />
                            )}
                          /> */}
                           <Controller
                            control={control}
                            name="City"
                            //defaultValue=""
                          //  rules={{ required: true }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <Autocomplete
                                //disablePortal
                                id="City"
                                name="City"
                                value={value || null}
                                options={citysData ? citysData : []}
                               // getOptionLabel={(option) => option.label}
                                onChange={(e, v) => {
                                onChange(v.label, setSourceValue(v.label));
                                // onChange={(e, v) => onChange(v)}
                                // onBlur={GetSource}
                                 //setCountryIsoValue(v);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="City"
                                //    error={errors.City}
                                  //  helperText={errors.City ? "City Required." : ""}
                                  />
                                )}
                              />
                            )}
                          />
                        </div>
                      </Grid>

                      <Grid item md="3">
                        <div className="reg_input">
                          <Controller
                            name="PostalCode"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Postal Code"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                            //    error={error}
                               // helperText={error?.message}
                                inputProps={{
                                  maxLength: 6,
                                }}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                    </Grid>

                    <Grid container spacing={3}></Grid>
                  </div>
                </div>
              </div>
              <div className="reg_submit">
                {newData?.test == "" || newData?.test == null ? (
                  <MDButton
                    onClick={() => navigate("/layouts/lead/CompanyList")}
                    variant="contained"
                    color="secondary"
                  >
                    Cancel
                  </MDButton>
                ) : (
                  <MDButton onClick={() => cancel()} variant="contained" color="secondary">
                    Cancel
                  </MDButton>
                )}

                {companyData ? (
                  <MDButton type="submit" variant="contained" color="primary">
                    Update
                  </MDButton>
                ) : (
                  <MDButton type="submit" variant="contained" color="primary">
                    Save
                  </MDButton>
                )}
              </div>
            </form>
          </MDBox>
        </Card>
        {/* New Contact */}
        <Card sx={{ width: "100%", mt: 3 }} className="section section2" ref={contactSection}>
          <MDBox display="flex">
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="4rem"
              height="4rem"
              variant="gradient"
              bgColor="primary"
              color="white"
              shadow="md"
              borderRadius="xl"
              ml={3}
              mt={-2}
            >
              <Icon fontSize="medium" color="inherit">
                add
              </Icon>
            </MDBox>
            <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
              {contactData ? "Edit Contact" : "Add New Contact"}
              {/* Referral Form. */}
            </MDTypography>
          </MDBox>
          <MDBox sx={{ pt: 4, pb: 2, pl: 2, pr: 2 }}>
            <form  onSubmit={handleSubmit1(onSubmitContact)}>
              <div className="reg_step" id="regStep1">
                <div className="reg_form">
                  
                  <Grid container spacing={3}>
                    <Grid item md="6">
                      <div className="reg_input auto-complete">
                        <Controller
                          control={control1}
                          name="CompanyID"
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              //inputValue={NewCompanyNameInput}
                              disablePortal
                              id="CompanyID"
                              name="CompanyID"
                              value={value}
                              options={stateList ? getUniqueCompanyNames(stateList) : ""}
                              onChange={(e, v) => onChange(v)}
                              renderInput={(params) => (
                                <TextField
                                  readonly
                                  {...params}
                                  label="Company*"
                                  error={errors.CompanyID}
                                  helperText={errors.CompanyID ? "Company Required." : ""}
                                />
                              )}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item md="6">
                      <div className="reg_input">
                        <Controller
                          name="FirstName"
                          control={control1}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="First Name*"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid>

                    <Grid item md="6">
                      <div className="reg_input">
                        <Controller
                          name="LastName"
                          control={control1}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="Last Name*"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item md="6">
                      <div className="reg_input">
                        <InputLabel>Gender</InputLabel>
                        <Controller
                          name="Gender"
                          control={control1}
                          defaultValue={contactData ? contactData?.Gender : "Male"}
                          rules={{ required: true }}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <>
                              {" "}
                              <RadioGroup
                                aria-label="appointment"
                                row="true"
                                defaultValue={contactData ? contactData?.Gender : "Male"}
                              >
                                <FormControlLabel
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  value="Male"
                                  control={<Radio />}
                                  label="Male"
                                />
                                <FormControlLabel
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  value="Female"
                                  control={<Radio />}
                                  label="Female"
                                />
                                <FormControlLabel
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  value="Unknown"
                                  control={<Radio />}
                                  label="Unknown"
                                />
                              </RadioGroup>
                            </>
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item md="6">
                      <div className="reg_input auto-complete">
                        <Controller
                          control={control1}
                          name="Designation"
                          // rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              disablePortal
                              id="Designation"
                              name="Designation"
                              value={value}
                              options={designationData ? designationData : ""}
                              onChange={(e, v) => onChange(v)}
                              //   onBlur={GetSource}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Designation"
                                  // error={errors.Designation}
                                  // helperText={errors.Designation ? "Designation Required." : ""}
                                />
                              )}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <div className="reg_box_bordered">
                    <Grid container spacing={3}>
                      <Grid item md="3">
                        <div className="reg_input auto-complete">
                          <Controller
                            control={control1}
                            name="PhoneType"
                            // rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                              <Autocomplete
                                disablePortal
                                id="PhoneType"
                                name="PhoneType"
                                value={value}
                                options={phoneEmailTypeData ? phoneEmailTypeData : ""}
                                onChange={(e, v) => onChange(v)}
                                //   onBlur={GetSource}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Phone Type"
                                    // error={errors.PhoneType}
                                    // helperText={errors.PhoneType ? "Phone Type Required." : ""}
                                  />
                                )}
                              />
                            )}
                          />
                        </div>
                      </Grid>

                      <Grid item md="3">
                        <div className="reg_input">
                          <Controller
                            name="CountryCode"
                            control={control1}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Country Code*"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />

                          {/* <Controller
                            control={control}
                            name="CountryCode"
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                              <Autocomplete
                                disablePortal
                                id="CountryCode"
                                name="CountryCode"
                                value={value}
                                options={CountryCodeData ? CountryCodeData : ""}
                                onChange={(e, v) => onChange(v)}
                                //   onBlur={GetSource}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Country Code*"
                                    error={errors.CountryCode}
                                    helperText={errors.CountryCode ? "Country Code Required." : ""}
                                  />
                                )}
                              />
                            )}
                          /> */}
                        </div>
                      </Grid>
                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="PhoneNumber"
                            control={control1}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                type="number"
                                label="Phone Number*"
                                variant="outlined"
                                maxLength="10"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                                inputProps={{
                                  maxLength: 10,
                                }}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <div className="reg_box_bordered">
                    <Grid container spacing={3}>
                      <Grid item md="4">
                        <div className="reg_input auto-complete">
                          <Controller
                            control={control1}
                            name="EmailType"
                            // rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                              <Autocomplete
                                disablePortal
                                id="EmailType"
                                name="EmailType"
                                value={value}
                                options={phoneEmailTypeData ? phoneEmailTypeData : ""}
                                onChange={(e, v) => onChange(v)}
                                //   onBlur={GetSource}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Email Type"
                                    // error={errors.EmailType}
                                    // helperText={errors.EmailType ? "Email Type Required." : ""}
                                  />
                                )}
                              />
                            )}
                          />
                        </div>
                      </Grid>

                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="Email"
                            control={control1}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Email*"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </div>

                  <Grid container spacing={3}>
                    <Grid item md="4">
                      <div className="reg_input">
                        <Controller
                          name="Skype"
                          control={control1}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="Skype"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item md="4">
                      <div className="reg_input">
                        <Controller
                          name="LinkedIn"
                          control={control1}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="LinkedIn"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item md="4">
                      <div className="reg_input">
                        <Controller
                          name="Facebook"
                          control={control1}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="Facebook"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>

              <div className="reg_submit">
                {newData?.test == "" || newData?.test == null ? (
                  <MDButton
                    onClick={() => navigate("/dashboards/analytics")}
                    variant="contained"
                    color="secondary"
                  >
                    Cancel
                  </MDButton>
                ) : (
                  <MDButton onClick={() => cancel()} variant="contained" color="secondary">
                    Cancel
                  </MDButton>
                )}

                {/* <MDButton
                  onClick={() => navigate("/layouts/lead/Index")}
                  variant="contained"
                  color="secondary"
                >
                  Cancel
                </MDButton> */}
                <MDButton type="submit" variant="contained" color="primary">
                  Save
                </MDButton>
              </div>

             
            </form>
          </MDBox>
        </Card>
         {/* New Lead */}       
        <Card sx={{ width: "100%", mt: 3 }}>
          <MDBox display="flex">
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="4rem"
              height="4rem"
              variant="gradient"
              bgColor="primary"
              color="white"
              shadow="md"
              borderRadius="xl"
              ml={3}
              mt={-2}
            >
              <Icon fontSize="medium" color="inherit">
                add
              </Icon>
            </MDBox>
            <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
              {leadData ? "Edit Lead" : "Add Lead"}
            </MDTypography>
            <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: "auto", mr: 2 }}>
              {leadData ? "Lead No : " : ""}
              {leadData?.LeadNumber}
            </MDTypography>
          </MDBox>
          <MDBox sx={{ pt: 4, pb: 2, pl: 2, pr: 2 }}>
            <form onSubmit={handleSubmit2(onSubmitLead)}>
              <div className="reg_step" id="regStep1">
                <div className="reg_form">
                  <Grid container spacing={3}>
                    <Grid item md="4">
                      <div className="reg_input">
                        <Controller
                          name="LeadTitle"
                          control={control2}
                          defaultValue=""
                          rules={{ required: true }}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="Lead Title*"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              id="LeadTitle"
                              error={errors2.LeadTitle}
                              helperText={errors2.LeadTitle ? "Lead Title Required." : ""}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item md="4">
                      <div className="reg_input auto-complete">
                        <Controller
                          control={control2}
                          name="Handler"
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              disablePortal
                              id="Handler"
                              name="Handler"
                              value={value}
                              options={handlerData ? handlerData : ""}
                              onChange={(e, v) => onChange(v)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Handler*"
                                  error={errors2.Handler}
                                  helperText={errors2.Handler ? "Handler Required." : ""}
                                />
                              )}
                            />
                          )}
                        />
                      </div>
                    </Grid>

                    <Grid item md="4">
                      <div className="reg_input">
                        <Controller
                          name="LeadDate"
                          defaultValue=""
                          control={control2}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <LocalizationProvider dateAdapter={DateFnsUtils}>
                              <DatePicker
                                id="LeadDate"
                                label="Lead Date"
                                value={value || null}
                                //inputFormat="dd/MM/yyyy"
                                 inputFormat="MM/dd/yyyy"
                                onChange={(date) => {
                                  onChange(date);
                                  display(date);
                                }}
                                // minDate={new Date("15-08-1947")}
                                maxDate={new Date()}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    // error={errors.LeadDate}
                                    // helperText={errors.LeadDate ? "Lead Date Required." : ""}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    
                  <Grid item md="4">
                      <div className="reg_input auto-complete">
                        <Controller
                          control={control2}
                          name="RequirementType"
                          // rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              disablePortal
                              id="RequirementType"
                              name="RequirementType"
                              value={value}
                              options={rquirementTypeData ? rquirementTypeData : ""}
                              onChange={(e, v) => onChange(v)}
                              onBlur={GetRequirement}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Requirement Type"
                                  // error={errors.RequirementType}
                                  // helperText={
                                  //   errors.RequirementType ? "Requirement Type Required." : ""
                                  // }
                                />
                              )}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                    
                    <Grid item md="4">
                      <div className="reg_input auto-complete">
                        <Controller
                          control={control2}
                          name="Source"
                          // rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              disablePortal
                              id="Source"
                              name="Source"
                              value={value}
                              options={sourceDataList ? sourceDataList : ""}
                              onChange={(e, v) => onChange(v, setSourceValue(v))}
                              // onBlur={GetSource}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Source"
                                  // error={errors.Source}
                                  // helperText={errors.Source ? "Source Required." : ""}
                                />
                              )}
                            />
                          )}
                        />
                      </div>
                    </Grid>

                    <Grid item md="4">
                      {sourceValue?.label == "Others" || sourceValue == "Others" ? (
                        <div className="reg_input">
                          <Controller
                            name="SourceTypeOther"
                            control={control2}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Other Source Name"
                                id="SourceTypeOther"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    {/* <Grid item md="4">
                      <div className="reg_input">
                        <Controller
                          name="SourceName"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              id="SourceName"
                              label="Source Name*"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid> */}

                    {/* <Grid item md="4">
                      <div className="reg_input auto-complete">
                        <Controller
                          control={control}
                          name="RequirementType"
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              disablePortal
                              id="RequirementType"
                              name="RequirementType"
                              value={value}
                              options={rquirementTypeData ? rquirementTypeData : ""}
                              onChange={(e, v) => onChange(v)}
                              onBlur={GetRequirement}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Requirement Type*"
                                  error={errors.RequirementType}
                                  helperText={
                                    errors.RequirementType ? "Requirement Type Required." : ""
                                  }
                                />
                              )}
                            />
                          )}
                        />
                      </div>
                    </Grid> */}

                    <Grid item md="4">
                      {requirementData == "Other" ? (
                        <div className="reg_input">
                          <Controller
                            name="OtherRequirementType"
                            control={control2}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                id="OtherRequirementType"
                                label="Requirement Type"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>


                  <Grid container spacing={3}>
                    <Grid item md="6">
                      <div className="reg_input sec_level">
                        <Autocomplete
                          filterSelectedOptions
                          multiple
                          id="SkillSet"
                          name="SkillSet"
                          options={serviceList ? serviceList : []}
                          value={serviceAutocompleteValues ? serviceAutocompleteValues : []}
                          defaultValue={serviceAutocompleteValues ? serviceAutocompleteValues : []}
                          onChange={onServiceChange}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Skill Set"
                              placeholder="Skill Set"
                              // error={errorservices}
                              // helperText={errorservices}
                              // id="SkillSet"
                              // name="SkillSet"
                              // defaultValue={Params}
                              variant="outlined"
                              rows={1.5}
                              multiline
                              formControlProps={{
                                fullWidth: true,
                              }}
                            />
                          )}
                        />
                        {/* <span class="errorTC" id="SkillSetError">
                          Please Select Skill Set.
                        </span> */}
                      </div>
                    </Grid>
                    <Grid item md="6">
                      <div className="reg_input">
                        <Controller
                          name="Requirement"
                          control={control2}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              id="Requirement"
                              label="Requirement"
                              color="primary"
                              variant="outlined"
                              rows={2}
                              multiline
                              formControlProps={{
                                fullWidth: true,
                              }}
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    {/* <Grid item md="4">
                      <div className="reg_input">
                        <Controller
                          name="Requirement"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              id="Requirement"
                              label="Requirement*"
                              color="primary"
                              variant="outlined"
                              rows={3}
                              multiline
                              formControlProps={{
                                fullWidth: true,
                              }}
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid> */}

                    <Grid item md="4">
                      <div className="reg_input auto-complete">
                        <Controller
                          control={control2}
                          name="Stage"
                         rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              disablePortal
                              id="Stage"
                              name="Stage"
                              value={value}
                              options={stageData ? stageData : ""}
                              // onChange={(e, v) => onChange(v)}
                              onChange={(e, v) => onChange(v, setFollowUpValue(v))}
                              renderInput={(params) => (
                                <TextField
                                  autoComplete="off"
                                  {...params}
                                  label="Stage"
                                  error={errors2.Stage}
                                  helperText={errors2.Stage ? "Stage Required." : ""}
                                />
                              )}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                    {followUpValue?.label == "Follow Up" || followUpValue == "Follow Up" || (leadData && state?.Stage == "Follow Up") ? (
                      <Grid item md="4">
                        <div className="reg_input auto-complete">
                          <Controller
                            control={control2}
                            name="FollowUpDate"
                            // rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                              <LocalizationProvider dateAdapter={DateFnsUtils}>
                              <DatePicker
                              id="FollowUpDate"
                              label="FollowUp Date"
                              value={value || null}
                              //inputFormat="dd/MM/yyyy"
                              inputFormat="MM/dd/yyyy"
                              onChange={(date) => {
                                onChange(date);
                                console.log("the new checking",value);
                                displayFollow(date);
                              }}
                              minDate={new Date()}
                              //maxDate={new Date()}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  // error={errors.FollowUpDate}
                                  // helperText={errors.FollowUpDate ? "FollowUp Date Required." : ""}
                                />
                              )}
                            />
                            </LocalizationProvider>
                              
                            )}
                          />

                            


                        </div>
                      </Grid>
                    ) : (
                      ""
                    )}

                   
                  </Grid>

                   <Grid container spacing={3}>
                   {/* <Grid item md="4">
                      <div className="reg_input check_value">
                        <Controller
                          name="CompanyType"
                          id="CompanyType"
                          control={control}
                          defaultValue=""
                          rules={{ required: true }}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <>
                              {" "}
                              <RadioGroup
                                aria-label="appointment"
                                row="true"
                                defaultValue={
                                  LedData?.CompanyType ? LedData?.CompanyType : "Existing Company"
                                }
                              >
                                < FormControlLabel
                                  id="CompanyType"
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  value="New Company"
                                  control={<Radio checked={CompanyType === "New Company"} />}
                                  label="New Company"
                              />
                                <FormControlLabel
                                  id="CompanyType"
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  value="Existing Company"
                                  control={<Radio checked={CompanyType === "Existing Company"} />}
                                  label="Existing Company"
                                />
                              </RadioGroup>
                            </>
                          )}
                        />
                      </div>
                    </Grid>
                    {CompanyType == "Existing Company" && ( */}
                      <Grid item md="4">
                        <div className="reg_input auto-complete">
                          <Controller
                            control={control2}
                            name="CompanyID"
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                              <Autocomplete
                                disablePortal
                                id="CompanyID"
                                name="CompanyID"
                                value={value}
                                options={stateList ? getUniqueCompanyNames(stateList) : ""}
                                onChange={(e, v) => onChange(v, setCompanyForLocal(v))}
                                // onChange={(e, v) => onChange(v)}
                                // onChange={(e, v) => {
                                //   onChange(v);
                                //   // getCompanyId(e, v);
                                //   console.log("onchangeDataaaa", v);
                                // }}

                                // onClick={(e, v) => getCompanyId(e, v)}
                                // onBlur={(e, v) => getCompanyId(e, v)}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Company*"
                                    error={errors2.CompanyID}
                                    helperText={errors2.CompanyID ? "Company Required." : ""}
                                  />
                                )}
                              />
                            )}
                          />
                          <span class="errorTC" id="ServicesError">
                            Please Select Company.
                          </span>
                        </div>
                      </Grid>
                    {/* )} */}
                    {/* 
                    {CompanyType == "NewCompany" && (
                      <div>
                    
                        {newCompany()}
                      </div>
                    )}
                     */}

                    <Grid item md="4">
                      <div className="reg_input">
                        {data?.length == 0 ? (
                          <PButton
                            icon="pi pi-plus"
                            className="p-button-primary AddNotes"
                            onClick={() => getValueAddNewContactButton()}
                            // style={{ marginRight: ".5em" }}
                            tooltip="Add New Contact"
                            tooltipOptions={{ position: "bottom" }}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </Grid>
                  </Grid>
                  <Grid item md="12">
                    <div className="card">
                      <div className="table-custom">
                        <DataTable
                          scrollable
                          value={data}
                          showGridlines
                          scrollHeight="600px"
                          scrollWidth="600px"
                          responsiveLayout="scroll"
                          selection={contactId}
                          onSelectionChange={(e) => setContactId(e.value)}
                          totalRecords={data ? data.length : 0}
                          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                          rowsPerPageOptions={[5, 10, 20]}
                          emptyMessage="No records found"
                          // filterDisplay="row"
                        >
                          <Column selectionMode="multiple" headerStyle={{ width: "3em" }}></Column>
                          {getColoumnsRow()}
                        </DataTable>
                      </div>
                    </div>
                  </Grid>
                </div>
              </div>

              {leadData || LedData?.LeadID ? (
                <Card sx={{ width: "100%", mt: 6 }}>
                  <MDBox display="flex">
                    <MDBox
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      width="4rem"
                      height="4rem"
                      variant="gradient"
                      bgColor="primary"
                      color="white"
                      shadow="md"
                      borderRadius="xl"
                      ml={3}
                      mt={-2}
                    >
                      <Icon fontSize="medium" color="inherit">
                        warning_amber
                      </Icon>
                    </MDBox>
                    <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
                      Notes
                    </MDTypography>
                  </MDBox>
                  <MDBox sx={{ pt: 4, pb: 2, pl: 2, pr: 2 }}>
                    <div className="notes-table">
                      <table>
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Notes</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            {/* <td className="nt-date">{moment(new Date()).format("DD-MM-YYYY")}</td> */}
                            <td className="nt-date">{moment(new Date()).tz("America/Chicago",true).format("MM-DD-YYYY")}</td>
                            <td className="nt-comment">
                              <div className="reg_input mb-0">
                                <TextField
                                  onChange={(e) => setChNotes(e.target.value)}
                                  value={chNotes ? chNotes : ""}
                                  name="notes"
                                  multiline
                                  rows={2}
                                  // maxRows={4}
                                  label="Notes Text*"
                                  id="regFirstName"
                                  fullWidth
                                />
                                <span class="errorTC" id="NotesError">
                                  Please Write Notes.
                                </span>
                              </div>
                            </td>
                            <td className="nt-action">
                              <PButton
                                type="button"
                                icon="pi pi-plus"
                                className="p-button-primary AddNotes"
                                onClick={() => saveNotes()}
                                tooltip="Add Notes"
                                tooltipOptions={{ position: "bottom" }}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <NotesComponent
                      entityType="Lead"
                      entityId={state?.LeadID ? state?.LeadID : LedData ? LedData.LeadID : ""}
                      NotesData={notesData}
                    />
                  </MDBox>
                </Card>
              ) : (
                <></>
              )}

              <div className="reg_submit">
                <MDButton onClick={() => cancel()} variant="contained" color="secondary">
                  Cancel
                </MDButton>

                {leadData || LedData?.LeadID ? (
                  <MDButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={() => getValueSaveButton()}
                  >
                    Update
                  </MDButton>
                ) : (
                  <MDButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={() => getValueSaveButton()}
                  >
                    Save
                  </MDButton>
                )}
              </div>
              <div className="copyright">
                <p>Copyright &copy; 2024 All Rights Reserved | Powered by Webment</p>
              </div>
            </form>
          </MDBox>
        </Card>
      </DashboardLayout>
    </>
    
  );
};

Add.defaultProps = {};

export default Add;
