import React, { useEffect, useMemo, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import cogoToast from "cogo-toast";
import axios from "axios";
import { DashCircle, PlusCircle } from "react-bootstrap-icons";
// import DatePicker from "react-date-picker";
import Multiselect from "multiselect-react-dropdown";
import PhoneInput from "react-phone-input-2";
import MDDatePicker from "components/MDDatePicker";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDInput from "components/MDInput";
import { CommonConfig } from "constant";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import Icon from "@mui/material/Icon";
import MDTypography from "components/MDTypography";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import {
  FormControlLabel,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
  Radio,
  Select,
  RadioGroup,
  Grid,
  Button,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import Autocomplete from "@mui/material/Autocomplete";
import Logo from "assets/img/cropped-webment.png";
import MDButton from "components/MDButton";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { item } from "examples/Sidenav/styles/sidenavItem";

import { isValidDate } from "@fullcalendar/react";
import { Alert } from "@mui/material";

const schema = yup.object().shape({
  CompanyID: yup.object().required("Company Required."),
  FirstName: yup.string().required("First Name Required."),
  LastName: yup.string().required("Last Name Required."),
  Designation: yup.object().required("Designation Required."),
  PhoneType: yup.object().required("Phone Type Required."),
  // CountryCode: yup.object().required("CountryCode Required."),
  CountryCode: yup.string().required("Country Code Required."),
  PhoneNumber: yup.string().required("Phone Number Required."),
  EmailType: yup.object().required("Email Type Required."),

  Email: yup.string().required("Email Required.").email("Incorrect email format."),
  // Skype: yup.string().required("Skype Required."),
  // Facebook: yup.string().required("Facebook Required."),
  // LinkedIn: yup.string().required("LinkedIn Required."),

  // Country: yup.object().required("Country Required."),
});

const NewContact = () => {
  const { state } = useLocation();

  let navigate = useNavigate();
  
  var PersonId = CommonConfig.loggedInUserData();
  console.log("Hello",PersonId);
  if(PersonId==undefined || PersonId==""){
    navigate('authentication/login');
  }

  let clientRef = useRef(false);
  const MAX_COUNT = 5;
  // const termsCondition = "";
  const [values, setValues] = useState([]);
  const [stateId, setStateId] = useState([]);
  const [stateLabel, setStateLabel] = useState(null);
  const [relativeStateId, setRelativeStateId] = useState([]);
  const [uploadfile, setuploadfile] = useState([]);
  const [relativeStateLabel, setRelativeStateLabel] = useState(null);
  const [serviceAutocompleteValues, setServiceAutocompleteValues] = useState(null);
  let LedData = JSON.parse(localStorage.getItem("leadData"));
  // console.log("LedData123", LedData.CompanyID);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fileLimit, setFileLimit] = useState(false);
  const [contactData, setContactData] = useState(state || null);
  console.log("contactData12", contactData);
  const [companyData, setCompanyData] = useState(null);
  const [companyId, setCompanyId] = useState(null);

  let newData = JSON.parse(localStorage.getItem("newData"));
  console.log("newDaqasds", newData);
  // const [DocumentuserData, setDocumentUserData] = useState(state[1] || null);
  console.log("KKKK", contactData);

  const [stateList, setStateList] = useState(null);

  const [requirementData, setRequirementData] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [documentdata, setdocumentdata] = useState([]);
  const [serviceList, setServiceList] = useState(null);
  const [loader, setLoader] = React.useState(false);
  const [designationData, setDesignationData] = useState(null);
  const [phoneEmailTypeData, setPhoneEmailTypeData] = useState(null);

  const onStateChange = (event, value) => {
    console.log("State::", value);
    setStateId(value.id);
    setStateLabel(value.label);
  };
  let dir = "https://services.valuecare.org.au/Backend/documents/Referals/";
  // const documentdata = userData.dOC;
  console.log("uploadedFiles", uploadedFiles);

  const IndustryData = [{ label: "Industry1" }, { label: "Industry2" }, { label: "Industry3" }];
  const CompanySizeData = [
    { label: "< 50" },
    { label: "50-100" },
    { label: "100-500" },
    { label: "500-1000" },
    { label: "> 1000" },
  ];
  const SalesTurnoverData = [{ label: "< 50 Cr" }, { label: "50-100 Cr" }, { label: "> 100 Cr" }];

  const SourceData = [{ label: "MCA" }, { label: "B.tech" }, { label: "MBA" }, { label: "M.tech" }];
  const RequirementTypeData = [
    { label: "Web App" },
    { label: "Mobile App" },
    { label: "Digital Marketing" },
    { label: "Other" },
  ];

  const SkillList = [{ label: "< NET" }, { label: "React" }, { label: "Node" }];

  const PhoneTypeData = [{ label: "Work" }, { label: "Personal" }];
  const CountryCodeData = [{ label: "+61" }, { label: "+91" }];
  const stateListStatic = [
    { label: "State1" },
    { label: "State2" },
    { label: "State3" },
    { label: "State4" },
  ];
  const CountryData = [{ label: "Australia" }, { label: "India" }];
  const StageData = [
    { label: "Lead" },
    { label: "Follow Up" },
    { label: "Won" },
    { label: "Lost" },
    { label: "Purged" },
  ];

  // const getStateData = () => {
  //   const formattedData = {
  //     StringMapType: "STATE",
  //     SortType: "Alpha",
  //   };

  //   axios({
  //     url: CommonConfig.ApiUrl + "Utils/getDropDownValues",
  //     method: "POST",
  //     headers: {},
  //     data: formattedData,
  //   })
  //     .then((res) => {
  //       if (res.data?.success) {
  //         console.log("StateList===>", res.data.data);

  //         setStateList(
  //           res.data.data.map((item) => ({
  //             id: item.StringMapKey,
  //             label: item.StringMapName,
  //           }))
  //         );
  //       }
  //     })

  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  const getStateData = () => {
    // const formattedData = {
    //   StringMapType: "STATE",
    //   SortType: "Alpha",
    // };

    axios({
      url: CommonConfig.ApiUrl + "authentication/getCompanyList",
      method: "GET",
      headers: {},
      data: "",
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("StateList===>", res.data.data[0]);

          setStateList(
            res.data.data[0].map((item) => ({
              id: item.CompanyID,
              label: item.CompanyName,
            }))
          );
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };
  console.log("stateListQQ", stateList);

  const handleFileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
  };
  const handleUploadFiles = (files) => {
    const uploaded = [...uploadedFiles];
    let limitExceeded = false;
    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        if (uploaded.length === MAX_COUNT) setFileLimit(true);
        if (uploaded.length > MAX_COUNT) {
          alert(`You can only add a maximum of ${MAX_COUNT} files`);
          setFileLimit(false);
          limitExceeded = true;
          return true;
        }
      }
    });
    if (!limitExceeded) setUploadedFiles(uploaded);
  };

  const setAutocompleteValues = () => {
    let dummyArray = [];
    let formattedData = [];
    let array = JSON.parse(state.SecurityUserData.Services);
    for (let i = 0; i < array.length; i++) {
      if (array[i]) {
        dummyArray.push({
          id: array[i],
          label: array[i],
        });
      }
    }
    for (let i = 0; i < array.length; i++) {
      formattedData.push(array[i]);
    }
    debugger;
    setValues(formattedData);
    setServiceAutocompleteValues(dummyArray);
  };

  const onServiceChange = (event, value) => {
    console.log("VALUEService", value);
    let formattedData = [];

    for (let i = 0; i < value.length; i++) {
      formattedData.push(value[i].label);
    }

    setValues(formattedData);
    setServiceAutocompleteValues(value);
    console.log("onService", values);
  };

  const getServiceData = () => {
    const formattedData = {
      StringMapType: "SERVICETYPE",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "Utils/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("ServiceList===>", res.data.data);
          setServiceList(
            res.data.data.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getDesignationData = () => {
    const formattedData = {
      StringMapType: "DESIGNITATION",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "authentication/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("ServiceList===>", res.data.data);
          setDesignationData(
            res.data.data.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getPhoneEmailTypeData = () => {
    const formattedData = {
      StringMapType: "PHONETYPE",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "authentication/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("ServiceList===>", res.data.data);
          setPhoneEmailTypeData(
            res.data.data.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getPhoneEmailTypeData();
    getDesignationData();
    getStateData();
    getServiceData();
  }, []);

  console.log("UserData = ", contactData);
  var setoffice = stateList?.filter((name) => name.id === contactData?.CompanyID);
  console.log("setoffice01", setoffice);
  console.log("Typeof", typeof setoffice);
  // console.log("Test2 ", setoffice[0].id);
  var CompanyData = {};
  // if (contactData && setoffice) {
  CompanyData = {
    id: contactData?.CompanyID,
    label: contactData?.CompanyName,
  };
  // }

  console.log("CompanyData012", CompanyData);
  const DesignationData = {
    id: contactData?.Designation,
    label: contactData?.Designation,
  };
  console.log("DesignationData01", DesignationData);
  const PhoneTypedata = {
    id: contactData?.PhoneType,
    label: contactData?.PhoneType,
  };
  const CountryCodedata = {
    id: contactData?.CountryCode,
    label: contactData?.CountryCode,
  };
  const EmailTypedata = {
    id: contactData?.EmailType,
    label: contactData?.EmailType,
  };

  const {
    register,
    handleSubmit,
    watch,
    control,
    // errors,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      CompanyID: contactData?.CompanyID ? CompanyData : "",
      FirstName: contactData?.FirstName ? contactData?.FirstName : "",
      LastName: contactData?.LastName ? contactData?.LastName : "",
      Gender: contactData?.Gender ? contactData?.Gender : "Male",
      Designation: contactData?.Designation ? DesignationData : "",
      PhoneType: contactData?.PhoneType ? PhoneTypedata : "",
      // CountryCode: contactData?.CountryCode ? CountryCodedata : "",
      CountryCode: contactData?.CountryCode ? contactData?.CountryCode : "",
      PhoneNumber: contactData?.PhoneNumber ? contactData?.PhoneNumber : "",
      EmailType: contactData?.EmailType ? EmailTypedata : "",
      Email: contactData?.Email ? contactData?.Email : "",
      Skype: contactData?.Skype ? contactData?.Skype : "",
      LinkedIn: contactData?.LinkedIn ? contactData?.LinkedIn : "",
      Facebook: contactData?.Facebook ? contactData?.Facebook : "",
    },
  });

  console.log("CheckComanyData", CompanyData);

  // const GetSource = (e, value) => {
  //   var setoffice = e.target.value;

  //   var dataSET = {
  //     source: setoffice,
  //   };
  //   setRequirementData(dataSET.source);
  //   console.log("sourceData", requirementData);
  // };

  const cancel = () => {
    navigate("/layouts/lead/Index");
    localStorage.removeItem("newData");
  };

  const onSubmit = (data) => {
    debugger;
    console.log("ContactdataQQQ", data);

    let formattedData = "";

    let sevicelist = [];
    sevicelist = values;

    console.log("skill", sevicelist);
    formattedData = {
      ...data,
      // CompanyID: LedData?.CompanyID,
      CompanyID: data.CompanyID?.id,
      CurrentUser: "",
      // CompanySize: data.CompanySize?.label,
      Country: data.Country,
      CountryCode: data.CountryCode,
      Designation: data.Designation?.label,
      EmailType: data.EmailType?.label,
      // Industry: data.Industry?.label,
      PhoneType: data.PhoneType?.label,
      // SalesTurnover: data.SalesTurnover?.label,
      State: data.State,
    };
    console.log("formattedData=", formattedData);

    let formattedDataT;

    formattedDataT = {
      ...formattedData,
    };

    if (contactData) {
      formattedDataT = {
        ...formattedData,
        // CompanyID: contactData.CompanyID,
        CompanyContactPersonID: contactData.CompanyContactPersonID,
        EmailID: contactData.EmailID,
        PhoneID: contactData.PhoneID,
        CurrentUser: CommonConfig.getCurrentUserId(),
      };
    }

    console.log("formattedDataTTT.....", formattedDataT);

    axios({
      url: CommonConfig.ApiUrl + "authentication/addUpdateContact",

      method: "POST",
      headers: {},
      data: formattedDataT,
    })
      .then((res) => {
        setLoader(false);
        debugger;
        /// setLoader(!loader);
        if (res.data.success === true) {
          if (contactData) {
            // setLoader(false);
            cogoToast.success("Contact Updated Successfully.");
            navigate("/layouts/lead/Index");
          } else if (newData?.test == "" || newData?.test == null) {
            cogoToast.success("Contact Added Successfully.");
            navigate("/dashboards/analytics");
          } else {
            cogoToast.success("Contact Added Successfully.");
            navigate("/layouts/lead/Index");
            localStorage.removeItem("newData");
          }
        } else {
          cogoToast.error("Something Went Wrong.");
        }

        console.log("testttttt", res.data);
      })
      .catch((error) => {
        console.log("AddUpdateErro", error);
      });
  };

  function getUniqueCompanyNames(stateList) {
    const uniqueCompanyNames = [];
    const map = new Map();
    stateList.forEach(company => {
      if (!map.has(company.label)) {
        map.set(company.label, true);
        uniqueCompanyNames.push(company);
      }
    });
   console.log("unique company names",uniqueCompanyNames)
    return uniqueCompanyNames;
  }

  return (
    <>
      <div>
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loader}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <DashboardLayout>
        <DashboardNavbar />
        <Card sx={{ width: "100%", mt: 3 }}>
          <MDBox display="flex">
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="4rem"
              height="4rem"
              variant="gradient"
              bgColor="primary"
              color="white"
              shadow="md"
              borderRadius="xl"
              ml={3}
              mt={-2}
            >
              <Icon fontSize="medium" color="inherit">
                add
              </Icon>
            </MDBox>
            <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
              {contactData ? "Edit Contact" : "Add New Contact"}
              {/* Referral Form. */}
            </MDTypography>
          </MDBox>
          <MDBox sx={{ pt: 4, pb: 2, pl: 2, pr: 2 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="reg_step" id="regStep1">
                <div className="reg_form">
                  {/* <Grid container spacing={3}>
                    {contactData ? (
                      <Grid item md="6">
                        <div className="reg_input auto-complete">
                          <Controller
                            name="CompanyID"
                            control={control}
                            defaultValue={contactData?.CompanyID ? CompanyData?.label : ""}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                disable="true"
                                label="Company*"
                                variant="outlined"
                                value={CompanyData?.label}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                    ) : (
                      <Grid item md="6">
                        <div className="reg_input auto-complete">
                          <Controller
                            control={control}
                            name="CompanyID"
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                              <Autocomplete
                                disablePortal
                                id="CompanyID"
                                name="CompanyID"
                                value={CompanyData.label}
                                options={stateList ? stateList : ""}
                                onChange={(e, v) => onChange(v)}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Company*"
                                    error={errors.CompanyID}
                                    helperText={errors.CompanyID ? "Company Required." : ""}
                                  />
                                )}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                    )}
                  </Grid> */}
                  <Grid container spacing={3}>
                    <Grid item md="6">
                      <div className="reg_input auto-complete">
                        <Controller
                          control={control}
                          name="CompanyID"
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              disablePortal
                              id="CompanyID"
                              name="CompanyID"
                              value={value}
                              options={stateList ? getUniqueCompanyNames(stateList) : ""}
                              onChange={(e, v) => onChange(v)}
                              renderInput={(params) => (
                                <TextField
                                  readonly
                                  {...params}
                                  label="Company*"
                                  error={errors.CompanyID}
                                  helperText={errors.CompanyID ? "Company Required." : ""}
                                />
                              )}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item md="6">
                      <div className="reg_input">
                        <Controller
                          name="FirstName"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="First Name*"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid>

                    <Grid item md="6">
                      <div className="reg_input">
                        <Controller
                          name="LastName"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="Last Name*"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item md="6">
                      <div className="reg_input">
                        <InputLabel>Gender</InputLabel>
                        <Controller
                          name="Gender"
                          control={control}
                          defaultValue={contactData ? contactData?.Gender : "Male"}
                          rules={{ required: true }}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <>
                              {" "}
                              <RadioGroup
                                aria-label="appointment"
                                row="true"
                                defaultValue={contactData ? contactData?.Gender : "Male"}
                              >
                                <FormControlLabel
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  value="Male"
                                  control={<Radio />}
                                  label="Male"
                                />
                                <FormControlLabel
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  value="Female"
                                  control={<Radio />}
                                  label="Female"
                                />
                                <FormControlLabel
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  value="Unknown"
                                  control={<Radio />}
                                  label="Unknown"
                                />
                              </RadioGroup>
                            </>
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item md="6">
                      <div className="reg_input auto-complete">
                        <Controller
                          control={control}
                          name="Designation"
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              disablePortal
                              id="Designation"
                              name="Designation"
                              value={value}
                              options={designationData ? designationData : ""}
                              onChange={(e, v) => onChange(v)}
                              //   onBlur={GetSource}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Designation*"
                                  error={errors.Designation}
                                  helperText={errors.Designation ? "Designation Required." : ""}
                                />
                              )}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <div className="reg_box_bordered">
                    <Grid container spacing={3}>
                      <Grid item md="3">
                        <div className="reg_input auto-complete">
                          <Controller
                            control={control}
                            name="PhoneType"
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                              <Autocomplete
                                disablePortal
                                id="PhoneType"
                                name="PhoneType"
                                value={value}
                                options={phoneEmailTypeData ? phoneEmailTypeData : ""}
                                onChange={(e, v) => onChange(v)}
                                //   onBlur={GetSource}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Phone Type*"
                                    error={errors.PhoneType}
                                    helperText={errors.PhoneType ? "Phone Type Required." : ""}
                                  />
                                )}
                              />
                            )}
                          />
                        </div>
                      </Grid>

                      <Grid item md="3">
                        <div className="reg_input">
                          <Controller
                            name="CountryCode"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Country Code*"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />

                          {/* <Controller
                            control={control}
                            name="CountryCode"
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                              <Autocomplete
                                disablePortal
                                id="CountryCode"
                                name="CountryCode"
                                value={value}
                                options={CountryCodeData ? CountryCodeData : ""}
                                onChange={(e, v) => onChange(v)}
                                //   onBlur={GetSource}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Country Code*"
                                    error={errors.CountryCode}
                                    helperText={errors.CountryCode ? "Country Code Required." : ""}
                                  />
                                )}
                              />
                            )}
                          /> */}
                        </div>
                      </Grid>
                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="PhoneNumber"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                type="number"
                                label="Phone Number*"
                                variant="outlined"
                                maxLength="10"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                                inputProps={{
                                  maxLength: 10,
                                }}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <div className="reg_box_bordered">
                    <Grid container spacing={3}>
                      <Grid item md="4">
                        <div className="reg_input auto-complete">
                          <Controller
                            control={control}
                            name="EmailType"
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                              <Autocomplete
                                disablePortal
                                id="EmailType"
                                name="EmailType"
                                value={value}
                                options={phoneEmailTypeData ? phoneEmailTypeData : ""}
                                onChange={(e, v) => onChange(v)}
                                //   onBlur={GetSource}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Email Type*"
                                    error={errors.EmailType}
                                    helperText={errors.EmailType ? "Email Type Required." : ""}
                                  />
                                )}
                              />
                            )}
                          />
                        </div>
                      </Grid>

                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="Email"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Email*"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </div>

                  <Grid container spacing={3}>
                    <Grid item md="4">
                      <div className="reg_input">
                        <Controller
                          name="Skype"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="Skype"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item md="4">
                      <div className="reg_input">
                        <Controller
                          name="LinkedIn"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="LinkedIn"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item md="4">
                      <div className="reg_input">
                        <Controller
                          name="Facebook"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="Facebook"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>

              <div className="reg_submit">
                {newData?.test == "" || newData?.test == null ? (
                  <MDButton
                    onClick={() => navigate("/dashboards/analytics")}
                    variant="contained"
                    color="secondary"
                  >
                    Cancel
                  </MDButton>
                ) : (
                  <MDButton onClick={() => cancel()} variant="contained" color="secondary">
                    Cancel
                  </MDButton>
                )}

                {/* <MDButton
                  onClick={() => navigate("/layouts/lead/Index")}
                  variant="contained"
                  color="secondary"
                >
                  Cancel
                </MDButton> */}
                <MDButton type="submit" variant="contained" color="primary">
                  Save
                </MDButton>
              </div>

              <div className="copyright">
                <p>Copyright &copy; 2024 All Rights Reserved | Powered by Webment</p>
              </div>
            </form>
          </MDBox>
        </Card>
      </DashboardLayout>
    </>
  );
};

NewContact.defaultProps = {};

export default NewContact;

// import React, { useEffect, useMemo, useState, useRef } from "react";
// import { useLocation, useNavigate } from "react-router-dom";
// import { useForm, Controller } from "react-hook-form";
// import cogoToast from "cogo-toast";
// import axios from "axios";
// import { DashCircle, PlusCircle } from "react-bootstrap-icons";
// // import DatePicker from "react-date-picker";
// import Multiselect from "multiselect-react-dropdown";
// import PhoneInput from "react-phone-input-2";
// import MDDatePicker from "components/MDDatePicker";
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import MDInput from "components/MDInput";
// import { CommonConfig } from "constant";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import Card from "@mui/material/Card";
// import MDBox from "components/MDBox";
// import Icon from "@mui/material/Icon";
// import MDTypography from "components/MDTypography";
// import Backdrop from "@mui/material/Backdrop";
// import CircularProgress from "@mui/material/CircularProgress";

// import {
//   FormControlLabel,
//   MenuItem,
//   TextField,
//   FormControl,
//   InputLabel,
//   Radio,
//   Select,
//   RadioGroup,
//   Grid,
//   Button,
// } from "@mui/material";
// import Checkbox from "@mui/material/Checkbox";
// import FormGroup from "@mui/material/FormGroup";
// import Autocomplete from "@mui/material/Autocomplete";
// import Logo from "assets/img/cropped-webment.png";
// import MDButton from "components/MDButton";
// import CoverLayout from "layouts/authentication/components/CoverLayout";
// import * as yup from "yup";
// import { yupResolver } from "@hookform/resolvers/yup";
// import {
//   MuiPickersUtilsProvider,
//   KeyboardTimePicker,
//   KeyboardDatePicker,
// } from "@material-ui/pickers";
// import DateFnsUtils from "@date-io/date-fns";
// import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
// import moment from "moment";
// import { item } from "examples/Sidenav/styles/sidenavItem";

// import { isValidDate } from "@fullcalendar/react";
// import { Alert } from "@mui/material";
// import { event } from "jquery";
// import { variants } from "@hig/icon-button";

// const schema = yup.object().shape({
//   // Country: yup.object().required("Country Required."),
// });

// const NewContact = () => {
//   const { state } = useLocation();

//   let navigate = useNavigate();
//   let clientRef = useRef(false);
//   const MAX_COUNT = 5;
//   // const termsCondition = "";
//   const [values, setValues] = useState([]);
//   const [stateId, setStateId] = useState([]);
//   const [stateLabel, setStateLabel] = useState(state?.SecurityUserData?.State || null);
//   const [relativeStateId, setRelativeStateId] = useState([]);
//   const [uploadfile, setuploadfile] = useState([]);
//   const [relativeStateLabel, setRelativeStateLabel] = useState(
//     state?.SecurityUserData?.RelativeState || null
//   );
//   const [serviceAutocompleteValues, setServiceAutocompleteValues] = useState(null);

//   const [uploadedFiles, setUploadedFiles] = useState([]);
//   const [fileLimit, setFileLimit] = useState(false);
//   const [userData, setUserData] = useState(state || null);
//   // let phonedata1 = { 0: "9801096001", 1: "7906436850", 2: "9631153158" };
//   let phonedata1 = [];
//   const [phonedata, setphonedata] = useState(phonedata1 ? phonedata1 : []);
//   // console.log("phonedata", phonedata);
//   // let codedata = ["+91", "+61", "+91"];
//   let codedata = [];
//   // var Countrycodedata = [];
//   // for (var i = 0; i < codedata.length; i++) {
//   //   if (codedata[i]) {
//   //     Countrycodedata.push({
//   //       label: codedata[i],
//   //     });
//   //   }

//   //   // var data = {
//   //   //   label: codedata[i],
//   //   // };
//   //   // Countrycodedata = data;
//   // }
//   // console.log("Countrycodedata", Countrycodedata);
//   const [counter, setCounter] = useState(1);
//   const [CountryValues, setCountryValues] = useState(codedata ? codedata : []);
//   const [phoneTypeValues, setPhoneTypeValues] = useState([]);
//   // const [DocumentuserData, setDocumentUserData] = useState(state[1] || null);
//   console.log("KKKK", userData);

//   const [emailCounter, setEmailCounter] = useState(1);
//   const [emailTypeValues, setEmaiTypelValues] = useState([]);
//   const [emailData, setEmailData] = useState([]);

//   const [addressCounter, setAddressCounter] = useState(1);
//   const [addressTypeData, setAddressTypeData] = useState([]);
//   const [addressLine1Data, setAddressLine1Data] = useState([]);
//   const [addressLine2Data, setAddressLine2Data] = useState([]);
//   const [countryData, setCountryData] = useState([]);
//   const [cityData, setCityData] = useState([]);
//   const [stateData, setStateData] = useState([]);
//   const [postalCodeData, setPostalCodeData] = useState([]);

//   const [stateList, setStateList] = useState(null);

//   const [requirementData, setRequirementData] = useState(null);
//   const [selectedState, setSelectedState] = useState(null);
//   const [documentdata, setdocumentdata] = useState([]);
//   const [serviceList, setServiceList] = useState(null);
//   const [loader, setLoader] = React.useState(false);

//   const onStateChange = (event, value) => {
//     console.log("State::", value);
//     setStateId(value.id);
//     setStateLabel(value.label);
//   };
//   let dir = "https://services.valuecare.org.au/Backend/documents/Referals/";
//   // const documentdata = userData.dOC;
//   console.log("uploadedFiles", uploadedFiles);

//   const IndustryData = [{ label: "Industry1" }, { label: "Industry2" }, { label: "Industry3" }];
//   const CompanySizeData = [
//     { label: "< 50" },
//     { label: "50-100" },
//     { label: "100-500" },
//     { label: "500-1000" },
//     { label: "> 1000" },
//   ];
//   const SalesTurnoverData = [{ label: "< 50 Cr" }, { label: "50-100 Cr" }, { label: "> 100 Cr" }];

//   const SourceData = [{ label: "MCA" }, { label: "B.tech" }, { label: "MBA" }, { label: "M.tech" }];
//   const RequirementTypeData = [
//     { label: "Web App" },
//     { label: "Mobile App" },
//     { label: "Digital Marketing" },
//     { label: "Other" },
//   ];

//   const SkillList = [{ label: "< NET" }, { label: "React" }, { label: "Node" }];

//   const PhoneTypeData = [{ label: "Work" }, { label: "Personal" }];
//   const CountryCodeData = [{ label: "+61" }, { label: "+91" }];
//   const CompanyList = [
//     { label: "Company1" },
//     { label: "Company2" },
//     { label: "Company3" },
//     { label: "Company4" },
//   ];
//   const CountryData = [{ label: "Australia" }, { label: "India" }];
//   const StageData = [
//     { label: "Lead" },
//     { label: "Follow Up" },
//     { label: "Won" },
//     { label: "Lost" },
//     { label: "Purged" },
//   ];

//   const getStateData = () => {
//     const formattedData = {
//       StringMapType: "STATE",
//       SortType: "Alpha",
//     };

//     axios({
//       url: CommonConfig.ApiUrl + "Utils/getDropDownValues",
//       method: "POST",
//       headers: {},
//       data: formattedData,
//     })
//       .then((res) => {
//         if (res.data?.success) {
//           console.log("StateList===>", res.data.data);

//           setStateList(
//             res.data.data.map((item) => ({
//               id: item.StringMapKey,
//               label: item.StringMapName,
//             }))
//           );
//         }
//       })

//       .catch((error) => {
//         console.log(error);
//       });
//   };

//   const handleFileEvent = (e) => {
//     const chosenFiles = Array.prototype.slice.call(e.target.files);
//     handleUploadFiles(chosenFiles);
//   };
//   const handleUploadFiles = (files) => {
//     const uploaded = [...uploadedFiles];
//     let limitExceeded = false;
//     files.some((file) => {
//       if (uploaded.findIndex((f) => f.name === file.name) === -1) {
//         uploaded.push(file);
//         if (uploaded.length === MAX_COUNT) setFileLimit(true);
//         if (uploaded.length > MAX_COUNT) {
//           alert(`You can only add a maximum of ${MAX_COUNT} files`);
//           setFileLimit(false);
//           limitExceeded = true;
//           return true;
//         }
//       }
//     });
//     if (!limitExceeded) setUploadedFiles(uploaded);
//   };

//   const setAutocompleteValues = () => {
//     let dummyArray = [];
//     let formattedData = [];
//     let array = JSON.parse(state.SecurityUserData.Services);
//     for (let i = 0; i < array.length; i++) {
//       if (array[i]) {
//         dummyArray.push({
//           id: array[i],
//           label: array[i],
//         });
//       }
//     }
//     for (let i = 0; i < array.length; i++) {
//       formattedData.push(array[i]);
//     }
//     debugger;
//     setValues(formattedData);
//     setServiceAutocompleteValues(dummyArray);
//   };

//   const onServiceChange = (event, value) => {
//     console.log("VALUEService", value);
//     let formattedData = [];

//     for (let i = 0; i < value.length; i++) {
//       formattedData.push(value[i].label);
//     }

//     setValues(formattedData);
//     setServiceAutocompleteValues(value);
//     console.log("onService", values);
//   };

//   const getServiceData = () => {
//     const formattedData = {
//       StringMapType: "SERVICETYPE",
//       SortType: "Alpha",
//     };

//     axios({
//       url: CommonConfig.ApiUrl + "Utils/getDropDownValues",
//       method: "POST",
//       headers: {},
//       data: formattedData,
//     })
//       .then((res) => {
//         if (res.data?.success) {
//           console.log("ServiceList===>", res.data.data);
//           setServiceList(
//             res.data.data.map((item) => ({
//               id: item.StringMapKey,
//               label: item.StringMapName,
//             }))
//           );
//         }
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   };
//   useEffect(() => {
//     getStateData();
//     getServiceData();
//   }, []);

//   console.log("UserData = ", userData);
//   const statedata = {
//     id: userData?.RepresentativeState,
//     label: userData?.RepresentativeState,
//   };

//   const {
//     register,
//     handleSubmit,
//     watch,
//     control,
//     // errors,
//     formState: { errors },
//     getValues,
//   } = useForm({
//     resolver: yupResolver(schema),
//     defaultValues: {
//       //   LeadTitle: LedData?.LeadTitle ? LedData?.LeadTitle : "",
//       //   CompanyType: userData?.CompanyType ? userData?.CompanyType : "Existing Company",
//     },
//   });

//   const GetSource = (e, value) => {
//     var setoffice = e.target.value;

//     var dataSET = {
//       source: setoffice,
//     };
//     setRequirementData(dataSET.source);
//     console.log("sourceData", requirementData);
//   };

//   const onAddBtnClick = (event) => {
//     if (counter != 3) {
//       setCounter(counter + 1);
//     }
//   };
//   console.log("counterQ", counter);

//   const handleOnChange = (e, type, value) => {
//     console.log("typeQQ", type);
//     if (type === "phoneNo") {
//       const abc = phonedata;
//       let current_index = e.target.id;
//       current_index = current_index.substr(0, current_index.indexOf("_"));
//       abc[current_index] = e.target.value;
//       setphonedata({ ...abc });
//       console.log("testingQ", e.target.value);
//     } else if (type === "countryCode") {
//       const abc = CountryValues;
//       let current_index = e.target.id;
//       current_index = current_index.substr(0, current_index.indexOf("_"));
//       abc[current_index] = value.label;
//       setCountryValues({ ...abc });
//       console.log("testingQ", value.label);
//     } else if (type === "phoneType") {
//       const abc = phoneTypeValues;
//       let current_index = e.target.id;
//       current_index = current_index.substr(0, current_index.indexOf("_"));
//       abc[current_index] = value.label;
//       setPhoneTypeValues({ ...abc });
//       console.log("testingQ", value.label);
//     }
//   };
//   console.log("CountryValuesQ", phoneTypeValues);

//   const onAddEmailBtn = (event) => {
//     setEmailCounter(emailCounter + 1);
//   };

//   const emailhandleOnChange = (e, type, value) => {
//     console.log("typeQQ", type);
//     if (type === "emailtype") {
//       const abc = emailTypeValues;
//       let current_index = e.target.id;
//       current_index = current_index.substr(0, current_index.indexOf("_"));
//       abc[current_index] = value.label;
//       setEmaiTypelValues({ ...abc });
//       console.log("testingQ", emailTypeValues);
//     }
//     if (type === "emailid") {
//       const abc = emailData;
//       let current_index = e.target.id;
//       current_index = current_index.substr(0, current_index.indexOf("_"));
//       abc[current_index] = e.target.value;
//       setEmailData({ ...abc });
//       console.log("testingQ", emailData);
//       console.log("current_index", current_index);
//     }
//   };

//   // function addEmail() {
//   //   var emailList[current_index] = { emailData };
//   //   alert("Hello " + emailList + "! You will now be redirected to www.w3Schools.com");
//   //   console.log("emailList", emailList);
//   // }

//   const onAddAddressBtn = (event) => {
//     setAddressCounter(addressCounter + 1);
//   };
//   // const onRemoveAddressBtn = (event) => {
//   //   if (addressCounter != 1) {
//   //     setAddressCounter(addressCounter - 1);
//   //   }
//   // };

//   const addressHandleOnChange = (e, type, value) => {
//     console.log("typeQQ", type);
//     if (type === "addresstype") {
//       const abc = addressTypeData;
//       let current_index = e.target.id;
//       current_index = current_index.substr(0, current_index.indexOf("_"));
//       abc[current_index] = e.target.value;
//       setAddressTypeData({ ...abc });
//       console.log("addressTypeData", addressTypeData);
//     } else if (type === "addressline1") {
//       const abc = addressLine1Data;
//       let current_index = e.target.id;
//       current_index = current_index.substr(0, current_index.indexOf("_"));
//       abc[current_index] = e.target.value;
//       setAddressLine1Data({ ...abc });
//       console.log("addressLine1Data", addressLine1Data);
//     } else if (type === "addressline2") {
//       const abc = addressLine2Data;
//       let current_index = e.target.id;
//       current_index = current_index.substr(0, current_index.indexOf("_"));
//       abc[current_index] = e.target.value;
//       setAddressLine2Data({ ...abc });
//       console.log("addressLine2Data", addressLine2Data);
//     } else if (type === "country") {
//       const abc = countryData;
//       let current_index = e.target.id;
//       current_index = current_index.substr(0, current_index.indexOf("_"));
//       abc[current_index] = value.label;
//       setCountryData({ ...abc });
//       console.log("countryDataQ", countryData);
//     } else if (type === "city") {
//       const abc = cityData;
//       let current_index = e.target.id;
//       current_index = current_index.substr(0, current_index.indexOf("_"));
//       abc[current_index] = e.target.value;
//       setCityData({ ...abc });
//       console.log("cityData", cityData);
//     } else if (type === "state") {
//       const abc = stateData;
//       let current_index = e.target.id;
//       current_index = current_index.substr(0, current_index.indexOf("_"));
//       abc[current_index] = value.label;
//       setStateData({ ...abc });
//       console.log("stateDataQ", stateData);
//     } else if (type === "postalcode") {
//       const abc = postalCodeData;
//       let current_index = e.target.id;
//       current_index = current_index.substr(0, current_index.indexOf("_"));
//       abc[current_index] = e.target.value;
//       setPostalCodeData({ ...abc });
//       console.log("postalCodeData", postalCodeData);
//     }
//   };

//   // const phonetypehandleOnChange = (e, value) => {
//   //   const abc = {};
//   //   let current_index = e.target.id;
//   //   current_index = current_index.substr(0, current_index.indexOf("_"));
//   //   abc[current_index] = value.label;
//   //   setInputPhoneTypeValues({ ...inputPhoneTypeValues, ...abc });
//   // };

//   var myJSONString = { 0: "1", 1: "2", 2: "3" };
//   // myObject = JSON.parse(myJSONString);

//   var myArray = [];
//   for (var i in myJSONString) {
//     myArray.push(myJSONString[i]);
//   }
//   console.log("myArray", myArray.toString());

//   const onSubmit = (data) => {
//     console.log("Contactdata", data);

//     let formattedData = "";

//     let sevicelist = [];
//     sevicelist = values;

//     console.log("data.Country", data.Country);
//     formattedData = {
//       ...data,
//       SkillSet: sevicelist,
//       CompanySize: data.CompanySize?.label,
//       Country: data.Country?.label,
//       PhoneType: phoneTypeValues,
//       CountryCode: CountryValues,
//       PhoneNumber: phonedata,
//       EmailType: emailTypeValues,
//       Email: emailData,
//       AddressType: addressTypeData,
//       AddressLine1: addressLine1Data,
//       AddressLine2: addressLine2Data,
//       Country: countryData,
//       City: cityData,
//       State: stateData,
//       PostalCode: postalCodeData,
//       Designation: data.Designation?.label,
//       Industry: data.Industry?.label,
//       RequirementType: data.RequirementType?.label,
//       SalesTurnover: data.SalesTurnover?.label,
//       Stage: data.Stage?.label,
//     };
//     console.log("formattedData=", formattedData);
//     if (formattedData.Country) {
//       navigate("/layouts/lead/Index");
//     }

//     // let formattedDataT;

//     // formattedDataT = {
//     //   formattedData,
//     // };

//     // if (userData) {
//     //   formattedDataT = {
//     //     formattedData,
//     //     CurrentUser: CommonConfig.getCurrentUserId(),
//     //   };
//     // }

//     // console.log("formattedDataTTT.....", formattedDataT);

//     // axios({
//     //   url: CommonConfig.ApiUrl + "Referral/addUpdateReferral",

//     //   method: "POST",
//     //   headers: {},
//     //   data: formattedDataT,
//     // })
//     //   .then((res) => {
//     //     setLoader(false);
//     //     debugger;
//     //     /// setLoader(!loader);
//     //     if (res.data.success === true) {
//     //       if (formattedData.ReferralId) {
//     //         // setLoader(false);
//     //         cogoToast.success("Referral Updated Successfully.");
//     //         navigate("/layouts/referal/Referallist");
//     //       } else {
//     //         cogoToast.success("Referral Added Successfully.");
//     //         navigate("/layouts/referal/Referallist");
//     //       }
//     //     } else {
//     //       cogoToast.error("Something Went Wrong.");
//     //     }

//     //     console.log("testttttt", res.data);
//     //   })
//     //   .catch((error) => {
//     //     console.log("AddUpdateErro", error);
//     //   });
//   };

//   return (
//     <>
//       <div>
//         <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loader}>
//           <CircularProgress color="inherit" />
//         </Backdrop>
//       </div>
//       <DashboardLayout>
//         <DashboardNavbar />
//         <Card sx={{ width: "100%", mt: 3 }}>
//           <MDBox display="flex">
//             <MDBox
//               display="flex"
//               justifyContent="center"
//               alignItems="center"
//               width="4rem"
//               height="4rem"
//               variant="gradient"
//               bgColor="primary"
//               color="white"
//               shadow="md"
//               borderRadius="xl"
//               ml={3}
//               mt={-2}
//             >
//               <Icon fontSize="medium" color="inherit">
//                 add
//               </Icon>
//             </MDBox>
//             <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
//               {userData ? "Edit Contact" : "Add New Contact."}
//               {/* Referral Form. */}
//             </MDTypography>
//           </MDBox>
//           <MDBox sx={{ pt: 4, pb: 2, pl: 2, pr: 2 }}>
//             <form onSubmit={handleSubmit(onSubmit)}>
//               <div className="reg_step" id="regStep1">
//                 <div className="reg_form">
//                   <Grid container spacing={3}></Grid>
//                   <Grid container spacing={3}>
//                     <Grid item md="6">
//                       <div className="reg_input">
//                         <Controller
//                           name="FirstName"
//                           control={control}
//                           defaultValue=""
//                           render={({ field: { onChange, value }, fieldState: { error } }) => (
//                             <TextField
//                               label="First Name"
//                               variant="outlined"
//                               value={value}
//                               onChange={onChange}
//                               error={error}
//                               helperText={error?.message}
//                             />
//                           )}
//                         />
//                       </div>
//                     </Grid>

//                     <Grid item md="6">
//                       <div className="reg_input">
//                         <Controller
//                           name="LastName"
//                           control={control}
//                           defaultValue=""
//                           render={({ field: { onChange, value }, fieldState: { error } }) => (
//                             <TextField
//                               label="Last Name"
//                               variant="outlined"
//                               value={value}
//                               onChange={onChange}
//                               error={error}
//                               helperText={error?.message}
//                             />
//                           )}
//                         />
//                       </div>
//                     </Grid>
//                   </Grid>

//                   <Grid container spacing={3}>
//                     <Grid item md="6">
//                       <div className="reg_input">
//                         <InputLabel>Gender</InputLabel>
//                         <Controller
//                           name="Gender"
//                           control={control}
//                           defaultValue=""
//                           rules={{ required: true }}
//                           render={({ field: { onChange, value }, fieldState: { error } }) => (
//                             <>
//                               {" "}
//                               <RadioGroup
//                                 aria-label="appointment"
//                                 row="true"
//                                 defaultValue={userData ? userData?.Gender : "Male"}
//                               >
//                                 <FormControlLabel
//                                   onChange={onChange}
//                                   error={!!error}
//                                   helperText={error ? error.message : null}
//                                   value="Male"
//                                   control={<Radio />}
//                                   label="Male"
//                                 />
//                                 <FormControlLabel
//                                   onChange={onChange}
//                                   error={!!error}
//                                   helperText={error ? error.message : null}
//                                   value="Female"
//                                   control={<Radio />}
//                                   label="Female"
//                                 />
//                                 <FormControlLabel
//                                   onChange={onChange}
//                                   error={!!error}
//                                   helperText={error ? error.message : null}
//                                   value="Unknown"
//                                   control={<Radio />}
//                                   label="Unknown"
//                                 />
//                               </RadioGroup>
//                             </>
//                           )}
//                         />
//                       </div>
//                     </Grid>
//                   </Grid>

//                   <Grid container spacing={3}>
//                     <Grid item md="6">
//                       <div className="reg_input auto-complete">
//                         <Controller
//                           control={control}
//                           name="Designation"
//                           rules={{ required: true }}
//                           render={({ field: { onChange, value } }) => (
//                             <Autocomplete
//                               disablePortal
//                               id="Designation"
//                               name="Designation"
//                               value={value}
//                               options={SourceData ? SourceData : ""}
//                               onChange={(e, v) => onChange(v)}
//                               //   onBlur={GetSource}
//                               renderInput={(params) => (
//                                 <TextField
//                                   {...params}
//                                   label="Designation"
//                                   error={errors.Designation}
//                                   helperText={errors.Designation ? "Designation Required." : ""}
//                                 />
//                               )}
//                             />
//                           )}
//                         />
//                       </div>
//                     </Grid>
//                   </Grid>
//                   <div className="reg_box_bordered">
//                     {Array.from(Array(counter)).map((c, index) => {
//                       return (
//                         <Grid container spacing={3}>
//                           <Grid item md="3">
//                             <div className="reg_input auto-complete">
//                               <Autocomplete
//                                 disablePortal
//                                 id={index + "_PhoneType"}
//                                 name="PhoneType"
//                                 value={phoneTypeValues ? phoneTypeValues[index] : ""}
//                                 options={PhoneTypeData ? PhoneTypeData : ""}
//                                 onChange={(e, value) => handleOnChange(e, "phoneType", value)}
//                                 //   onBlur={GetSource}
//                                 renderInput={(params) => (
//                                   <TextField
//                                     {...params}
//                                     label="Phone Type"
//                                     error={errors.PhoneType}
//                                     helperText={errors.PhoneType ? "PhoneType Required." : ""}
//                                   />
//                                 )}
//                               />
//                             </div>
//                           </Grid>

//                           <Grid item md="3">
//                             <div className="reg_input auto-complete">
//                               <Autocomplete
//                                 disablePortal
//                                 id={index + "_CountryCode"}
//                                 name="CountryCode"
//                                 options={CountryCodeData ? CountryCodeData : ""}
//                                 onChange={(e, value) => handleOnChange(e, "countryCode", value)}
//                                 value={CountryValues ? CountryValues[index] : ""}
//                                 //   onBlur={GetSource}
//                                 renderInput={(params) => (
//                                   <TextField
//                                     {...params}
//                                     label="Country Code"
//                                     error={errors.CountryCode}
//                                     helperText={errors.CountryCode ? "CountryCode Required." : ""}
//                                   />
//                                 )}
//                               />
//                             </div>
//                           </Grid>
//                           <Grid item md="4">
//                             <h6>Phone Number</h6>
//                             <div className="reg_input">
//                               <input
//                                 value={phonedata ? phonedata[index] : ""}
//                                 name="PhoneNumber"
//                                 id={index + "_PhoneNumber"}
//                                 type="number"
//                                 label="Phone Number"
//                                 variant="outlined"
//                                 maxLength="10"
//                                 className={index}
//                                 onChange={(e) => handleOnChange(e, "phoneNo")}
//                                 inputProps={{
//                                   maxLength: 10,
//                                 }}
//                               />
//                             </div>
//                           </Grid>
//                         </Grid>
//                       );
//                     })}

//                     <Grid item md="2">
//                       <div className="reg_input">
//                         <MDButton onClick={onAddBtnClick} variant="contained" color="secondary">
//                           Add More
//                         </MDButton>
//                       </div>
//                     </Grid>
//                   </div>

//                   {/* <form onSubmit={handleSubmit(addEmail)}> */}
//                   <div className="reg_box_bordered">
//                     {Array.from(Array(emailCounter)).map((c, index) => {
//                       return (
//                         <Grid container spacing={3}>
//                           <Grid item md="4">
//                             <div className="reg_input auto-complete">
//                               <Autocomplete
//                                 disablePortal
//                                 id={index + "_EmailType"}
//                                 name="EmailType"
//                                 value={emailTypeValues ? emailTypeValues[index] : ""}
//                                 options={PhoneTypeData ? PhoneTypeData : ""}
//                                 onChange={(e, value) => emailhandleOnChange(e, "emailtype", value)}
//                                 //   onBlur={GetSource}
//                                 renderInput={(params) => (
//                                   <TextField
//                                     {...params}
//                                     label="Email Type"
//                                     error={errors.EmailType}
//                                     helperText={errors.EmailType ? "EmailType Required." : ""}
//                                   />
//                                 )}
//                               />
//                             </div>
//                           </Grid>

//                           <Grid item md="4">
//                             <h6>Email</h6>
//                             <div className="reg_input">
//                               <input
//                                 value={emailData ? emailData[index] : ""}
//                                 name="Email"
//                                 id={index + "_Email"}
//                                 label="Email"
//                                 variant="outlined"
//                                 className={index}
//                                 onChange={(e) => emailhandleOnChange(e, "emailid")}
//                               />
//                             </div>
//                           </Grid>
//                           {/* <input type="Submit" /> */}
//                         </Grid>
//                       );
//                     })}

//                     <Grid item md="2">
//                       <div className="reg_input">
//                         <MDButton onClick={onAddEmailBtn} variant="contained" color="secondary">
//                           Add More
//                         </MDButton>
//                       </div>
//                     </Grid>
//                   </div>
//                   {/* </form> */}

//                   <Grid container spacing={3}>
//                     <Grid item md="4">
//                       <div className="reg_input">
//                         <Controller
//                           name="Skype"
//                           control={control}
//                           defaultValue=""
//                           render={({ field: { onChange, value }, fieldState: { error } }) => (
//                             <TextField
//                               label="Skype"
//                               variant="outlined"
//                               value={value}
//                               onChange={onChange}
//                               error={error}
//                               helperText={error?.message}
//                             />
//                           )}
//                         />
//                       </div>
//                     </Grid>
//                     <Grid item md="4">
//                       <div className="reg_input">
//                         <Controller
//                           name="LinkedIn"
//                           control={control}
//                           defaultValue=""
//                           render={({ field: { onChange, value }, fieldState: { error } }) => (
//                             <TextField
//                               label="LinkedIn"
//                               variant="outlined"
//                               value={value}
//                               onChange={onChange}
//                               error={error}
//                               helperText={error?.message}
//                             />
//                           )}
//                         />
//                       </div>
//                     </Grid>
//                     <Grid item md="4">
//                       <div className="reg_input">
//                         <Controller
//                           name="Facebook"
//                           control={control}
//                           defaultValue=""
//                           render={({ field: { onChange, value }, fieldState: { error } }) => (
//                             <TextField
//                               label="Facebook"
//                               variant="outlined"
//                               value={value}
//                               onChange={onChange}
//                               error={error}
//                               helperText={error?.message}
//                             />
//                           )}
//                         />
//                       </div>
//                     </Grid>
//                   </Grid>
//                   {Array.from(Array(addressCounter)).map((c, index) => {
//                     return (
//                       <div className="reg_box_bordered">
//                         <Grid container spacing={3}>
//                           <Grid item md="4">
//                             <h6>Address Type</h6>
//                             <div className="reg_input">
//                               <input
//                                 id={index + "_AddressType"}
//                                 name="AddressType"
//                                 variant="outlined"
//                                 value={addressTypeData ? addressTypeData[index] : ""}
//                                 className={index}
//                                 onChange={(e) => addressHandleOnChange(e, "addresstype")}
//                               />
//                             </div>
//                           </Grid>
//                           <Grid item md="4">
//                             <h6>Line1</h6>
//                             <div className="reg_input">
//                               <input
//                                 id={index + "_AddressLine1"}
//                                 name="AddressLine1"
//                                 variant="outlined"
//                                 value={addressLine1Data ? addressLine1Data[index] : ""}
//                                 className={index}
//                                 onChange={(e) => addressHandleOnChange(e, "addressline1")}
//                               />
//                             </div>
//                           </Grid>
//                           <Grid item md="4">
//                             <div className="reg_input">
//                               <h6>Line2</h6>
//                               <div className="reg_input">
//                                 <input
//                                   id={index + "_AddressLine2"}
//                                   name="AddressLine2"
//                                   variant="outlined"
//                                   value={addressLine2Data ? addressLine2Data[index] : ""}
//                                   className={index}
//                                   onChange={(e) => addressHandleOnChange(e, "addressline2")}
//                                 />
//                               </div>
//                             </div>
//                           </Grid>
//                         </Grid>

//                         <Grid container spacing={3}>
//                           <Grid item md="6">
//                             <div className="reg_input auto-complete">
//                               <Autocomplete
//                                 disablePortal
//                                 id={index + "_Country"}
//                                 name="Country"
//                                 value={countryData ? countryData[index] : ""}
//                                 options={CountryData ? CountryData : ""}
//                                 onChange={(e, value) => addressHandleOnChange(e, "country", value)}
//                                 //   onBlur={GetSource}
//                                 renderInput={(params) => (
//                                   <TextField
//                                     {...params}
//                                     label="Country"
//                                     error={errors.Country}
//                                     helperText={errors.Country ? "Country Required." : ""}
//                                   />
//                                 )}
//                               />
//                             </div>
//                           </Grid>
//                         </Grid>

//                         <Grid container spacing={3}>
//                           <Grid item md="3">
//                             <div className="reg_input">
//                               <h6>City</h6>
//                               <div className="reg_input">
//                                 <input
//                                   id={index + "_City"}
//                                   name="City"
//                                   variant="outlined"
//                                   value={cityData ? cityData[index] : ""}
//                                   className={index}
//                                   onChange={(e) => addressHandleOnChange(e, "city")}
//                                 />
//                               </div>
//                             </div>
//                           </Grid>
//                           <Grid item md="3">
//                             <div className="reg_input auto-complete">
//                               <Autocomplete
//                                 disablePortal
//                                 id={index + "_State"}
//                                 name="State"
//                                 value={stateData ? stateData[index] : ""}
//                                 options={stateList ? stateList : ""}
//                                 onChange={(e, value) => addressHandleOnChange(e, "state", value)}
//                                 //   onBlur={GetSource}
//                                 renderInput={(params) => (
//                                   <TextField
//                                     {...params}
//                                     label="State"
//                                     error={errors.State}
//                                     helperText={errors.State ? "State Required." : ""}
//                                   />
//                                 )}
//                               />
//                             </div>
//                           </Grid>
//                           <Grid item md="3">
//                             <h6>Postal Code</h6>
//                             <div className="reg_input">
//                               <input
//                                 value={postalCodeData ? postalCodeData[index] : ""}
//                                 name="PostalCode"
//                                 id={index + "_PostalCode"}
//                                 label="PostalCode"
//                                 variant="outlined"
//                                 className={index}
//                                 onChange={(e) => addressHandleOnChange(e, "postalcode")}
//                                 maxlength="4"
//                               />
//                             </div>
//                           </Grid>
//                         </Grid>
//                       </div>
//                     );
//                   })}
//                   <Grid container spacing={0}>
//                     {/* <Grid item md="2">
//                       <div className="reg_input">
//                         <MDButton onClick={onRemoveAddressBtn} variant="contained" color="primary">
//                           Remove
//                         </MDButton>
//                       </div>
//                     </Grid> */}
//                     <Grid item md="2">
//                       <div className="reg_input">
//                         <MDButton onClick={onAddAddressBtn} variant="contained" color="secondary">
//                           Add More
//                         </MDButton>
//                       </div>
//                     </Grid>
//                   </Grid>

//                   <Grid container spacing={3}>
//                     <Grid item md="6">
//                       <div className="reg_input">
//                         <Controller
//                           name="Website"
//                           control={control}
//                           defaultValue=""
//                           render={({ field: { onChange, value }, fieldState: { error } }) => (
//                             <TextField
//                               label="Website"
//                               variant="outlined"
//                               value={value}
//                               onChange={onChange}
//                               error={error}
//                               helperText={error?.message}
//                             />
//                           )}
//                         />
//                       </div>
//                     </Grid>
//                     <Grid item md="6">
//                       <div className="reg_input auto-complete">
//                         <Controller
//                           control={control}
//                           name="Industry"
//                           rules={{ required: true }}
//                           render={({ field: { onChange, value } }) => (
//                             <Autocomplete
//                               disablePortal
//                               id="Industry"
//                               name="Industry"
//                               value={value}
//                               options={IndustryData ? IndustryData : ""}
//                               onChange={(e, v) => onChange(v)}
//                               renderInput={(params) => (
//                                 <TextField
//                                   autoComplete="off"
//                                   {...params}
//                                   label="Industry"
//                                   error={errors.Industry}
//                                   helperText={errors.Industry ? "Industry Required." : ""}
//                                 />
//                               )}
//                             />
//                           )}
//                         />
//                       </div>
//                     </Grid>
//                   </Grid>
//                   <Grid container spacing={3}>
//                     <Grid item md="12">
//                       <div className="reg_input auto-complete">
//                         <Controller
//                           control={control}
//                           name="CompanySize"
//                           rules={{ required: true }}
//                           render={({ field: { onChange, value } }) => (
//                             <Autocomplete
//                               disablePortal
//                               id="CompanySize"
//                               name="CompanySize"
//                               value={value}
//                               options={CompanySizeData ? CompanySizeData : ""}
//                               onChange={(e, v) => onChange(v)}
//                               renderInput={(params) => (
//                                 <TextField
//                                   autoComplete="off"
//                                   {...params}
//                                   label="Company Size (No of Employees)"
//                                   error={errors.CompanySize}
//                                   helperText={errors.CompanySize ? "Company Size Required." : ""}
//                                 />
//                               )}
//                             />
//                           )}
//                         />
//                       </div>
//                     </Grid>
//                   </Grid>

//                   <Grid container spacing={3}>
//                     <Grid item md="4">
//                       <div className="reg_input auto-complete">
//                         <Controller
//                           control={control}
//                           name="SalesTurnover"
//                           rules={{ required: true }}
//                           render={({ field: { onChange, value } }) => (
//                             <Autocomplete
//                               disablePortal
//                               id="SalesTurnover"
//                               name="SalesTurnover"
//                               value={value}
//                               options={SalesTurnoverData ? SalesTurnoverData : ""}
//                               onChange={(e, v) => onChange(v)}
//                               renderInput={(params) => (
//                                 <TextField
//                                   autoComplete="off"
//                                   {...params}
//                                   label="Sales Turnover"
//                                   error={errors.SalesTurnover}
//                                   helperText={
//                                     errors.SalesTurnover ? "SalesTurnover Size Required." : ""
//                                   }
//                                 />
//                               )}
//                             />
//                           )}
//                         />
//                       </div>
//                     </Grid>

//                     <Grid item md="4">
//                       <div className="reg_input auto-complete">
//                         <Controller
//                           control={control}
//                           name="RequirementType"
//                           rules={{ required: true }}
//                           render={({ field: { onChange, value } }) => (
//                             <Autocomplete
//                               disablePortal
//                               id="RequirementType"
//                               name="RequirementType"
//                               value={value}
//                               options={RequirementTypeData ? RequirementTypeData : ""}
//                               onChange={(e, v) => onChange(v)}
//                               onBlur={GetSource}
//                               renderInput={(params) => (
//                                 <TextField
//                                   {...params}
//                                   label="Requirement Type"
//                                   error={errors.RequirementType}
//                                   helperText={
//                                     errors.RequirementType ? "Requirement Type Required." : ""
//                                   }
//                                 />
//                               )}
//                             />
//                           )}
//                         />
//                       </div>
//                     </Grid>

//                     <Grid item md="4">
//                       {requirementData == "Other" ? (
//                         <div className="reg_input">
//                           <Controller
//                             name="OtherRequirementType"
//                             control={control}
//                             defaultValue=""
//                             render={({ field: { onChange, value }, fieldState: { error } }) => (
//                               <TextField
//                                 label=""
//                                 variant="outlined"
//                                 value={value}
//                                 onChange={onChange}
//                                 error={error}
//                                 helperText={error?.message}
//                               />
//                             )}
//                           />
//                         </div>
//                       ) : (
//                         ""
//                       )}
//                     </Grid>
//                   </Grid>
//                   <Grid container spacing={3}>
//                     <Grid item md="8">
//                       <div className="reg_input sec_level">
//                         <Autocomplete
//                           multiple
//                           id="Services"
//                           name="Services"
//                           options={SkillList ? SkillList : []}
//                           value={serviceAutocompleteValues ? serviceAutocompleteValues : []}
//                           defaultValue={serviceAutocompleteValues ? serviceAutocompleteValues : []}
//                           onChange={onServiceChange}
//                           filterSelectedOptions
//                           formControlProps={{
//                             fullWidth: true,
//                           }}
//                           renderInput={(params) => (
//                             <TextField
//                               {...params}
//                               label="Skill Set"
//                               placeholder="Favorites"
//                               id="Services"
//                               name="Services"
//                               formControlProps={{
//                                 fullWidth: true,
//                               }}
//                             />
//                           )}
//                         />
//                       </div>
//                     </Grid>
//                   </Grid>

//                   <Grid container spacing={3}>
//                     <Grid item md="8">
//                       <div className="reg_input">
//                         <Controller
//                           name="Requirement"
//                           control={control}
//                           defaultValue=""
//                           render={({ field: { onChange, value }, fieldState: { error } }) => (
//                             <TextField
//                               label="Requirement"
//                               color="primary"
//                               variant="outlined"
//                               rows={3}
//                               multiline
//                               formControlProps={{
//                                 fullWidth: true,
//                               }}
//                               value={value}
//                               onChange={onChange}
//                               error={error}
//                               helperText={error?.message}
//                             />
//                           )}
//                         />
//                       </div>
//                     </Grid>

//                     <Grid item md="4">
//                       <div className="reg_input auto-complete">
//                         <Controller
//                           control={control}
//                           name="Stage"
//                           rules={{ required: true }}
//                           render={({ field: { onChange, value } }) => (
//                             <Autocomplete
//                               disablePortal
//                               id="Stage"
//                               name="Stage"
//                               value={value}
//                               options={StageData ? StageData : ""}
//                               onChange={(e, v) => onChange(v)}
//                               renderInput={(params) => (
//                                 <TextField
//                                   autoComplete="off"
//                                   {...params}
//                                   label="Stage"
//                                   error={errors.Stage}
//                                   helperText={errors.Stage ? "Stage Required." : ""}
//                                 />
//                               )}
//                             />
//                           )}
//                         />
//                       </div>
//                     </Grid>
//                   </Grid>
//                 </div>
//               </div>

//               <div className="reg_submit">
//                 <MDButton
//                   onClick={() => navigate("/layouts/lead/Index")}
//                   variant="contained"
//                   color="secondary"
//                 >
//                   Cancel
//                 </MDButton>
//                 <MDButton type="submit" variant="contained" color="primary">
//                   Save
//                 </MDButton>
//               </div>

//               <div className="copyright">
//                 <p>Copyright &copy; 2024 All Rights Reserved | Powered by Webment</p>
//               </div>
//             </form>
//           </MDBox>
//         </Card>
//       </DashboardLayout>
//     </>
//   );
// };

// NewContact.defaultProps = {};

// export default NewContact;
