import React, { useEffect, useMemo, useState, useRef } from "react";
import { useLocation, useNavigate, Navigate} from "react-router-dom";
import { useForm, Controller, useWatch } from "react-hook-form";
import cogoToast from "cogo-toast";
import axios from "axios";
import { DashCircle, PlusCircle } from "react-bootstrap-icons";
// import DatePicker from "react-date-picker";
import Multiselect from "multiselect-react-dropdown";
import PhoneInput from "react-phone-input-2";
import MDDatePicker from "components/MDDatePicker";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDInput from "components/MDInput";
import { CommonConfig } from "constant";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import Icon from "@mui/material/Icon";
import MDTypography from "components/MDTypography";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { ArrowDownward } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { Button as PButton } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

import {
  FormControlLabel,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
  Radio,
  Select,
  RadioGroup,
  Grid,
  Button,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import Autocomplete from "@mui/material/Autocomplete";
import Logo from "assets/img/cropped-webment.png";
import MDButton from "components/MDButton";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import moment from "moment-timezone";
import { item } from "examples/Sidenav/styles/sidenavItem";

import { isValidDate } from "@fullcalendar/react";
import { Alert } from "@mui/material";
import NewCompany from "./NewCompany";
import ContactList from "./ContactList";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import { NotesComponent } from "layouts/component/Notes";



const SkillList = [{ label: ". NET" }, { label: "React" }, { label: "Node" }];
const Lead = () => {
  const { state } = useLocation();
  console.log("the state is:",state);
  let navigate = useNavigate();
  
  var PersonId = CommonConfig.loggedInUserData();
  console.log("Hello",PersonId);
  if(PersonId==undefined || PersonId==""){
    navigate('authentication/login');
  }

  let clientRef = useRef(false);
  const MAX_COUNT = 5;
  // const termsCondition = "";
  const [values, setValues] = useState([]);
  console.log("values", values);
  const [stateId, setStateId] = useState([]);
  const [stateLabel, setStateLabel] = useState(state?.SecurityUserData?.State || null);
  const [relativeStateId, setRelativeStateId] = useState([]);
  const [uploadfile, setuploadfile] = useState([]);
  const [relativeStateLabel, setRelativeStateLabel] = useState(
    state?.SecurityUserData?.RelativeState || null
  );
  const [serviceAutocompleteValues, setServiceAutocompleteValues] = useState(null);

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fileLimit, setFileLimit] = useState(false);
  const [leadData, setLeadData] = useState(state || null);
  // const [DocumentuserData, setDocumentUserData] = useState(state[1] || null);
  console.log("KKKK", leadData);
  localStorage.setItem("PrevStageData", JSON.stringify(leadData ? leadData : ""));
  let PrevStageData = JSON.parse(localStorage.getItem("PrevStageData"));
  console.log("PrevStageData", PrevStageData.Stage);

  let NewCompanyId = JSON.parse(localStorage.getItem("NewCompanyId"));
  console.log("NewCompanyIdQQ", NewCompanyId);
  let LedData = JSON.parse(localStorage.getItem("leadData"));
  console.log("LedDataQQ", LedData);
  let ContactData = JSON.parse(localStorage.getItem("contactData"));
  console.log("ContactData", ContactData);
  const [stateList, setStateList] = useState(null);
  const [contactPersonId, setContactPersonId] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const [companyForLocal, setCompanyForLocal] = useState(null);

  const [sourceData, setSourceData] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [documentdata, setdocumentdata] = useState([]);
  const [serviceList, setServiceList] = useState(null);
  const [loader, setLoader] = React.useState(false);
  const [data, setData] = useState(null);

  const [handlerData, setHandlerData] = useState(null);
  const [rquirementTypeData, setRquirementTypeData] = useState(null);
  const [sourceDataList, setSourceDataList] = useState(null);
  const [stageData, setStageData] = useState(null);

  const [leadDate, setLeadDate] = useState(null);
  const [FollowUpDate, setFollowupDate] = useState(null);
  // Insert Multiple Contact Id....................................
  const [contactId, setContactId] = useState(null);
  console.log("setContactIdQAAQ", contactId);
  const [selectedContactId, setSelectedContactId] = useState(null);
  const [finalContactId, setFinalContactId] = useState(null);
  const [chNotes, setChNotes] = useState(null);
  const [notesData, setNotesData] = useState([]);
  const [sourceValue, setSourceValue] = useState(null);
  // const [companyIdValue, setCompanyIdValue] = useState(null);
  const [followUpValue, setFollowUpValue] = useState(null);

  console.log("followUpValueeeee1", followUpValue);
  
  useEffect(()=>{
     if(state==null) setLeadData(null);
  },[state]);
  useEffect(
    (v) => {
      if (companyForLocal) {
        setCompanyId(companyForLocal?.id);
      }
    },
    [companyForLocal]
  );

  useEffect(() => {
    if (leadData) {
      setSourceValue(leadData?.SourceType);
    }
  }, [leadData]);

  useEffect(() => {
    if (contactId) {
      setSelectedContactId(
        contactId?.map((item) => ({
          id: item?.CompanyContactPersonID,
        }))
      );
    }
  }, [contactId]);

  console.log("selectedContactIdcheck", selectedContactId);
  console.log("selectedleadData", leadData?.SourceType);

  const setFinalContactID = () => {
    let dummyArry = [];
    for (let i = 0; i < selectedContactId?.length; i++) {
      console.log("text00", i);
      console.log("selectedContactid11", selectedContactId[i]?.id);
      dummyArry.push(selectedContactId[i]?.id);
    }
    setFinalContactId(dummyArry);
    console.log("dummyArry", dummyArry);
  };
  console.log("finalContactIdbhanu", finalContactId);

  useEffect(() => {
    if (selectedContactId) {
      setFinalContactID();
    }
  }, [selectedContactId]);

  // ........End of Insert Multiple Contact Id............................

  // Update Multiple Contact ID Start from here...................
  console.log("leadDataCompanyContactPer", leadData?.CompanyContactPersonID);
  useEffect(() => {
    if (data) {
      const CompanyContactPersonID =
        "e8f391d5-7b74-11ed-9f52-fa163e3ffd3c,f0e48f4f-810c-11ed-9f52-fa163e3ffd3c,3fc2267d-7b73-11ed-9f52-fa163e3ffd3c,21518ec9-7d2b-11ed-9f52-fa163e3ffd3c";
      let arr = leadData?.CompanyContactPersonID
        ? leadData?.CompanyContactPersonID?.split(",")
        : LedData?.CompanyContactPersonID
        ? LedData?.CompanyContactPersonID.split(",")
        : "";
      console.log("leadDataCompanyContactPersonID", arr);
      let SetContactIdArry = [];
      console.log("loklokloklokloklokloklokloklokloklokloklok");
      let mainArry = [];
      for (var i = 0; i < arr?.length; i++) {
        let mainObject = {};
        mainObject.id = arr[i];
        mainArry.push(mainObject);
        console.log("mainAQQrry", mainObject.id);
        let setC = data?.filter((name) => name.CompanyContactPersonID === mainObject.id);
        console.log("setCsetCsetC", setC);
        SetContactIdArry.push(setC);
      }
      console.log("SetContactIdArryQQ", SetContactIdArry);
      let secondArry = [];
      for (var i = 0; i < SetContactIdArry.length; i++) {
        let secondObj = {};
        secondObj = SetContactIdArry[i][0];
        secondArry.push(secondObj);
        setContactId(secondArry);
      }
      console.log("secondArryQ", secondArry);
    }
  }, [data]);

  console.log("contactIdcheck", contactId);

  // ..............End.................................

  const [selectContactButtonvalue, setSelectContactButtonvalue] = React.useState(0);
  const [addNewContactButtonvalue, setAddNewContactButtonvalue] = React.useState(0);
  const [selectSaveButtonvalue, setSelectSaveButtonvalue] = React.useState("");
  const [requirementData, setRequirementData] = useState(null);

  const [twoButtonvalue, setTwoButtonvalue] = React.useState(0);

  const onStateChange = (event, value) => {
    console.log("State::", value);
    setStateId(value.id);
    setStateLabel(value.label);
  };
  // let dir = "https://services.valuecare.org.au/Backend/documents/Referals/";
  // const documentdata = userData.dOC;

  const HandlerData = [{ label: "Kamal" }, { label: "Purveen" }];

  const SourceData = [
    { label: "LinkedIn" },
    { label: "Upwork" },
    { label: "Referral Network" },
    { label: "Other" },
  ];

  const CompanyList = [
    { label: "Company1" },
    { label: "Company2" },
    { label: "Company3" },
    { label: "Company4" },
  ];

  const RequirementTypeData = [
    { label: "Web App" },
    { label: "Mobile App" },
    { label: "Digital Marketing" },
    { label: "Other" },
  ];
  // const SkillList = [{ label: ". NET" }, { label: "React" }, { label: "Node" }];
  const StageData = [
    { label: "Lead" },
    { label: "Follow Up" },
    { label: "Won" },
    { label: "Lost" },
    { label: "Purged" },
  ];

  const GetRequirement = (e, value) => {
    var setoffice = e.target.value;

    var dataSET = {
      source: setoffice,
    };
    setRequirementData(dataSET.source);
    console.log("sourceData", requirementData);
  };

  const columns = [
    {
      name: "Company Contact Person ID",
      selector: "CompanyContactPersonID",
      filter: false,
      sortable: false,
    },

    {
      name: "Name",
      selector: "FirstName",
      filter: false,
      sortable: false,
    },
    {
      name: "Email",
      selector: "Email",
      filter: false,
      sortable: false,
    },
    {
      name: "Phone",
      selector: "PhoneNumber",
      filter: false,
      sortable: false,
    },

    {
      name: "Action",
      body: (data) => actionTemplate(data),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const actionTemplate = (data) => {
    console.log("tempdata", data);
    return (
      <div>
        <PButton
          type="button"
          icon="pi pi-user-edit"
          className="p-button-success"
          onClick={() => editContact(data)}
          style={{ marginRight: ".5em" }}
          tooltip="Edit Contact"
          tooltipOptions={{ position: "bottom" }}
        />

        <PButton
          type="button"
          icon="pi pi-plus"
          className="p-button-primary AddNotes"
          onClick={() => newContact()}
          // style={{ marginRight: ".5em" }}
          tooltip="Add New Contact"
          tooltipOptions={{ position: "bottom" }}
        />
      </div>
    );
  };

  console.log("leadData?.CompanyContactPersonID", leadData?.CompanyContactPersonID);

  const getColoumnsRow = () =>
    columns.map((item, id) => {
      return (
        <Column
          style={{ minWidth: "200px" }}
          key={id}
          sortable={item.sortable}
          body={item?.body}
          filter={item.filter}
          field={item.selector}
          header={item.name}
        />
      );
    });

  // const getCompanyId = async (e, value) => {
  //   console.log("NewCompanyId?.CompanyID01AA", e);
  //   //debugger;
  //   console.log("setofficeQQ111");
  //   var setoffice = stateList.filter((name) => name.label === e.target.value);
  //   console.log("setofficeQQasd", setoffice[0].id);
  //   setCompanyId(setoffice[0].id);

  //   console.log("setofficeQQZASZZ", setoffice[0]);
  //   setCompanyForLocal(setoffice[0]);
  // };
  // console.log("companyId00", companyId);

  const getContactBYCompany = async () => {
    console.log("NewCompanyId?.CompanyID01", NewCompanyId?.CompanyID);
    console.log("companyIdQQQQ", companyId);
    //debugger;

    // ? leadData?.CompanyID
    // : companyForLocal
    // ? companyForLocal?.id
    // : LedData?.CompanyID
    // ? LedData?.CompanyID

    const formattedData = {
      CompanyID: companyId
        ? companyId
        : NewCompanyId?.CompanyID
        ? NewCompanyId?.CompanyID
        : leadData?.CompanyID
        ? leadData?.CompanyID
        : LedData?.CompanyID
        ? LedData?.CompanyID
        : "",
    };
    console.log("formattedDataforcompany", formattedData);

    try {
      const data = await axios(CommonConfig.ApiUrl + "authentication/getContactBYCompanyID", {
        method: "POST",
        data: formattedData,
      }).then((res) => {
        console.log("res0000", res.data.data[0]);
        setData(res.data.data[0]);
      });
    } catch (error) {
      console.log({ error });
    }
  };

  console.log("datadata", data);

  function selectContact(selectedRow) {
    console.log("........,,,", selectedRow);
    setContactPersonId(selectedRow.CompanyContactPersonID);

    if (selectedRow.CompanyContactPersonID != "") {
      cogoToast.success("Contact Selected.");
    }
  }
  console.log("companyForLocal", companyForLocal);

  function editContact(selectedRow) {
    const newContactData = {
      LeadTitle: document.getElementById("LeadTitle")?.value,
      Handler: document.getElementById("Handler")?.value,
      LeadDate: leadData
        ? leadData.LeadDate
        : leadDate
        ? leadDate
        : LedData?.LeadDate
        ? LedData?.LeadDate
        : "",
        FollowUpDate: leadData
        ? leadData.FollowUpDate
        : FollowUpDate
        ? FollowUpDate
        : LedData?.FollowUpDate
        ? LedData?.FollowUpDate
        : "",
      Source: document.getElementById("Source")?.value,
      SourceTypeOther: document.getElementById("SourceTypeOther")?.value,
      SourceName: document.getElementById("SourceName")?.value,
      CompanyType: document.getElementById("CompanyType")?.value,
      RequirementType: document.getElementById("RequirementType")?.value,
      SourceName:document.getElementById("SourceName")?.value,
      OtherRequirementType: document.getElementById("OtherRequirementType")?.value,
      SkillSet: values ? values : "",
      Requirement: document.getElementById("Requirement")?.value,
      Stage: document.getElementById("Stage")?.value,
      CompanyID: leadData
        ? leadData?.CompanyID
        : companyForLocal
        ? companyForLocal?.id
        : LedData?.CompanyID
        ? LedData?.CompanyID
        : "",
      CompanyName: leadData
        ? leadData?.CompanyName
        : companyForLocal
        ? companyForLocal?.label
        : LedData?.CompanyName
        ? LedData?.CompanyName
        : "",
      CompanyContactPersonID: finalContactId?.toString(),
      LeadID: state?.LeadID ? state?.LeadID : LedData ? LedData.LeadID : "",
      PreviousStageStore: PrevStageData?.Stage
        ? PrevStageData?.Stage
        : LedData?.PreviousStageStore
        ? LedData?.PreviousStageStore
        : "",
    };

    const newLead = {
      test: "test",
    };
    localStorage.setItem("newData", JSON.stringify(newLead));
    localStorage.setItem("leadData", JSON.stringify(newContactData));

    navigate("/layouts/lead/NewContact", {
      state: selectedRow,
    });
  }

  useEffect(() => {
    getContactBYCompany();
  }, [companyId]);

  const getStateData = () => {
    axios({
      url: CommonConfig.ApiUrl + "authentication/getCompanyList",
      method: "GET",
      headers: {},
      data: "",
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("StateList===>", res.data.data[0]);

          setStateList(
            res.data.data[0].map((item) => ({
              id: item.CompanyID,
              label: item.CompanyName,
            }))
          );
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };
  console.log("stateListQQ", stateList);

  const handleFileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
  };
  const handleUploadFiles = (files) => {
    const uploaded = [...uploadedFiles];
    let limitExceeded = false;
    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        if (uploaded.length === MAX_COUNT) setFileLimit(true);
        if (uploaded.length > MAX_COUNT) {
          alert(`You can only add a maximum of ${MAX_COUNT} files`);
          setFileLimit(false);
          limitExceeded = true;
          return true;
        }
      }
    });
    if (!limitExceeded) setUploadedFiles(uploaded);
  };

  console.log("leadData?.SkillSet", leadData?.SkillSet);

  const setAutocompleteValues = () => {
    let dummyArray = [];
    let formattedData = [];
    let array = leadData?.SkillSet || LedData?.SkillSet?.toString();
    array = array?.split(",");
    for (let i = 0; i < array?.length; i++) {
      if (array[i]) {
        dummyArray.push({
          id: array[i],
          label: array[i],
        });
      }
    }
    for (let i = 0; i < array?.length; i++) {
      formattedData.push(array[i]);
    }
    debugger;
    setValues(formattedData);
    setServiceAutocompleteValues(dummyArray);
  };
  console.log("setServiceAutocompleteValues", serviceAutocompleteValues);

  const onServiceChange = (event, value) => {
    console.log("VALUEService", value);
    let formattedData = [];

    for (let i = 0; i < value.length; i++) {
      formattedData.push(value[i].label);
    }

    console.log("formattedDatazasQ", formattedData.toString());

    setValues(formattedData);
    setServiceAutocompleteValues(value);
    console.log("onService", values);
  };

  const getServiceData = () => {
    const formattedData = {
      StringMapType: "SKILLSET",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "authentication/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("ServiceList===>", res.data.data);
          setServiceList(
            res.data.data.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getHandlerData = () => {
    const formattedData = {
      StringMapType: "HANDLER",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "authentication/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("ServiceList===>", res.data.data);
          setHandlerData(
            res.data.data.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  console.log("handlerDataQQ", handlerData);

  const getRquirementTypeData = () => {
    const formattedData = {
      StringMapType: "REQUIEMENTTYPE",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "authentication/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("ServiceList===>", res.data.data);
          setRquirementTypeData(
            res.data.data.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSourceData = () => {
    const formattedData = {
      StringMapType: "SOURCES",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "authentication/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("ServiceList===>", res.data.data);
          setSourceDataList(
            res.data.data.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getStageData = () => {
    const formattedData = {
      StringMapType: "STAGE",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "authentication/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("ServiceList===>", res.data.data);
          setStageData(
            res.data.data.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getStageData();
    getSourceData();
    getRquirementTypeData();
    getHandlerData();
    getStateData();
    getServiceData();
    setAutocompleteValues();
    getNoteList(state?.LeadID ? state?.LeadID : LedData ? LedData.LeadID : "");
  }, []);

  // companyForLocal
  console.log("UserData =1222", LedData);
  const Companydata = {
    id: leadData?.CompanyName ? leadData?.CompanyID : LedData?.CompanyID,
    label: leadData?.CompanyName ? leadData?.CompanyName : LedData?.CompanyName,
  };
  const Handlerdata = {
    id: leadData?.HandlerPersonid ? leadData?.HandlerPersonid : LedData?.Handler,
    label: leadData?.HandlerPersonid ? leadData?.HandlerPersonid : LedData?.Handler,
  };
  const Sourcedata = {
    id: leadData?.SourceType ? leadData?.SourceType : LedData?.Source,
    label: leadData?.SourceType ? leadData?.SourceType : LedData?.Source,
  };
  const RequirementTypeDataList = {
    id: leadData?.RequirementType ? leadData?.RequirementType : LedData?.RequirementType,
    label: leadData?.RequirementType ? leadData?.RequirementType : LedData?.RequirementType,
  };

  const StageDataList = {
    id: leadData?.Stage ? leadData?.Stage : LedData?.Stage,
    label: leadData?.Stage ? leadData?.Stage : LedData?.Stage,
  };


  
  console.log("StageDataList = ",StageDataList);

  const {
    register,
    handleSubmit,
    watch,
    control,
    // errors,
    formState: { errors },
    getValues,
  } = useForm({
    defaultValues: {
      LeadTitle: leadData?.LeadTitle ? leadData?.LeadTitle : LedData?.LeadTitle,
      Handler: LedData?.Handler || leadData?.HandlerPersonid ? Handlerdata : "",
      LeadDate: leadData?.LeadDate ? leadData?.LeadDate : LedData?.LeadDate,
      Source: LedData?.Source || leadData?.SourceType ? Sourcedata : "",
      SourceTypeOther: leadData?.SourceTypeOther
        ? leadData?.SourceTypeOther
        : LedData?.SourceTypeOther,
      SourceName: leadData?.SourceName ? leadData?.SourceName : LedData?.SourceName,
      CompanyID: LedData?.CompanyID || leadData?.CompanyName ? Companydata : "",
      NewCompany: LedData?.NewCompany ? LedData?.NewCompany : "",
      CompanyType: LedData?.CompanyType ? LedData?.CompanyType : "Existing Company",
      RequirementType:
        LedData?.RequirementType || leadData?.RequirementType ? RequirementTypeDataList : "",
      OtherRequirementType: leadData?.OtherRequirementType
        ? leadData?.OtherRequirementType
        : LedData?.OtherRequirementType,
      Requirement: leadData?.Requirement ? leadData?.Requirement : LedData?.Requirement,
      Stage: leadData?.Stage || leadData?.Stage ? StageDataList : "",
      
      followUpValue: leadData?.Stage || leadData?.Stage ? StageDataList : "",
      FollowUpDate: leadData?.FollowUpDate ? leadData?.FollowUpDate : LedData?.FollowUpDate,
    },
   
  });

  // if(leadData){
  //   this.state.Stage.label = leadData.Stage;
    
  // }

  const GetSource = (e, value) => {
    var setoffice = e.target.value;

    var dataSET = {
      source: setoffice,
    };
    setSourceData(dataSET.source);
    console.log("sourceData", sourceData);
  };
  
  const CompanyType = watch("CompanyType");

  console.log("leadDataQQQ", LedData);

  const getValueAddNewContactButton = () => {
    const newContactData = {
      LeadTitle: document.getElementById("LeadTitle")?.value,
      Handler: document.getElementById("Handler")?.value,
      LeadDate: leadData
        ? leadData.LeadDate
        : leadDate
        ? leadDate
        : LedData?.LeadDate
        ? LedData?.LeadDate
        : "",
        FollowUpDate: leadData
        ? leadData.FollowUpDate
        : FollowUpDate
        ? FollowUpDate
        : LedData?.FollowUpDate
        ? LedData?.FollowUpDate
        : "",
      Source: document.getElementById("Source")?.value,
      SourceTypeOther: document.getElementById("SourceTypeOther")?.value,
      SourceName: document.getElementById("SourceName")?.value,
      CompanyType: document.getElementById("CompanyType")?.value,
      RequirementType: document.getElementById("RequirementType")?.value,
      OtherRequirementType: document.getElementById("OtherRequirementType")?.value,
      SkillSet: values ? values : "",
      Requirement: document.getElementById("Requirement")?.value,
      Stage: document.getElementById("Stage")?.value,
      CompanyID: leadData
        ? leadData?.CompanyID
        : companyForLocal
        ? companyForLocal?.id
        : LedData?.CompanyID
        ? LedData?.CompanyID
        : "",
      CompanyName: leadData
        ? leadData?.CompanyName
        : companyForLocal
        ? companyForLocal?.label
        : LedData?.CompanyName
        ? LedData?.CompanyName
        : "",
      CompanyContactPersonID: finalContactId?.toString(),
      LeadID: state?.LeadID ? state?.LeadID : LedData ? LedData.LeadID : "",
      PreviousStageStore: PrevStageData?.Stage
        ? PrevStageData?.Stage
        : LedData?.PreviousStageStore
        ? LedData?.PreviousStageStore
        : "",
    };

    const newLead = {
      test: "test",
    };
    localStorage.setItem("newData", JSON.stringify(newLead));
    localStorage.setItem("leadData", JSON.stringify(newContactData));
    navigate("/layouts/lead/NewContact");
    console.log("newContactData", newContactData);
  };
  const getValueSaveButton = () => {
    setSelectSaveButtonvalue("save");
  };

  const display = (e) => {
    setLeadDate(CommonConfig.formatDate(e));
  };

  const displayFollow = (e) => {
    console.log("display follow e:",e);
    setFollowupDate(CommonConfig.formatDate(e));

  };

  useEffect(()=>{
    console.log("followup date is ",FollowUpDate);
  },[FollowUpDate]);

  const newCompany = () => {
    const newLeadData = {
      LeadTitle: document.getElementById("LeadTitle")?.value,
      Handler: document.getElementById("Handler")?.value,
      LeadDate: leadData
        ? leadData.LeadDate
        : leadDate
        ? leadDate
        : LedData?.LeadDate
        ? LedData?.LeadDate
        : "",
        FollowUpDate: leadData
        ? leadData.FollowUpDate
        : FollowUpDate
        ? FollowUpDate
        : LedData?.FollowUpDate
        ? LedData?.FollowUpDate
        : "",
      Source: document.getElementById("Source")?.value,
      SourceTypeOther: document.getElementById("SourceTypeOther")?.value,
      SourceName: document.getElementById("SourceName")?.value,
      CompanyType: document.getElementById("CompanyType")?.value,
      RequirementType: document.getElementById("RequirementType")?.value,
      OtherRequirementType: document.getElementById("OtherRequirementType")?.value,
      SkillSet: values ? values : "",
      Requirement: document.getElementById("Requirement")?.value,
      Stage: document.getElementById("Stage")?.value,
      CompanyID: leadData
        ? leadData?.CompanyID
        : companyForLocal
        ? companyForLocal?.id
        : LedData?.CompanyID
        ? LedData?.CompanyID
        : "",
      CompanyName: leadData
        ? leadData?.CompanyName
        : companyForLocal
        ? companyForLocal?.label
        : LedData?.CompanyName
        ? LedData?.CompanyName
        : "",
      CompanyContactPersonID: finalContactId?.toString(),
      LeadID: state?.LeadID ? state?.LeadID : LedData ? LedData.LeadID : "",
      PreviousStageStore: PrevStageData?.Stage
        ? PrevStageData?.Stage
        : LedData?.PreviousStageStore
        ? LedData?.PreviousStageStore
        : "",
    };
    const newLead = {
      test: "test",
    };
    localStorage.setItem("newData", JSON.stringify(newLead));
    localStorage.setItem("leadData", JSON.stringify(newLeadData));
    navigate("/layouts/lead/NewCompany");
    console.log("newLeadData", newLeadData);
  };

  const newContact = () => {
    const newContactData = {
      LeadTitle: document.getElementById("LeadTitle")?.value,
      Handler: document.getElementById("Handler")?.value,
      LeadDate: leadData
        ? leadData.LeadDate
        : leadDate
        ? leadDate
        : LedData?.LeadDate
        ? LedData?.LeadDate
        : "",
        FollowUpDate: leadData
        ? leadData.FollowUpDate
        : FollowUpDate
        ? FollowUpDate
        : LedData?.FollowUpDate
        ? LedData?.FollowUpDate
        : "",
      Source: document.getElementById("Source")?.value,
      SourceTypeOther: document.getElementById("SourceTypeOther")?.value,
      SourceName: document.getElementById("SourceName")?.value,
      CompanyType: document.getElementById("CompanyType")?.value,
      RequirementType: document.getElementById("RequirementType")?.value,
      OtherRequirementType: document.getElementById("OtherRequirementType")?.value,
      SkillSet: values ? values : "",
      Requirement: document.getElementById("Requirement")?.value,
      Stage: document.getElementById("Stage")?.value,
      CompanyID: leadData
        ? leadData?.CompanyID
        : companyForLocal
        ? companyForLocal?.id
        : LedData?.CompanyID
        ? LedData?.CompanyID
        : "",
      CompanyName: leadData
        ? leadData?.CompanyName
        : companyForLocal
        ? companyForLocal?.label
        : LedData?.CompanyName
        ? LedData?.CompanyName
        : "",

      CompanyContactPersonID: finalContactId?.toString(),
      LeadID: state?.LeadID ? state?.LeadID : LedData ? LedData.LeadID : "",
      PreviousStageStore: PrevStageData?.Stage
        ? PrevStageData?.Stage
        : LedData?.PreviousStageStore
        ? LedData?.PreviousStageStore
        : "",
    };

    const newLead = {
      test: "test",
    };
    localStorage.setItem("newData", JSON.stringify(newLead));
    localStorage.setItem("leadData", JSON.stringify(newContactData));
    navigate("/layouts/lead/NewContact");
    console.log("newContactDataQA", newContactData);
  };

  const Lead = {
    AddLead: "data",
  };
  localStorage.setItem("addLeadData", JSON.stringify(Lead));

  console.log("contactPersonIdnaaa", contactPersonId);

  const cancel = () => {
    navigate("/layouts/lead/LeadList");
    localStorage.removeItem("leadData");
    localStorage.removeItem("NewCompanyId");
    localStorage.removeItem("PrevStageData");
  };

  useWatch({
    control,
    name: "Source", // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
  });
  console.log("LedData?.LeadID", LedData?.LeadID);

  const getNoteList = () => {
    let noteReqData = {
      EntityType: "Lead",
      EntityId: state?.LeadID ? state?.LeadID : LedData ? LedData.LeadID : "",
      CurrentUser: "",
    };
    axios({
      url: CommonConfig.ApiUrl + "authentication/getNoteList",
      method: "POST",
      headers: {},
      data: noteReqData,
    })
      .then((res) => {
        if (res.data?.success) {
          setNotesData(res.data.data || []);
          document.getElementById("NotesTableDynamic").style.display = "block";
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const saveNotes = () => {
    if (chNotes === null || chNotes === "") {
      document.getElementById("NotesError").style.display = "block";
      return;
    } else {
      document.getElementById("NotesError").style.display = "none";
    }

    const formattedData = {
      EntityType: "Lead",
      EntityId: state?.LeadID ? state?.LeadID : LedData ? LedData.LeadID : "",
      CurrentUser: "",
      IsFlag: 0,
      NoteType: "Lead",
      NoteTitle: "",
      NoteText: chNotes,
    };
    debugger;
    console.log("formatteddata..", formattedData);
    axios({
      url: CommonConfig.ApiUrl + "authentication/addNote",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data.success === true) {
          cogoToast.success("Notes Added Successfully.");
          getNoteList(state?.LeadID ? state?.LeadID : LedData ? LedData.LeadID : "");
          setChNotes("");
        }
        console.log("test...", res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  console.log("leadDataQQAQAQ", leadData);
  console.log("PreviousStageStore", LedData?.PreviousStageStore);
  const onSubmit = (data) => {
    debugger;
    console.log("dataaa", data);

    let LeadDateFormat = CommonConfig.formatDate(data.LeadDate);

    let FollowUpDateFormat = ""

    if(data.FollowUpDate ){
      console.log("the checking date is:",data.FollowUpDate);
      FollowUpDateFormat = CommonConfig.formatDate(data.FollowUpDate);
    }

    if(FollowUpDateFormat == "Invalid date"){
      FollowUpDateFormat = ""
    }

    let formattedData = "";
    let sevicelist = [];
    sevicelist = values;

    console.log("sevicelistAQ", sevicelist.length);

    if (sevicelist.length == 0) {
      document.getElementById("SkillSetError").style.display = "block";
      return;
    } else {
      document.getElementById("SkillSetError").style.display = "none";
    }

    debugger;
    formattedData = {
      ...data,
      CurrentUser: "",
      // CompanyContactPersonID: contactPersonId ? contactPersonId : leadData?.CompanyContactPersonID,
      CompanyContactPersonID: finalContactId?.toString(),
      CompanyID: data.CompanyID ? data.CompanyID?.id : NewCompanyId?.CompanyID,
      Handler: data.Handler?.label,
      Source: data.Source?.label,
      LeadDate: LeadDateFormat,
      FollowUpDateFormat: FollowUpDateFormat,
      
      SkillSet: sevicelist?.toString(),
      RequirementType: data.RequirementType?.label,
      Stage: data.Stage?.label,
      PreviousStage: leadData?.Stage
        ? leadData?.Stage
        : LedData?.PreviousStageStore
        ? LedData?.PreviousStageStore
        : "",
      UserId:PersonId[0].PersonId,
      // SourceTypeOther:data.SourceTypeOther,
      SourceTypeOther:data.Source?.label=="Others" ? data.SourceTypeOther : "",

    };
    

    console.log("formattedDataA", formattedData);

    if (formattedData.CompanyID == undefined) {
      cogoToast.error("Please Select Company.");
      document.getElementById("ServicesError").style.display = "block";
      return;
    } else {
      document.getElementById("ServicesError").style.display = "none";
    }

    if (
      formattedData.CompanyContactPersonID == "" ||
      formattedData.CompanyContactPersonID == undefined ||
      leadData?.CompanyContactPersonID == ""
    ) {
      cogoToast.error("Please Select Contact.");
    } else {
      if (selectSaveButtonvalue == "save") {
        formattedData = {
          ...formattedData,
        };
        console.log("formattedDataQQ", formattedData);

        if (leadData) {
          formattedData = {
            ...formattedData,
            LeadID: state?.LeadID ? state?.LeadID : LedData ? LedData.LeadID : "",
          };
          console.log("ISLIVINGS", formattedData);
        }

        if (LedData) {
          formattedData = {
            ...formattedData,
            LeadID: state?.LeadID ? state?.LeadID : LedData ? LedData.LeadID : "",
          };
          console.log("ISLIVINGS", formattedData);
        }

        axios({
          url: CommonConfig.ApiUrl + "authentication/addUpdateLead",
          method: "POST",
          headers: {},
          data: formattedData,
        })
          .then((res) => {
            setLoader(false);
            debugger;
            setLoader(!loader);
            if (res.data.success === true) {
              localStorage.removeItem("leadData");
              localStorage.removeItem("NewCompanyId");
              localStorage.removeItem("PrevStageData");

              if (leadData) {
                setLoader(false);
                cogoToast.success("Lead Updated Successfully.");
                navigate("/layouts/lead/LeadList");
              } else {
                cogoToast.success("Lead Added Successfully.");
                navigate("/layouts/lead/LeadList");
              }
            } else {
              cogoToast.error("Something Went Wrong.");
            }
            console.log("testttttt", res.data);
          })
          .catch((error) => {
            console.log("AddUpdateErro", error);
          });
      }
    }
  };

  function getUniqueCompanyNames(stateList) {
    const uniqueCompanyNames = [];
    const map = new Map();
    stateList.forEach(company => {
      if (!map.has(company.label)) {
        map.set(company.label, true);
        uniqueCompanyNames.push(company);
      }
    });
   console.log("unique company names",uniqueCompanyNames)
    return uniqueCompanyNames;
  }

  return (
  <>
    { PersonId==undefined || PersonId=="" ?
       <> </> :
    <>
      <div>
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loader}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <DashboardLayout>
        <DashboardNavbar />
        <Card sx={{ width: "100%", mt: 3 }}>
          <MDBox display="flex">
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="4rem"
              height="4rem"
              variant="gradient"
              bgColor="primary"
              color="white"
              shadow="md"
              borderRadius="xl"
              ml={3}
              mt={-2}
            >
              <Icon fontSize="medium" color="inherit">
                add
              </Icon>
            </MDBox>
            <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
              {leadData ? "Edit Lead" : "Add Lead"}
            </MDTypography>
            <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: "auto", mr: 2 }}>
              {leadData ? "Lead No : " : ""}
              {leadData?.LeadNumber}
            </MDTypography>
          </MDBox>
          <MDBox sx={{ pt: 4, pb: 2, pl: 2, pr: 2 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="reg_step" id="regStep1">
                <div className="reg_form">
                  <Grid container spacing={3}>
                    <Grid item md="4">
                      <div className="reg_input">
                        <Controller
                          name="LeadTitle"
                          control={control}
                          defaultValue=""
                          rules={{ required: true }}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="Lead Title*"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              id="LeadTitle"
                              error={errors.LeadTitle}
                                  helperText={errors.LeadTitle ? "Lead Title Required." : ""}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item md="4">
                      <div className="reg_input auto-complete">
                        <Controller
                          control={control}
                          name="Handler"
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              disablePortal
                              id="Handler"
                              name="Handler"
                              value={value}
                              options={handlerData ? handlerData : ""}
                              onChange={(e, v) => onChange(v)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Handler*"
                                  error={errors.Handler}
                                  helperText={errors.Handler ? "Handler Required." : ""}
                                />
                              )}
                            />
                          )}
                        />
                      </div>
                    </Grid>

                    <Grid item md="4">
                      <div className="reg_input">
                        <Controller
                          name="LeadDate"
                          defaultValue=""
                          control={control}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <LocalizationProvider dateAdapter={DateFnsUtils}>
                              <DatePicker
                                id="LeadDate"
                                label="Lead Date*"
                                value={value || null}
                                //inputFormat="dd/MM/yyyy"
                                 inputFormat="MM/dd/yyyy"
                                onChange={(date) => {
                                  onChange(date);
                                  display(date);
                                }}
                                // minDate={new Date("15-08-1947")}
                                maxDate={new Date()}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    error={errors.LeadDate}
                                    helperText={errors.LeadDate ? "Lead Date Required." : ""}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    
                  <Grid item md="4">
                      <div className="reg_input auto-complete">
                        <Controller
                          control={control}
                          name="RequirementType"
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              disablePortal
                              id="RequirementType"
                              name="RequirementType"
                              value={value}
                              options={rquirementTypeData ? rquirementTypeData : ""}
                              onChange={(e, v) => onChange(v)}
                              onBlur={GetRequirement}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Requirement Type*"
                                  error={errors.RequirementType}
                                  helperText={
                                    errors.RequirementType ? "Requirement Type Required." : ""
                                  }
                                />
                              )}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                    
                    <Grid item md="4">
                      <div className="reg_input auto-complete">
                        <Controller
                          control={control}
                          name="Source"
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              disablePortal
                              id="Source"
                              name="Source"
                              value={value}
                              options={sourceDataList ? sourceDataList : ""}
                              onChange={(e, v) => onChange(v, setSourceValue(v))}
                              // onBlur={GetSource}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Source*"
                                  error={errors.Source}
                                  helperText={errors.Source ? "Source Required." : ""}
                                />
                              )}
                            />
                          )}
                        />
                      </div>
                    </Grid>

                    <Grid item md="4">
                      {sourceValue?.label == "Others" || sourceValue == "Others" ? (
                        <div className="reg_input">
                          <Controller
                            name="SourceTypeOther"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Other Source Name"
                                id="SourceTypeOther"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    {/* <Grid item md="4">
                      <div className="reg_input">
                        <Controller
                          name="SourceName"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              id="SourceName"
                              label="Source Name*"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid> */}

                    {/* <Grid item md="4">
                      <div className="reg_input auto-complete">
                        <Controller
                          control={control}
                          name="RequirementType"
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              disablePortal
                              id="RequirementType"
                              name="RequirementType"
                              value={value}
                              options={rquirementTypeData ? rquirementTypeData : ""}
                              onChange={(e, v) => onChange(v)}
                              onBlur={GetRequirement}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Requirement Type*"
                                  error={errors.RequirementType}
                                  helperText={
                                    errors.RequirementType ? "Requirement Type Required." : ""
                                  }
                                />
                              )}
                            />
                          )}
                        />
                      </div>
                    </Grid> */}

                    <Grid item md="4">
                      {requirementData == "Other" ? (
                        <div className="reg_input">
                          <Controller
                            name="OtherRequirementType"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                id="OtherRequirementType"
                                label="Requirement Type"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>


                  <Grid container spacing={3}>
                    <Grid item md="6">
                      <div className="reg_input sec_level">
                        <Autocomplete
                          filterSelectedOptions
                          multiple
                          id="SkillSet"
                          name="SkillSet"
                          options={serviceList ? serviceList : []}
                          value={serviceAutocompleteValues ? serviceAutocompleteValues : []}
                          defaultValue={serviceAutocompleteValues ? serviceAutocompleteValues : []}
                          onChange={onServiceChange}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Skill Set*"
                              placeholder="Skill Set"
                              // error={errorservices}
                              // helperText={errorservices}
                              // id="SkillSet"
                              // name="SkillSet"
                              // defaultValue={Params}
                              variant="outlined"
                              rows={1.5}
                              multiline
                              formControlProps={{
                                fullWidth: true,
                              }}
                            />
                          )}
                        />
                        <span class="errorTC" id="SkillSetError">
                          Please Select Skill Set.
                        </span>
                      </div>
                    </Grid>
                    <Grid item md="6">
                      <div className="reg_input">
                        <Controller
                          name="Requirement"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              id="Requirement"
                              label="Requirement*"
                              color="primary"
                              variant="outlined"
                              rows={2}
                              multiline
                              formControlProps={{
                                fullWidth: true,
                              }}
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    {/* <Grid item md="4">
                      <div className="reg_input">
                        <Controller
                          name="Requirement"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              id="Requirement"
                              label="Requirement*"
                              color="primary"
                              variant="outlined"
                              rows={3}
                              multiline
                              formControlProps={{
                                fullWidth: true,
                              }}
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid> */}

                    <Grid item md="4">
                      <div className="reg_input auto-complete">
                        <Controller
                          control={control}
                          name="Stage"
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              disablePortal
                              id="Stage"
                              name="Stage"
                              value={value}
                              options={stageData ? stageData : ""}
                              // onChange={(e, v) => onChange(v)}
                              onChange={(e, v) => onChange(v, setFollowUpValue(v))}
                              renderInput={(params) => (
                                <TextField
                                  autoComplete="off"
                                  {...params}
                                  label="Stage*"
                                  error={errors.Stage}
                                  helperText={errors.Stage ? "Stage Required." : ""}
                                />
                              )}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                    {followUpValue?.label == "Follow Up" || followUpValue == "Follow Up" || (leadData && state?.Stage == "Follow Up") ? (
                      <Grid item md="4">
                        <div className="reg_input auto-complete">
                          <Controller
                            control={control}
                            name="FollowUpDate"
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                              <LocalizationProvider dateAdapter={DateFnsUtils}>
                              <DatePicker
                              id="FollowUpDate"
                              label="FollowUp Date*"
                              value={value || null}
                              //inputFormat="dd/MM/yyyy"
                              inputFormat="MM/dd/yyyy"
                              onChange={(date) => {
                                onChange(date);
                                console.log("the new checking",value);
                                displayFollow(date);
                              }}
                              minDate={new Date()}
                              //maxDate={new Date()}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  error={errors.FollowUpDate}
                                  helperText={errors.FollowUpDate ? "FollowUp Date Required." : ""}
                                />
                              )}
                            />
                            </LocalizationProvider>
                              
                            )}
                          />

                            


                        </div>
                      </Grid>
                    ) : (
                      ""
                    )}

                   
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item md="4">
                      <div className="reg_input check_value">
                        <Controller
                          name="CompanyType"
                          id="CompanyType"
                          control={control}
                          defaultValue=""
                          rules={{ required: true }}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <>
                              {" "}
                              <RadioGroup
                                aria-label="appointment"
                                row="true"
                                defaultValue={
                                  LedData?.CompanyType ? LedData?.CompanyType : "Existing Company"
                                }
                              >
                                <FormControlLabel
                                  id="CompanyType"
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  value="New Company"
                                  control={<Radio checked={CompanyType === "New Company"} />}
                                  label="New Company"
                                />
                                <FormControlLabel
                                  id="CompanyType"
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  value="Existing Company"
                                  control={<Radio checked={CompanyType === "Existing Company"} />}
                                  label="Existing Company"
                                />
                              </RadioGroup>
                            </>
                          )}
                        />
                      </div>
                    </Grid>
                    {CompanyType == "Existing Company" && (
                      <Grid item md="4">
                        <div className="reg_input auto-complete">
                          <Controller
                            control={control}
                            name="CompanyID"
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                              <Autocomplete
                                disablePortal
                                id="CompanyID"
                                name="CompanyID"
                                value={value}
                                options={stateList ? getUniqueCompanyNames(stateList) : ""}
                                onChange={(e, v) => onChange(v, setCompanyForLocal(v))}
                                // onChange={(e, v) => onChange(v)}
                                // onChange={(e, v) => {
                                //   onChange(v);
                                //   // getCompanyId(e, v);
                                //   console.log("onchangeDataaaa", v);
                                // }}

                                // onClick={(e, v) => getCompanyId(e, v)}
                                // onBlur={(e, v) => getCompanyId(e, v)}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Company"
                                    error={errors.CompanyID}
                                    helperText={errors.CompanyID ? "Company Required." : ""}
                                  />
                                )}
                              />
                            )}
                          />
                          <span class="errorTC" id="ServicesError">
                            Please Select Company.
                          </span>
                        </div>
                      </Grid>
                    )}

                    {CompanyType == "New Company" && (
                      <div>
                        {/* <NewCompany /> */}
                        {newCompany()}
                      </div>
                    )}

                    <Grid item md="4">
                      <div className="reg_input">
                        {data?.length == 0 ? (
                          <PButton
                            icon="pi pi-plus"
                            className="p-button-primary AddNotes"
                            onClick={() => getValueAddNewContactButton()}
                            // style={{ marginRight: ".5em" }}
                            tooltip="Add New Contact"
                            tooltipOptions={{ position: "bottom" }}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </Grid>
                  </Grid>
                  <Grid item md="12">
                    <div className="card">
                      <div className="table-custom">
                        <DataTable
                          scrollable
                          value={data}
                          showGridlines
                          scrollHeight="600px"
                          scrollWidth="600px"
                          responsiveLayout="scroll"
                          selection={contactId}
                          onSelectionChange={(e) => setContactId(e.value)}
                          totalRecords={data ? data.length : 0}
                          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                          rowsPerPageOptions={[5, 10, 20]}
                          emptyMessage="No records found"
                          // filterDisplay="row"
                        >
                          <Column selectionMode="multiple" headerStyle={{ width: "3em" }}></Column>
                          {getColoumnsRow()}
                        </DataTable>
                      </div>
                    </div>
                  </Grid>
                </div>
              </div>

              {leadData || LedData?.LeadID ? (
                <Card sx={{ width: "100%", mt: 6 }}>
                  <MDBox display="flex">
                    <MDBox
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      width="4rem"
                      height="4rem"
                      variant="gradient"
                      bgColor="primary"
                      color="white"
                      shadow="md"
                      borderRadius="xl"
                      ml={3}
                      mt={-2}
                    >
                      <Icon fontSize="medium" color="inherit">
                        warning_amber
                      </Icon>
                    </MDBox>
                    <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
                      Notes
                    </MDTypography>
                  </MDBox>
                  <MDBox sx={{ pt: 4, pb: 2, pl: 2, pr: 2 }}>
                    <div className="notes-table">
                      <table>
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Notes</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            {/* <td className="nt-date">{moment(new Date()).format("DD-MM-YYYY")}</td> */}
                            <td className="nt-date">{moment(new Date()).tz("America/Chicago",true).format("MM-DD-YYYY")}</td>
                            <td className="nt-comment">
                              <div className="reg_input mb-0">
                                <TextField
                                  onChange={(e) => setChNotes(e.target.value)}
                                  value={chNotes ? chNotes : ""}
                                  name="notes"
                                  multiline
                                  rows={2}
                                  // maxRows={4}
                                  label="Notes Text*"
                                  id="regFirstName"
                                  fullWidth
                                />
                                <span class="errorTC" id="NotesError">
                                  Please Write Notes.
                                </span>
                              </div>
                            </td>
                            <td className="nt-action">
                              <PButton
                                type="button"
                                icon="pi pi-plus"
                                className="p-button-primary AddNotes"
                                onClick={() => saveNotes()}
                                tooltip="Add Notes"
                                tooltipOptions={{ position: "bottom" }}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <NotesComponent
                      entityType="Lead"
                      entityId={state?.LeadID ? state?.LeadID : LedData ? LedData.LeadID : ""}
                      NotesData={notesData}
                    />
                  </MDBox>
                </Card>
              ) : (
                <></>
              )}

              <div className="reg_submit">
                <MDButton onClick={() => cancel()} variant="contained" color="secondary">
                  Cancel
                </MDButton>

                {leadData || LedData?.LeadID ? (
                  <MDButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={() => getValueSaveButton()}
                  >
                    Update
                  </MDButton>
                ) : (
                  <MDButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={() => getValueSaveButton()}
                  >
                    Save
                  </MDButton>
                )}
              </div>
              <div className="copyright">
                <p>Copyright &copy; 2024 All Rights Reserved | Powered by Webment</p>
              </div>
            </form>
          </MDBox>
        </Card>
      </DashboardLayout>
      </>
    }
    
 </>
  );
};

Lead.defaultProps = {};

export default Lead;
