import React, { useEffect, useMemo, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import cogoToast from "cogo-toast";
import axios from "axios";
import { DashCircle, PlusCircle } from "react-bootstrap-icons";
// import DatePicker from "react-date-picker";
import Multiselect from "multiselect-react-dropdown";
import PhoneInput from "react-phone-input-2";
import MDDatePicker from "components/MDDatePicker";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDInput from "components/MDInput";
import { CommonConfig } from "constant";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import Icon from "@mui/material/Icon";
import MDTypography from "components/MDTypography";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Country, State, City } from "country-state-city";

import {
  FormControlLabel,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
  Radio,
  Select,
  RadioGroup,
  Grid,
  Button,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import Autocomplete from "@mui/material/Autocomplete";
import Logo from "assets/img/cropped-webment.png";
import MDButton from "components/MDButton";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { item } from "examples/Sidenav/styles/sidenavItem";

import { isValidDate } from "@fullcalendar/react";
import { Alert } from "@mui/material";


const schema = yup.object().shape(
  {
    CompanyName: yup.string().required("Company Name Required."),
    Industry: yup.object().required("Industry Required."),
    // CompanySize: yup.object().required("No. of Employees Required."),
    // SalesTurnover: yup.object().required("Sales Turnover Required."),
    // Website: yup.string().required("Website Required."),
    Source: yup.object().required("Source Required."),
    // Notes: yup.string().required("Notes Required."),

    Country: yup.string().when(["AddressLine1", "AddressLine2", "City", "State", "PostalCode"], {
      is: (AddressLine1, AddressLine2, City, State, PostalCode) =>
        AddressLine1 !== "" ||
        AddressLine2 !== "" ||
        City !== "" ||
        State !== "" ||
        PostalCode !== "",
      then: yup.string().required("Address Type Required."),
    }),
    AddressLine1: yup.string().when(["Country", "AddressLine2", "City", "State", "PostalCode"], {
      is: (Country, AddressLine2, City, State, PostalCode) =>
        Country !== "" || AddressLine2 !== "" || City !== "" || State !== "" || PostalCode !== "",
      then: yup.string().required("Address Line1 Required."),
    }),
    AddressLine2: yup.string(),
    // .when(["Country", "AddressLine1", "City", "State", "PostalCode"], {
    //   is: (Country, AddressLine1, City, State, PostalCode) =>
    //     Country !== "" || AddressLine1 !== "" || City !== "" || State !== "" || PostalCode !== "",
    //   then: yup.string(),
    //   .required("Address Line2 Required."),
    // }),

    City: yup.string().when(["Country", "AddressLine1", "AddressLine2", "State", "PostalCode"], {
      is: (Country, AddressLine1, AddressLine2, State, PostalCode) =>
        Country !== "" ||
        AddressLine1 !== "" ||
        AddressLine2 !== "" ||
        State !== "" ||
        PostalCode !== "",
      then: yup.string().required("City Required."),
    }),

    State: yup.string().when(["Country", "AddressLine1", "AddressLine2", "City", "PostalCode"], {
      is: (Country, AddressLine1, AddressLine2, City, PostalCode) =>
        Country !== "" ||
        AddressLine1 !== "" ||
        AddressLine2 !== "" ||
        City !== "" ||
        PostalCode !== "",
      then: yup.string().required("State Required."),
    }),

    PostalCode: yup.string().when(["Country", "AddressLine1", "AddressLine2", "City", "State"], {
      is: (Country, AddressLine1, AddressLine2, City, State) =>
        Country !== "" || AddressLine1 !== "" || AddressLine2 !== "" || City !== "" || State !== "",
      then: yup.string().required("PostalCode Required."),
    }),

    AddressType: yup.object().required("Address Type Required."),
    AddressLine1: yup.string().required("Address Line1 Required."),
    AddressLine2: yup.string(),
    // .required("Address Line2 Required."),
    City: yup.string().required("City Required."),
    PostalCode: yup.string().required("Postal Code Required."),
    State: yup.string().required("State Required."),
    Country: yup.string().required("Country Required."),
  },
  [
    ["Country", "AddressLine1"],
    ["Country", "AddressLine2"],
    ["AddressLine1", "AddressLine2"],
    ["AddressLine1", "City"],
    ["City", "AddressLine2"],
    ["Country", "City"],
    ["State", "AddressLine2"],
    ["State", "AddressLine1"],
    ["State", "Country"],
    ["State", "City"],
    ["State", "PostalCode"],
    ["City", "PostalCode"],
    ["Country", "PostalCode"],
    ["AddressLine2", "PostalCode"],
    ["AddressLine1", "PostalCode"],
  ]
);

const NewCompany = () => {
  const { state } = useLocation();

  let navigate = useNavigate();
  
  var PersonId = CommonConfig.loggedInUserData();
  console.log("Hello",PersonId);
  if(PersonId==undefined || PersonId==""){
    navigate('authentication/login');
  }
  
  
  let clientRef = useRef(false);
  const MAX_COUNT = 5;
  // const termsCondition = "";
  const [values, setValues] = useState([]);
  const [stateId, setStateId] = useState([]);
  const [stateLabel, setStateLabel] = useState(state?.SecurityUserData?.State || null);

  const [relativeStateId, setRelativeStateId] = useState([]);
  const [uploadfile, setuploadfile] = useState([]);
  const [relativeStateLabel, setRelativeStateLabel] = useState(
    state?.SecurityUserData?.RelativeState || null
  );
  const [serviceAutocompleteValues, setServiceAutocompleteValues] = useState(null);

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fileLimit, setFileLimit] = useState(false);
  const [companyData, setCompanyData] = useState(state || null);
  // const [DocumentuserData, setDocumentUserData] = useState(state[1] || null);
  console.log("KKKK", companyData);

  let LedData = JSON.parse(localStorage.getItem("leadData"));
  console.log("122222", LedData);
  let newData = JSON.parse(localStorage.getItem("newData"));
  console.log("newData011", newData);

  const [stateList, setStateList] = useState(null);

  const [requirementData, setRequirementData] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [documentdata, setdocumentdata] = useState([]);
  const [serviceList, setServiceList] = useState(null);
  const [loader, setLoader] = React.useState(false);
  const [sourceData, setSourceData] = useState(null);
  const [industryData, setIndustryData] = useState(null);
  const [salesTurnoverData, setSalesTurnoverData] = useState(null);
  const [sourceDataList, setSourceDataList] = useState(null);
  const [noOfEmployeData, setNoOfEmployeData] = useState(null);
  const [addressTypeData, setAddressTypeData] = useState(null);
  const [sourceValue, setSourceValue] = useState(null);
  const [countryData, setCountryData] = useState(null);
  const [countryIsoValue, setCountryIsoValue] = useState(null);
  const [stateIsoValue, setStateIsoValue] = useState(null);

  const [stateData, setStateData] = useState(null);
  const [citysData, setCitysData] = useState(null);

  console.log("countryIsoValue Country", `${countryIsoValue?.id}`);
  console.log("countryIsoValuecity", `${countryIsoValue?.id}`, `${stateIsoValue?.id}`);

  // GetCountry State City--------------------------

  function getCountry() {
    let getAllCountry = Country.getAllCountries();
    let getCountryArry = [];
    for (var i = 0; i < getAllCountry.length; i++) {
      let getCountryObject = {};
      getCountryObject.label = getAllCountry[i]?.name;
      getCountryObject.id = getAllCountry[i]?.isoCode;
      getCountryArry.push(getCountryObject);
    }
  
    // console.log("countryDataavalueee", getCountryArry);
    // console.log("countryDataavalueee1", getAllCountry);
    setCountryData(getCountryArry);
  }

  function getStates() {
    let getAllState = State.getStatesOfCountry(`${countryIsoValue?.id}`);

    let getStateArry = [];
    for (var i = 0; i < getAllState.length; i++) {
      let getStateObject = {};
      getStateObject.label = getAllState[i]?.name;
      getStateObject.id = getAllState[i]?.isoCode;
      getStateArry.push(getStateObject);
    }
    console.log("qwert",getStateArry);
    setStateData(getStateArry);
  }

  function getCitys() {
    let getAllCity = City.getCitiesOfState(`${countryIsoValue?.id}`, `${stateIsoValue?.id}`);
    console.log("hello country",countryIsoValue,stateIsoValue);
    let getCityArry = [];
    for (var i = 0; i < getAllCity.length; i++) {
      let getCityObject = {};
      getCityObject.label = getAllCity[i]?.name;
      getCityObject.id = getAllCity[i]?.stateCode;
      getCityArry.push(getCityObject);
    }
    console.log("countryDataavalueee1222", getAllCity);
    setCitysData(getCityArry);
  }
  
  const getCountryISO = (countryName) => {
    let getAllCountry = Country.getAllCountries();
    console.log("the country",countryName);
    const country = getAllCountry?.find(country => country?.name === countryName);
    const tempData= {
      label:country?.name,
      id:country?.isoCode,
    };
    return tempData;
  }

  const getStateISO = (stateName) => {
    let getAllState = State.getStatesOfCountry(`${countryIsoValue?.id}`);
    console.log("the country",stateName);
    const state = getAllState?.find(state => state?.name === stateName);
    console.log("wow2",state);
    const tempData= {
      label:state?.name,
      id:state?.isoCode,
    };
    console.log("wow",tempData);
    return tempData;
  }

  
  useEffect(() => {
    getCountry();
  }, []);
  useEffect(() => {
    getStates();
    if(companyData?.Country){
      const temp2=getStateISO(companyData?.State);
      setStateIsoValue(temp2);
    }
  }, [countryIsoValue]);
  useEffect(() => {
    getCitys();
  }, [stateIsoValue]);
  useEffect(()=>{
    if(companyData){
      console.log("ccdd",companyData);
      const temp=getCountryISO(companyData?.Country);
      setCountryIsoValue(temp);
    }
  },[companyData]);


  // GetCountry State City--------------------------

  const onStateChange = (event, value) => {
    console.log("State::", value);
    setStateId(value.id);
    setStateLabel(value.label);
  };
  let dir = "https://services.valuecare.org.au/Backend/documents/Referals/";
  // const documentdata = userData.dOC;
  console.log("uploadedFiles", uploadedFiles);

  const IndustryData = [{ label: "Industry1" }, { label: "Industry2" }, { label: "Industry3" }];
  const CompanySizeData = [
    { label: "< 50" },
    { label: "50-100" },
    { label: "100-500" },
    { label: "500-1000" },
    { label: "> 1000" },
  ];
  const SalesTurnoverData = [{ label: "< 50 Cr" }, { label: "50-100 Cr" }, { label: "> 100 Cr" }];

  //   const SourceData = [{ label: "MCA" }, { label: "B.tech" }, { label: "MBA" }, { label: "M.tech" }];
  const RequirementTypeData = [
    { label: "Web App" },
    { label: "Mobile App" },
    { label: "Digital Marketing" },
    { label: "Other" },
  ];

  const SkillList = [{ label: "< NET" }, { label: "React" }, { label: "Node" }];

  const PhoneTypeData = [{ label: "Work" }, { label: "Personal" }];
  const CountryCodeData = [{ label: "+61" }, { label: "+91" }];
  const stateListStatic = [
    { label: "State1" },
    { label: "State2" },
    { label: "State3" },
    { label: "State4" },
  ];
  const CountryData = [{ label: "Australia" }, { label: "India" }];
  const StageData = [
    { label: "Lead" },
    { label: "Follow Up" },
    { label: "Won" },
    { label: "Lost" },
    { label: "Purged" },
  ];

  const SourceData = [
    { label: "LinkedIn" },
    { label: "Upwork" },
    { label: "Referral Network" },
    { label: "Other" },
  ];

  const getStateData = () => {
    const formattedData = {
      StringMapType: "STATE",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "Utils/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("StateList===>", res.data.data);

          setStateList(
            res.data.data.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };

  const handleFileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
  };
  const handleUploadFiles = (files) => {
    const uploaded = [...uploadedFiles];
    let limitExceeded = false;
    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        if (uploaded.length === MAX_COUNT) setFileLimit(true);
        if (uploaded.length > MAX_COUNT) {
          alert(`You can only add a maximum of ${MAX_COUNT} files`);
          setFileLimit(false);
          limitExceeded = true;
          return true;
        }
      }
    });
    if (!limitExceeded) setUploadedFiles(uploaded);
  };

  const setAutocompleteValues = () => {
    let dummyArray = [];
    let formattedData = [];
    let array = JSON.parse(state.SecurityUserData.Services);
    for (let i = 0; i < array.length; i++) {
      if (array[i]) {
        dummyArray.push({
          id: array[i],
          label: array[i],
        });
      }
    }
    for (let i = 0; i < array.length; i++) {
      formattedData.push(array[i]);
    }
    debugger;
    setValues(formattedData);
    setServiceAutocompleteValues(dummyArray);
  };

  const onServiceChange = (event, value) => {
    console.log("VALUEService", value);
    let formattedData = [];

    for (let i = 0; i < value.length; i++) {
      formattedData.push(value[i].label);
    }

    setValues(formattedData);
    setServiceAutocompleteValues(value);
    console.log("onService", values);
  };

  const getServiceData = () => {
    const formattedData = {
      StringMapType: "SERVICETYPE",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "Utils/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("ServiceList===>", res.data.data);
          setServiceList(
            res.data.data.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getIndustryData = () => {
    const formattedData = {
      StringMapType: "INDUSTRY",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "authentication/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("ServiceList===>", res.data.data);
          setIndustryData(
            res.data.data.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  console.log("handlerDataQQ", industryData);

  const getSalesTurnoverData = () => {
    const formattedData = {
      StringMapType: "SALESTURNOVER",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "authentication/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("ServiceList===>", res.data.data);
          setSalesTurnoverData(
            res.data.data.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSourceData = () => {
    const formattedData = {
      StringMapType: "SOURCES",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "authentication/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("ServiceList===>", res.data.data);
          setSourceDataList(
            res.data.data.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getNoOfEmployeData = () => {
    const formattedData = {
      StringMapType: "NOOFEMPLOYEES",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "authentication/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("ServiceList===>", res.data.data);
          setNoOfEmployeData(
            res.data.data.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAddressTypeData = () => {
    const formattedData = {
      StringMapType: "PHONETYPE",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "authentication/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("ServiceList===>", res.data.data);
          setAddressTypeData(
            res.data.data.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getAddressTypeData();
    getNoOfEmployeData();
    getSourceData();
    getSalesTurnoverData();
    getIndustryData();
    getStateData();
    getServiceData();
  }, []);

  useEffect(() => {
    if (companyData) {
      setSourceValue(companyData?.SourceType);
    }
  }, [companyData]);

  console.log("UserData = ", companyData);
  const Industrydata = {
    id: companyData?.Industry,
    label: companyData?.Industry,
  };
  const CompanySizedata = {
    id: companyData?.CompanySize,
    label: companyData?.CompanySize,
  };
  const SalesTurnoverdata = {
    id: companyData?.SalesTurnover,
    label: companyData?.SalesTurnover,
  };
  const Sourcedata = {
    id: companyData?.Source,
    label: companyData?.Source,
  };

  const AddressTypedata = {
    id: companyData?.AddressType,
    label: companyData?.AddressType,
  };

  const Statedata = {
    label: companyData?.State,
  };

  const Countrydata = {
    label: companyData?.Country,
  };

  const {
    register,
    handleSubmit,
    watch,
    control,
    // errors,
    formState: { errors },
    getValues,
  } = useForm({

    resolver: yupResolver(schema),
    defaultValues: {
      CompanyName: companyData?.CompanyName ? companyData?.CompanyName : "",
      Industry: companyData?.Industry ? Industrydata : "",
      CompanySize: companyData?.CompanySize ? CompanySizedata : "",
      SalesTurnover: companyData?.SalesTurnover ? SalesTurnoverdata : "",
      Website: companyData?.Website ? companyData?.Website : "",
      Source: companyData?.Source ? Sourcedata : "",
      Other: companyData?.Other ? companyData?.Other : "",
      Notes: companyData?.Notes ? companyData?.Notes : "",
      AddressType: companyData?.AddressType ? AddressTypedata : "",
      AddressLine1: companyData?.AddrLine1 ? companyData?.AddrLine1 : "",
      AddressLine2: companyData?.AddrLine2 ? companyData?.AddrLine2 : "",
      
      City: companyData?.City ? companyData?.City : "",
      PostalCode: companyData?.PostalCode ? companyData?.PostalCode : "",
      // State: companyData?.State ? Statedata : "",
      // Country: companyData?.Country ? Countrydata : "",
      State: companyData?.State ? companyData?.State : "",
      Country: companyData?.Country ? companyData?.Country : "",
    },
  });

  const GetSource = (e, value) => {
    var setoffice = e.target.value;

    var dataSET = {
      source: setoffice,
    };
    setSourceData(dataSET.source);
    console.log("sourceData", sourceData);
  };

  const cancel = () => {
    navigate("/layouts/lead/Index");
    localStorage.removeItem("newData");
  };

  const onSubmitCompany = (data) => {debugger
    console.log("CompanydataQQQ", data);

    let formattedData = "";
    let sevicelist = [];
    sevicelist = values;
    console.log("skill", sevicelist);
    formattedData = {
      ...data,
      CurrentUser: "",
      CompanySize: data.CompanySize?.label,
      Country: data.Country,
      Industry: data.Industry?.label,
      SalesTurnover: data.SalesTurnover?.label,
      State: data.State,
      Source: data.Source?.label,
      AddressType: data.AddressType.label,
    };
    console.log("formattedData=", formattedData);

    let formattedDataT;

    formattedDataT = {
      formattedData,
    };

    if (companyData) {
      formattedDataT = {
        formattedData,
        AddressID: companyData?.AddressID,
        CompanyId: companyData?.CompanyID,
        CurrentUser: "",
      };
    }

    // console.log("formattedDataTTT.....", formattedDataT);

    axios({
      url: CommonConfig.ApiUrl + "authentication/AddUpdateCompany",

      method: "POST",
      headers: {},
      data: formattedDataT,
    })
      .then((res) => {
        console.log("coidres", res.data.data[0][0]);
        localStorage.setItem("NewCompanyId", JSON.stringify(res.data.data[0][0]));
        setLoader(false);
        debugger;
        /// setLoader(!loader);
        if (res.data.success === true) {
          if (companyData) {
            // setLoader(false);
            cogoToast.success("Company Updated Successfully.");
             navigate("/layouts/lead/CompanyList");
          } else if (newData?.test == "" || newData?.test == null) {
            cogoToast.success("Company Added Successfully.");
             navigate("/layouts/lead/CompanyList");
          } else {
            cogoToast.success("Company Added Successfully.");
             navigate("/layouts/lead/Index");
            localStorage.removeItem("newData");
          }
        } else {
          cogoToast.error("Something Went Wrong.");
        }

        console.log("testttttt", res.data);
      })
      .catch((error) => {
        console.log("AddUpdateErro", error);
      });
  };

  return (
    <>
      <div>
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loader}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <DashboardLayout>
        <DashboardNavbar />
        <Card sx={{ width: "100%", mt: 3 }}>
          <MDBox display="flex">
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="4rem"
              height="4rem"
              variant="gradient"
              bgColor="primary"
              color="white"
              shadow="md"
              borderRadius="xl"
              ml={3}
              mt={-2}
            >
              {companyData ? 
              <Icon fontSize="medium" color="inherit">
                edit
              </Icon> : <Icon fontSize="medium" color="inherit">
                add
              </Icon>}
              
              
            </MDBox>
            <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
              {companyData ? "Edit New Company" : "Add New Company"}
              {/* Referral Form. */}
            </MDTypography>
          </MDBox>
          <MDBox sx={{ pt: 4, pb: 2, pl: 2, pr: 2 }}>
            <form onSubmit={handleSubmit(onSubmitCompany)}>
              <div className="reg_step" id="regStep1">
                <div className="reg_form">
                  <div className="reg_box_bordered">
                    <Grid container spacing={3}>
                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="CompanyName"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                autoComplete="nope"
                                label="Company Name*"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>

                      <Grid item md="4">
                        <div className="reg_input auto-complete">
                          <Controller
                            control={control}
                            name="Industry"
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                              <Autocomplete
                                disablePortal
                                id="Industry"
                                name="Industry"
                                value={value}
                                options={industryData ? industryData : ""}
                                onChange={(e, v) => onChange(v)}
                                renderInput={(params) => (
                                  <TextField
                                    autoComplete="off"
                                    {...params}
                                    label="Industry*"
                                    error={errors.Industry}
                                    helperText={errors.Industry ? "Industry Required." : ""}
                                  />
                                )}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                      <Grid item md="4">
                        <div className="reg_input auto-complete">
                          <Controller
                            control={control}
                            name="CompanySize"
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                              <Autocomplete
                                disablePortal
                                id="CompanySize"
                                name="CompanySize"
                                value={value}
                                options={noOfEmployeData ? noOfEmployeData : ""}
                                onChange={(e, v) => onChange(v)}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="No of Employees"
                                    error={errors.CompanySize}
                                    helperText={
                                      errors.CompanySize ? "No of Employees Required." : ""
                                    }
                                  />
                                )}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                      <Grid item md="6">
                        <div className="reg_input auto-complete">
                          <Controller
                            control={control}
                            name="SalesTurnover"
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                              <Autocomplete
                                disablePortal
                                id="SalesTurnover"
                                name="SalesTurnover"
                                value={value}
                                options={salesTurnoverData ? salesTurnoverData : ""}
                                onChange={(e, v) => onChange(v)}
                                renderInput={(params) => (
                                  <TextField
                                    autoComplete="off"
                                    {...params}
                                    label="Sales Turnover"
                                    error={errors.SalesTurnover}
                                    helperText={
                                      errors.SalesTurnover ? "Sales Turnover Required." : ""
                                    }
                                  />
                                )}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                      <Grid item md="6">
                        <div className="reg_input">
                          <Controller
                            name="Website"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Website"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                      <Grid item md="6">
                        <div className="reg_input auto-complete">
                          <Controller
                            control={control}
                            name="Source"
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                              <Autocomplete
                                disablePortal
                                id="Source"
                                name="Source"
                                value={value}
                                options={sourceDataList ? sourceDataList : ""}
                                onChange={(e, v) => onChange(v, setSourceValue(v))}
                                // onChange={(e, v) => onChange(v)}
                                // onBlur={GetSource}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Source*"
                                    error={errors.Source}
                                    helperText={errors.Source ? "Source Required." : ""}
                                  />
                                )}
                              />
                            )}
                          />
                        </div>
                      </Grid>

                      <Grid item md="6">
                        {sourceValue?.label == "Others" || sourceValue == "Others" ? (
                          <div className="reg_input">
                            <Controller
                              name="Other"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  label="Other Source"
                                  id="Other"
                                  variant="outlined"
                                  value={value}
                                  onChange={onChange}
                                  error={error}
                                  helperText={error?.message}
                                />
                              )}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                      <Grid item md="12">
                        <div className="reg_input">
                          <Controller
                            name="Notes"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Notes"
                                color="primary"
                                variant="outlined"
                                rows={3}
                                multiline
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <div className="reg_box_bordered">
                    <Grid container spacing={3}>
                      <Grid item md="4">
                        <div className="reg_input auto-complete">
                          <Controller
                            control={control}
                            name="AddressType"
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                              <Autocomplete
                                disablePortal
                                id="AddressType"
                                name="AddressType"
                                value={value}
                                options={addressTypeData ? addressTypeData : ""}
                                onChange={(e, v) => onChange(v)}
                                //   onBlur={GetSource}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Address Type"
                                    error={errors.AddressType}
                                    helperText={errors.AddressType ? "Address Type Required." : ""}
                                  />
                                )}
                              />
                            )}
                          />
                        </div>

                        {/* <div className="reg_input">
                          <Controller
                            name="AddressType"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Address Type*"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div> */}
                      </Grid>
                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="AddressLine1"
                            control={control}
                            defaultValue=""
                            rules={{ required: true }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                autoComplete="nope"
                                type="text"
                                label="Address Line1"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="AddressLine2"
                            control={control}
                            defaultValue=""
                            // rules={{ required: false }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                autoComplete="nope"
                                label="Address Line2"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                      <Grid item md="3">
                        <div className="reg_input auto-complete">

                          {/* <Controller
                            name="Country"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                //autoComplete="nope"
                                label="Country"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          /> */}

  
                          {/* <Controller
                            control={control}
                            name="Country"
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                              <Autocomplete
                                disablePortal
                                id="Country"
                                name="Country"
                                value={value}
                                options={countryData ? countryData : ""}
                                onChange={(e, v) => onChange(v.label, setCountryIsoValue(v.label))}
                                // onChange={(e, v) => console.log("getisocodevalue", v)}
                                //   onBlur={GetSource}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Country"
                                    error={errors.Country}
                                    helperText={errors.Country ? "Country Required." : ""}
                                  />
                                )}
                              />
                            )}
                          /> */}
                           <Controller
                            control={control}
                            name="Country"
                            defaultValue=""
                            rules={{ required: true }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <Autocomplete
                                disablePortal
                                id="Country"
                                name="Country"
                                value={value || null}
                                options={countryData ? countryData : []}
                               // getOptionLabel={(option) => option.label}
                                onChange={(e, v) => {
                                onChange(v.label, setSourceValue(v.label));
                                // onChange={(e, v) => onChange(v)}
                                // onBlur={GetSource}
                                 setCountryIsoValue(v);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Country"
                                    error={errors.Country}
                                    helperText={errors.Country ? "Country Required." : ""}
                                  />
                                )}
                              />
                            )}
                          />

                        </div>
                      </Grid>

                      <Grid item md="3">
                        <div className="reg_input">

                          {/* <Controller
                            name="State"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                autoComplete="nope"
                                label="State"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          /> */}
                          {/* <Controller
                            name="State"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <Autocomplete
                               autoComplete="nope"
                               options={stateData ? stateData : ""}
                               getOptionLabel={(state) => state.name}
                               value={value}
                               onChange={(e, selectedState) => onChange(selectedState)}
                               renderInput={(params) => (
                              <TextField
                                {...params}
                                label="State"
                                variant="outlined"
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                            />
                          )}
                         /> */}
                         <Controller
                            control={control}
                            name="State"
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                              <Autocomplete
                                disablePortal
                                id="State"
                                name="State"
                                value={value || null}
                                options={stateData ? stateData : []}
                                onChange={(e, v) => {
                                onChange(v.label, setStateIsoValue(v.label))
                                // onChange={(e, v) => console.log("getisocodevalue", v)}
                                //   onBlur={GetSource}
                                setStateIsoValue(v);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="State"
                                    error={errors.State}
                                    helperText={errors.State ? "State Required." : ""}
                                  />
                                )}
                              />
                            )}
                          />



                          {/* <Controller
                            name="State"
                            control={control}
                            defaultValue=""
                            rules={{ required: true }}
                            render={({ field: { onChange, value },fieldState: { error } }) => (
                            <Autocomplete
                              disablePortal
                              id="State"
                              name="State"
                              value={value || null}
                              options={stateData ? stateData : ""}
                              getOptionLabel={(state) => state.label}
                              onChange={(e, v) => 
                                 {onChange(v.label,setSourceValue(v.label));
                                       setStateIsoValue(v);
                              }}
                              renderInput={(params) => (
                              <TextField
                                 {...params}
                                 label="State"
                                 // variant="outlined"
                                 error={errors.State}
                                 helperText={errors.State ? "State Required." : ""}
                               />
                              )}
                              />
                             )}
                            /> */}
                        </div>
                      </Grid>

                      <Grid item md="3">
                        <div className="reg_input">
                          {/* <Controller
                            name="City"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="City"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          /> */}

                          {/* <Controller
                             name="City"
                             control={control}
                             defaultValue=""
                             rules={{ required: true }}
                             render={({ field: { onChange, value }}) => (
                             <Autocomplete
                              disablePortal
                              id="City"
                              name="City"
                              value={value || null}
                              options={citysData ? citysData : ""}
                              getOptionLabel={(city) => city.label}
                              onChange={(e, v) => 
                              onChange(v.label,setSourceValue(v.label))}
                              renderInput={(params) => (
                              <TextField
                               {...params}
                               label="City"
                               // variant="outlined"
                               error={errors.City}
                               helperText={errors.City ? "City Required." : ""} 
                              />
                              )}
                             />
                            )}
                          /> */}
                           <Controller
                            control={control}
                            name="City"
                            //defaultValue=""
                            rules={{ required: true }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <Autocomplete
                                //disablePortal
                                id="City"
                                name="City"
                                value={value || null}
                                options={citysData ? citysData : []}
                               // getOptionLabel={(option) => option.label}
                                onChange={(e, v) => {
                                onChange(v.label, setSourceValue(v.label));
                                // onChange={(e, v) => onChange(v)}
                                // onBlur={GetSource}
                                 //setCountryIsoValue(v);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="City"
                                    error={errors.City}
                                    helperText={errors.City ? "City Required." : ""}
                                  />
                                )}
                              />
                            )}
                          />
                        </div>
                      </Grid>

                      <Grid item md="3">
                        <div className="reg_input">
                          <Controller
                            name="PostalCode"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Postal Code"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                                inputProps={{
                                  maxLength: 6,
                                }}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                    </Grid>

                    <Grid container spacing={3}></Grid>
                  </div>
                </div>
              </div>
              <div className="reg_submit">
                {newData?.test == "" || newData?.test == null ? (
                  <MDButton
                    onClick={() => navigate("/layouts/lead/CompanyList")}
                    variant="contained"
                    color="secondary"
                  >
                    Cancel
                  </MDButton>
                ) : (
                  <MDButton onClick={() => cancel()} variant="contained" color="secondary">
                    Cancel
                  </MDButton>
                )}

                {companyData ? (
                  <MDButton type="submit" variant="contained" color="primary">
                    Update
                  </MDButton>
                ) : (
                  <MDButton type="submit" variant="contained" color="primary">
                    Save
                  </MDButton>
                )}
              </div>
            </form>
          </MDBox>
        </Card>
      </DashboardLayout>
    </>
  );
};

NewCompany.defaultProps = {};

export default NewCompany;
